import React from "react";
import { withLastLocation } from 'react-router-last-location';
import QuestionsLib from "../../Components/QuestionsLib";
import  LandingPage  from "../LandingPage/landingPage";
import {
  prepareFormData,
  CreateRequiredSchema,
  getUid,
  LIST_ACTION,
  saveException,
  checkLoginRedirection,
  redirectIfNeeded,
  handleGtmQuestionsSubmission,
  handleGtmVirtalPageVisits,
  getForMotivConfig
} from "../../util";
//import { ActivityLog } from "../../util/ActivityLog";
import { getNewQuestions, insertMissingQuestions, insertReflexiveQuestion, removeErrorQuestionInReflexiveCall } from "./helper";
import "./loader.css";
import "./Questions.css";
import { axiosInstance } from "../../util/axios";
import { setBreadCrumbs } from '../../redux/actions/index'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import get from "lodash/get";
import * as utils from '../../util/index';
import { PAGES_LIST } from '../../util/pages';
import { ErrorPopup, Loader } from "../../Components/UIElements";
import localDb from "../../util/local-db";

const QUESTIONS_RENDER = {
  init: "INITIAL",
  next: "NEXT",
  prev: "PREVIOUS",
  update: "UPDATE",
  cancel: "CANCEL"
};

//  const SM_FLAG = false; //SPINNER MESSAGE FLAG


/**
 * @description Used to toggle between the validation views.
 * If the field is true, then the next button is disabled by default and it becomes active
 * when all the neccessary fields are filled appropriately.
 * If the field is false, then the next button is active by default and inline error messages
 * and knockout error popup message are displayed on clicking next button; in case of any errors.
 */
export const TOGGLE_VALIDATION = false;

class Questions extends React.Component {
  state = {
    leftTextHead: "",
    leftTextBody: "",
    loading: false,
    page_sequence_number: 0,
    questions: [],
    error: false,
    loadingQuestions: true,
    fromUpdate: "",
    fromListUpdate: false,
    prev_button_status: false,
    isListEmpty: false,
    other_params: [],
    selectedIndex: -1,
    listAction: null,
    inputRequest: [],
    leftTextTitle:"",
    reflexiveRequiredSchema: null,
    knockoutErrors: ""
  };

  __ISMOUNTED = false;

  componentDidMount = async () => {
    utils.scrollUP();
    this.__ISMOUNTED = true;
    const {
      location: { state }
    } = this.props;

    // if user has clicked on back button and had crossed the questions component,
    // then irrespective of available data, always make an questions api call.
    if (!this.isBackToQuestionsPage()) {
      if (state && state.response && state.fromReview === true) {
        this.getToUpdateQuestions(state.response, false);
      } else if (state && state.response && state.fromUpdate === true) {
        this.getToUpdateQuestions(state.response, state.fromUpdate);
      } else if (state && state.response) {
        this.gotoQuestions(state);
      } else {
        this.getNewSetQuestionsHandler(QUESTIONS_RENDER.init);
      }
    } else {
      this.getNewSetQuestionsHandler(QUESTIONS_RENDER.init);
    }
    //ActivityLog.saveActivity("loadedQuestionnairePage");
  };

  componentWillUnmount = () => {
    this.__ISMOUNTED = false;
  };

  /**
   * @description function identifies user has pressed the back browser button and
   * landed on questions page from a different route.
   * @returns {Boolean}
   */
  isBackToQuestionsPage () {
    const historyAction = get(this, "props.history.action", "");
    return historyAction === "POP" && this.props.lastLocation !== null;
  }

  axiosCallHelper = async (uriPath, q_id) => {
    const completedQuestionsFormData = new FormData();
    //const globalParams = getGlobalParams(this.props);
    const globalParams=Object.assign({ 'uid': getUid(this.props.location.search) })
    if (typeof q_id === "string") {
      q_id = JSON.parse(q_id);
    }
    let formDataForCompeltedQues = {
      ...globalParams,
      ...q_id
    };
    prepareFormData(completedQuestionsFormData, formDataForCompeltedQues);
    return await axiosInstance.post(uriPath, completedQuestionsFormData);
  };
   /**
   * @description Used to set knockout error popup messages.
   */
  setknockoutErrors = error => {
    this.setState({ knockoutErrors: error });
  }

  gotoQuestions = async state => {
    this.setState({ loading: true });
    try {
      let res = await this.axiosCallHelper("/questions", state.response);
      res = JSON.parse(JSON.stringify(res));
      if (res.status === 200) {
        const ifRedirected =redirectIfNeeded(this.props, res)
        if(ifRedirected){
          return;
        }
        if (res && res.data && res.data.response && res.data.response.loginRedirectUrl) {
          window.location.href = res.data.response.loginRedirectUrl;
          return;
        }
        this.getQuestionsSuccess(res);
      }
    } catch (e) {
      saveException({apiName:'/v1/question',message:e});
      console.log(e);
    }
    this.setState({ loading: false });
  };
  /**
   * call this function when the update is clikced on
   * the review page and render those questions instead
   */
  getToUpdateQuestions = (res, fromUpdate) => {
    handleGtmVirtalPageVisits(JSON.parse(res));
    this.getQuestionsSuccess(res, fromUpdate);
  };

  /**
   * This is called when the update is clicked on questionsLib
   * and it will make a call to the questions API with params
   *
   * uid: uid that is defined in the redux store,
   * edit_flag: set to 1 as we are updating the questions,
   * questions: we are getting them as the only argumet
   * page_seq_number: send this as it is mandatory
   */
  updatedQuestionsArray = async (questions, isListQuestion = false, parentQuestionGroup = null) => {
    //const commonGlobalParams = getGlobalParams(this.props);
    const commonGlobalParams=Object.assign({ 'uid': getUid(this.props.location.search) })
    const { page_sequence_number } = this.state;
    const formDataForUpdate = new FormData();

    const isList =
      this.state.listAction === LIST_ACTION.ADD ||
      this.state.listAction === LIST_ACTION.UPDATE ||
      this.state.listAction === LIST_ACTION.DELETE;
    const isFromListQuestion = isListQuestion || isList;
    if (!isFromListQuestion && questions && this.state.questions) {
      questions = insertMissingQuestions(
        this.state.questions.questions,
        questions
      );
    }
    let updateFormDataVars;

    let isListAction = this.state.fromListUpdate;

    if (isListAction) {
      updateFormDataVars = {
        uid: commonGlobalParams.uid,
        questions,
        page_sequence_number
      };
      if (this.state.listAction === LIST_ACTION.DELETE) {
        updateFormDataVars.delete_index_flag = 1;
      } else {
        updateFormDataVars.index_modify_flag = 1;
      }
    } else {
      updateFormDataVars = {
        uid: commonGlobalParams.uid,
        questions,
        edit_page_flag: 1,
        action: 'CONFIRM_UPDATE',
        page_sequence_number
      };
    }
    prepareFormData(formDataForUpdate, updateFormDataVars);
    try {
      this.setState({ loadingQuestions: true });
      const questionResponse = await axiosInstance.post(
        "/questions",
        formDataForUpdate
      );
      const otherParams = get(questionResponse, "data.response.other_params", {});
      const { show_edit_page_flag: showEditPageFlag, current_status: currentStatus } = otherParams;
      if ( currentStatus === "questions" && (showEditPageFlag || isListAction) ) {
        // Adding the reflexive support
        // console.log("SHOW NEW QUESTION WE ARE GETTING THE API");
        this.getQuestionsSuccess(questionResponse);
      } else {
        const didRedirect = redirectIfNeeded(this.props, questionResponse, {
          skipApiCall: true
        });
        if (!didRedirect) {
          this.setState({ loading: true, loadingQuestions: false });
          // Send the user back to completed page
          utils.scrollUP("smooth");
          this.props.history.goBack(-1);
        }
      }
      if (this.__ISMOUNTED) {
        this.setState({ loading: false, loadingQuestions: false });
      }
      return;
    } catch (e) {
      saveException({apiName:'/v1/question',message:e});
      console.log(e);
    }
  };

  handleListAction = (ListAction, index, request, isEmpty) => {
    if (LIST_ACTION.ADD === ListAction) {
      //Add
      this.getNewSetQuestionsHandler(
        QUESTIONS_RENDER.update,
        [],
        this.state.fromUpdate,
        ListAction
      );
      this.setState({
        // selectedIndex: index,
        //fromUpdate: true,
        isListEmpty: isEmpty ? true : false,
        fromListUpdate: true,
        listAction: ListAction,
        inputRequest: request
      });
    } else if (
      LIST_ACTION.UPDATE === ListAction ||
      LIST_ACTION.DELETE === ListAction
    ) {
      /*if (this.state.questions) {
        let questionsWithAnswer = insertMissingQuestions(
          this.state.questions.questions,this.state.
        );
      }*/
      //Update
      this.setState({
        selectedIndex: index,
        //fromUpdate: true,
        fromListUpdate: true,
        isListEmpty: false,
        listAction: ListAction,
        inputRequest: request
      });
    } else if (LIST_ACTION.CANCEL === ListAction) {
      this.setState({
        //fromUpdate: false,
        fromListUpdate: false,
        selectedIndex: -1,
        listAction: ListAction
      });
    }
  };
  setInputRequest = request => {
    this.setState({
      selectedIndex: -1,
      inputRequest: request
    });
  };

  eSignHandler = async STATE_RENDER => {
    try {
      this.setState({ loadingQuestions: true });
      if (STATE_RENDER === QUESTIONS_RENDER.next) {
        this.SM_FLAG = true;
      } else {
        this.SM_FLAG = false;
      }
      //const commonGlobalParams = getGlobalParams(this.props);
      const commonGlobalParams = Object.assign({ 'uid': getUid(this.props.location.search) })
      const formData = new FormData();
      formData.append("docusign_flag", 1);
      prepareFormData(formData, commonGlobalParams);
      const questionResponse = await axiosInstance.post("/questions", formData);
      if (
        questionResponse &&
        questionResponse.data &&
        questionResponse.data.response &&
        questionResponse.data.response.other_params
      ) {
        // console.log("SHOW ESIGN NEXT PAGE.....");
        this.getQuestionsSuccess(questionResponse);
      }
      return;
    } catch (e) {
      saveException({apiName:'/v1/question',message:e});;
      console.log(e);
    }
  };

  /**
   * function to handle the change set in the child component
   * and get a new set of questions
   * or get the same set with the error message
   * {STATERENDER} can get 3 values INTIAL, NEXT, PREVIOUS
   */
  getNewSetQuestionsHandler = async (
    STATE_RENDER,
    questionsWithAnswer,
    fromUpdate,
    listAction,
    cancelProps = {}
  ) => {
    const { isCancelAction, pageSeqNumber } = cancelProps;
    let requestParams = {};
    //const commonGlobalParams = getGlobalParams(this.props);
    const commonGlobalParams=Object.assign({ 'uid': getUid(this.props.location.search) })
    if (isCancelAction) {
      try {
        const formData = new FormData();
        const params = {
          ...commonGlobalParams,
          action: 'CANCEL_UPDATE',
          page_sequence_number: this.state.page_sequence_number
        }
        prepareFormData(formData, params);
        this.setState({ loadingQuestions: true });
        const discardPrevResponse = await getNewQuestions(
          "/questions",
          formData,
          this.props.test
        );
        const didRedirect = redirectIfNeeded(this.props, discardPrevResponse, {
          skipApiCall: true
        });
        if (!didRedirect) {
          // go back from browser history if the backend is not sending review page parameter
          // to go back to review page.
          this.props.history.goBack();
        }
      } catch (err) {
        console.error(err)
      }
      return;
    } else {
      /**
       * This is to insert the unanswered question by the user
       * requirement of the API to have all the answers and if they are not required
       * then insert a blank value
       *
       * This need to be handled for 3 cases
       * 1. Top level questions
       * 2. Group Questions
       * 3. List Quesions TODO
       */
      const isListContinueAction = listAction && listAction === LIST_ACTION.NEXT;
      if (!isListContinueAction && questionsWithAnswer && this.state.questions) {
        questionsWithAnswer = insertMissingQuestions(
          this.state.questions.questions,
          questionsWithAnswer
        );
      }

      /**
       * Get the questions for the user based on the used id provdied in the queryparasms
       * make an axios instance and call it
       */
      if (STATE_RENDER !== QUESTIONS_RENDER.init) {
        requestParams = {
          ...requestParams,
          page_sequence_number: this.state.page_sequence_number
        }
      }
      this.setState({ loadingQuestions: true });
      if (STATE_RENDER === QUESTIONS_RENDER.next) {
        this.setState({ loadingQuestions: true });
        requestParams = {
          ...requestParams,
          next_page_flag: 1,
          questions: questionsWithAnswer
        }
      } else if (
        STATE_RENDER === QUESTIONS_RENDER.prev ||
        (STATE_RENDER === QUESTIONS_RENDER.cancel && this.state.isListEmpty)
      ) {
        this.setState({ loadingQuestions: true, isListEmpty: false });
        requestParams = {
          ...requestParams,
          prev_page_flag: 1,
        }
      } /*else if (STATE_RENDER === QUESTIONS_RENDER.update) {
        formData.append("questions", questionsWithAnswer);
      }*/
      if (listAction === LIST_ACTION.ADD) {
        this.setState({ loadingQuestions: true });
        requestParams = {
          ...requestParams,
          index_modify_flag: 1,
          next_page_flag: 1
        }
      }

      requestParams = {
        ...requestParams,
        ...commonGlobalParams,
      }
      localDb.saveQuestionsResponse(requestParams);
    }

    // /**
    //  *  If the user is in review/update flow, on refresh, we should send
    //  *  cancel update action.
    //  */
    // const historyAction = get(this, "props.history.action", "");
    if (STATE_RENDER !== QUESTIONS_RENDER.next && this.props.lastLocation === null) {
      requestParams = {
        ...requestParams,
        is_refresh: true,
      }
    }

    const formData = new FormData();
    if (this.state.other_params.challenge) {
      delete requestParams.next_page_flag
    }

    prepareFormData(formData, requestParams);
    let questionResponse = {};

    try {
      questionResponse = await getNewQuestions(
        "/questions",
        formData,
        this.props.test
      );
      handleGtmQuestionsSubmission(questionsWithAnswer);
      // fake update store.
      if (!listAction || listAction === LIST_ACTION.NEXT) {

        // if this is a review page, do not call this handleGtmVirtalPageVisits
        // the virtual page view is handled by review component, see review.js
        const isReviewPage = get(questionResponse, "data.response.other_params.current_status", "") === "review";
        if (!isReviewPage) {
          handleGtmVirtalPageVisits(questionResponse);
        }
      }
      if (questionResponse.status === 200) {
        // if needed redirect to login.
        checkLoginRedirection(questionResponse);
        const didRedirect = redirectIfNeeded(this.props, questionResponse, {
          skipApiCall: true
        });

        // if did not redirect, continue questions flow.
        if (!didRedirect) {
          this.getQuestionsSuccess(
            questionResponse,
            fromUpdate, //this.state.isListEmpty ? true :
            listAction === LIST_ACTION.ADD,
            listAction
          );
          utils.scrollUP();
        }
      } else throw new Error(questionResponse.status);
    } catch (e) {
      saveException({apiName:'/v1/question',message:e});
      this.getQuestionsFailure(e);
    }
    return true;
  };


  loadReflexiveQuestions = async (questionId, questionsArray, errors, isQuestionAnswered, reflexiveHandle) => {
    const currentQuestionAndAnswer = questionsArray.filter(ele=>ele.question_id===questionId)
    if((isQuestionAnswered && isQuestionAnswered.length>0) || (reflexiveHandle && reflexiveHandle[0] === currentQuestionAndAnswer[0].answer) || (reflexiveHandle && reflexiveHandle.length>1 ) ||!reflexiveHandle)
    {
    const commonGlobalParams=Object.assign({ 'uid': getUid(this.props.location.search) });
    const formData = new FormData();


    formData.append("questions", JSON.stringify(removeErrorQuestionInReflexiveCall(questionsArray, errors)));
    formData.append("page_sequence_number", this.state.page_sequence_number);
    if (this.state.fromUpdate) {
      formData.append("reflexive_question_flag",  1)
    }
    prepareFormData(formData, commonGlobalParams);

    this.setState({
      showReflexiveLoaderFor: questionId,
    });
    const response = await getNewQuestions(
      "/questions",
      formData,
      this.props.test
    );
    if (response) {
      redirectIfNeeded(this.props, response);
    } else {
      this.setState({
        showReflexiveLoaderFor: "",
      });
      console.error("response not received from backend.");
    }
    const updatedQuestions = get(response, "data.response.data.questionnarie.questions", null);
    const currentStatus = get(response, "data.response.other_params.current_status", "");
    if (currentStatus === "completed") {
      this.props.history.push(`/thankyou${this.props.location.search}`, {
        response: JSON.stringify(response)
      });
    }
    if (updatedQuestions) {

      const newQuestions = insertReflexiveQuestion([updatedQuestions], questionId);
      const requiredSchema = CreateRequiredSchema(newQuestions);
      this.setState({
        questions: newQuestions,
        reflexiveRequiredSchema: requiredSchema,
      });
    }
    this.setState({
      showReflexiveLoaderFor: "",
    });
    this.callFormotivConfig(this.state.leftTextHead);
  }
  }

  render = () => {

    const {
      leftTextBody,
      leftTextHead,
      leftTextTitle,
      loading,
      questions,
      requiredSchema,
      error,
      page_sequence_number,
      loadingQuestions,
      fromUpdate,
      knockoutErrors
    } = this.state;

    const leftTextHTML = this.renderLeftTextHelper(leftTextHead, leftTextBody, leftTextTitle);

    return (
      <LandingPage leftText={leftTextHTML} loading={loading}>
        {!loading && !error ? (
          <React.Fragment>
            {loadingQuestions ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 318
                }}
              >
                <div className="lds-dual-ring" />
                {this.SM_FLAG ? (
                  <h4>Please wait while forms are generated for e-sign</h4>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className={`question-lib-wrapper ${this.state.showReflexiveLoaderFor ? 'disable-page' : ''}`}>
                {
                  knockoutErrors
                  ? (
                      <div className="normalize-layout-right">
                        <ErrorPopup title={knockoutErrors.title} message={knockoutErrors.message} />
                      </div>
                    )
                  : null
                }
                <QuestionsLib
                  questions={questions}
                  requiredSchema={requiredSchema}
                  page_sequence_number={page_sequence_number}
                  getNewSetQuestionsHandler={this.getNewSetQuestionsHandler}
                  updatedQuestionsArray={this.updatedQuestionsArray}
                  loadingQuestions={loadingQuestions}
                  fromUpdate={fromUpdate}
                  prev_button_status={this.state.prev_button_status}
                  other_params={this.state.other_params}
                  getToUpdateQuestions={this.getToUpdateQuestions}
                  selectedIndex={this.state.selectedIndex}
                  handleListAction={this.handleListAction}
                  listAction={this.state.listAction}
                  inputRequest={this.state.inputRequest}
                  isListQuestion={this.state.isListQuestion}
                  setInputRequest={this.setInputRequest}
                  fromListUpdate={this.state.fromListUpdate}
                  eSignHandler={this.eSignHandler}
                  setknockoutErrors={error => this.setknockoutErrors(error)}
                  updateQuestion={this.updateQuestion}
                  loadReflexiveQuestions={this.loadReflexiveQuestions}
                  showReflexiveLoaderFor={this.state.showReflexiveLoaderFor}
                  reflexiveRequiredSchema={this.state.reflexiveRequiredSchema}
                  formotivURI={leftTextHead}
                  {...this.props}
                />
              </div>
            )}
          </React.Fragment>
        ) : (
          <div>Something went wrong!</div>
        )}
      </LandingPage>
    );
  };

  renderLeftTextHelper = (leftTextHead, leftTextBody, leftTextTitle) => {
    return (
      <div>
        {/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
        <p className='global-left-panel-page-title' dangerouslySetInnerHTML={{__html:leftTextTitle}}/>
        <p className='global-left-panel-title' dangerouslySetInnerHTML={{ __html: leftTextHead }} />
        <p
          dangerouslySetInnerHTML={{ __html: leftTextBody }}
        />
      </div>
    );
  };

  getQuestionsSuccess = (questionResponse, fromUpdate, fromListUpdate, listAction) => {
    if (typeof questionResponse === "string") {
      questionResponse = JSON.parse(questionResponse);
    }
    /**
     * Start destructuring the object to get the desired
     * values and set them accordingly
     */
    const {
      data: {
        response: {
          page_desc,
          breadcrumbs,
          other_params,
          errors,
          data: {
            questionnarie: {
              page_sequence_number,
              completed,
              // This will be an object if completed is false
              // Other wise this will be an array
              questions
            }
          }
        }
      }
    } = questionResponse;
    // Set the bread crumbs
    this.props.setBreadCrumbs(breadcrumbs);
    // Set the left text title and content first
    if (
      completed === false ||
      (other_params && other_params.current_status === "document")
    ) {
      // Get the required schema for first render
      const requiredSchema = CreateRequiredSchema(questions);
      const updatedQuestions = insertReflexiveQuestion([questions]);
      this.setState({
        loading: false,
        leftTextHead: page_desc ? page_desc.title : "",
        leftTextBody: page_desc ? page_desc.desc : "",
        leftTextTitle: page_desc ? page_desc.heading : "",
        page_sequence_number,
        questions: updatedQuestions,
        other_params,
        requiredSchema,
        error: false,
        fromUpdate: fromUpdate || "",
        fromListUpdate: fromListUpdate ? fromListUpdate : false,
        prev_button_status: other_params
          ? other_params.prev_button_status
          : "false",
        loadingQuestions: false,
        reflexiveRequiredSchema: null,
        knockoutErrors: errors,
        listAction: listAction,
      });
    } else if (other_params && other_params.current_status === "docusign") {

      // console.log(other_params.next_button_redirect_url);
      window.location.href = other_params.next_button_redirect_url;
    } else {
      this.props.history.push(`/completed${this.props.location.search}`, {
        questions,
        title: page_desc ? page_desc.title : "",
        desc: page_desc ? page_desc.desc : ""
      });
    }
    this.callFormotivConfig(page_desc ? page_desc.title : 'Questions');
  };

  callFormotivConfig = altFormURI => {
    const existingFomrotivConfig = getForMotivConfig();
    const existingAltFormURI = existingFomrotivConfig.discovery.altURIHandler.altFormURI;
    if(existingAltFormURI === altFormURI){
      window.formotiv.rescan(getForMotivConfig(altFormURI));
    }
    else{
      window.formotiv.init(getForMotivConfig(altFormURI));
    }
  }

  getQuestionsFailure = error => {
    // showMessage(MessageType.ERROR, error.message);
    this.setState({
      error: true,
      apiError: error,
      loading: false,
      loadingQuestions: false
    });
    console.log(error)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setBreadCrumbs }, dispatch);
}

export default withLastLocation(connect(null, mapDispatchToProps)(Questions));
