import React from "react";
import Select from "react-select";
import { isArray } from "lodash";
import debounce from "debounce-promise";
import { Tooltip, Loader } from "..";
import {ErrorTypes, getErrMsg} from "../../../util";
import "./Dropdown.css";
import { CustomOption, isMobile, getForMotivConfig } from "../../../util";

/**
 * We need to add the logic for error validations.
 * check what all Validations we are getting ?
 * ok so we have a required field but
 * it is not neccassary that we always have a required field!!!
 * for a single select dropdown either the selected option is an array of length 0
 * if nothing is selected
 * else and object if single option is selected
 * but We will do a future proof check to handle the length of [] and explicitly
 * checking it with the value 0
 *
 * so for the first check,
 * 1. check for the required field value
 * 2. check for the isArray or not
 * 3. check for the length of that array
 */

export default class Dropdown extends React.Component {
  state = {
    selectedOption: null,
    isMulti: false,
    inputValue: "",
    question_text: "",
    question_id: "",
    value: "",
    error_messge: "",
    index: "",
    response_options: [],
    response_id: "",
    response_label: "",
    fieldError: false,
    child_questions: ""
  };

  sendDataBackToParentDebounce = debounce(this.props.handleSingleDropDown, 200);

  static getDerivedStateFromProps(props, state) {
    const { requiredError, nextButtonOnClick } = props;

    if (requiredError !== ErrorTypes.noError && nextButtonOnClick)
      return {fieldError: true}
    else return {fieldError: false}
  }

  getBorderColor = state => {
    const { fieldError } = this.state;
    if (fieldError === true) {
      return "red";
    } else if (state.isFocused) {
      return "#0074FF;";
    }
    return "#e4e4e4";
  };

  handleChange = selectedOption => {
    if (this.state.selectedOption.id === selectedOption.id) {
      return;
    }
    const { requiredValue, question_id, child_questions } = this.state;
    this.setState({
      selectedOption,
      fieldError:
        Boolean(requiredValue) === true &&
          isArray(selectedOption) &&
          selectedOption.length === 0
          ? true
          : false
    });
    window.formotiv.inputActivity(getForMotivConfig(this.props.formotivURI), `${this.props.questionObj.question_id}`, "dropdown", selectedOption.id);
    this.sendDataBackToParentDebounce(
      selectedOption,
      requiredValue,
      question_id,
      child_questions,
      this.props.questionObj.child_questions_on

    );
  };

  componentDidMount() {
    const {
      questionObj: {
        question_id,
        question_text,
        validations,
        index,
        response_options,
        response,
        child_questions,
      }
    } = this.props;

    const requiredValue =
      validations && validations.required ? validations.required.value : false;
    const error_message =
      validations && validations.required
        ? validations.required.error_message
        : "";

    if (response) {
      this.sendDataBackToParentDebounce(response, requiredValue, question_id);
    }

    this.setState({
      question_text,
      question_id,
      requiredValue,
      error_message,
      index,
      response_options,
      selectedOption: response,
      child_questions: child_questions
    });
    this.select.select.inputRef.name = `${this.props.questionObj.question_id}`;
  }

  getErrorMessage = errorType => {
    return getErrMsg(this.props.questionObj, errorType);
  };

  colourStyles = {
    control: (base, state) => ({
      ...base,
      "&:hover": {
        borderColor: this.getBorderColor(state)
      },
      boxShadow: "none",
      height: 44,
      width: isMobile() ? "100%" : "360px",
      outline: "none",
      backgroundColor: 'rgba(255, 255, 255)',
      border: "1px solid #e2e2e2",
      borderRadius: 2,
      marginBottom: 10,
      borderColor: this.getBorderColor(state),
      textTransform:'capitalize'
    }),
    option: (base, state) => {
      return {
        ...base,
        // position: "fixed",
        backgroundColor:
          this.state.selectedOption &&
            this.state.selectedOption.id === state.data.id
            ? "#2424ff"
            : state.isFocused
              ? "#D2E4EA"
              : "#fff",
        color:
          this.state.selectedOption &&
            this.state.selectedOption.id === state.data.id
            ? "#fff"
            : state.isFocused && "#000",
        ":active": {
          backgroundColor: "#2424ff",
          color: "#f5f5f5"
        },
        borderRadius: 2
      };
    },
    menu: (base) => ({
      ...base,
      width: isMobile() ? "100%" : "360px",
      textTransform:'capitalize'
    })
  };

  onKeyDown = (e) => {
    let { response_options } = this.props.questionObj
    let optionFilter = response_options.filter((ele) => {
      return ele.label.toUpperCase().indexOf((e.target.value).toUpperCase()) > -1
    })
    if ((!this.isMenuOpen || optionFilter.length === 0) && e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render() {
    const {
      selectedOption,
      question_text,
      question_id,
      response_options,
      error_message,
      fieldError
    } = this.state;
    let { tooltip, id, is_readonly: disabled } = this.props.questionObj
    let toolTipValue = <div dangerouslySetInnerHTML={{ __html: tooltip }} />
    let { disableClass, requiredError, nextButtonOnClick } = this.props;

    return (
      <div
        style={{
          width: this.props.selectContainerWidth || "100%",
        }}
        id={question_id}
        key={question_id}
      >
        <label className="global-label-text">
          {question_text} {this.props.required}
          {tooltip ? <Tooltip value={toolTipValue} id={id} /> : null}
          {/* {this.props.required && <span className="label_icon_star">*</span>} */}
        </label>
        <div className={`select-box-container ${disabled ? 'disable-input' : ''}`}>
          <Select
            disabled={disabled}
            value={selectedOption}
            onChange={this.handleChange}
            options={response_options}
            styles={this.colourStyles}
            openMenuOnFocus="true"
            onMenuOpen={() => this.isMenuOpen = true}
            onMenuClose={() => this.isMenuOpen = false}
            onKeyDown={this.onKeyDown}
            tabIndex={(disableClass || disabled) ? "-1" : "0"}
            isOptionDisabled={(option) => option.id === 'CountrySeparator'}
            components={{ Option: CustomOption }}
            ref={ref => { this.select = ref; }}
          />
          {(fieldError || (requiredError !== ErrorTypes.noError && nextButtonOnClick)) && (
            <p style={{ color: "red", fontSize: 12 }}>{error_message ? error_message :
             this.getErrorMessage(ErrorTypes.required)}</p>
          )}
        </div>
      </div>
    );
  }
}