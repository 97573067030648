import React from 'react';
import {
  ErrorTypes,
  ToggleContinueButton,
  GetElement,
  groupedQuestionType,
  LIST_ACTION,
  prepareFormData,
  getUid,
  scrollUP,
  trimSpaces,
  getForMotivConfig
} from '../../util';
//import { ActivityLog } from '../../util/ActivityLog';
import { ButtonBlue, GroupedQuestions, ListQuestions, AssesmentGroupQuestions } from './../UIElements';
import { axiosInstance } from "../../util/axios";
import './loader.css';
import { removeObsoleteQuestions, removeObsoleteErrors, createRequiredFieldErrors, shouldMakeReflexiveCall } from '../../Pages/Questions/helper';
import { TOGGLE_VALIDATION } from '../../Pages/Questions';
import get from 'lodash/get'
const QUESTIONS_RENDER = {
  init: 'INITIAL',
  next: 'NEXT',
  prev: 'PREVIOUS',
  update: 'UPDATE',
  cancel: 'CANCEL'
};

export default class QuestionsLib extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    leftText: '',
    leftTextTitle: '',
    errors: {},
    questionsArr: [],
    requiredSchemaTrue: [],
    doEnable: false,
    fromChild: false,
    isListQuestion: false,
    isGroupedQuestion: false,
    disableClass: '',
    nextButtonOnClick: false
  };

  static getDerivedStateFromProps(props, state) {
    let requiredSchema = [];
    let questionsArr = [];
    let errorsArr = state.errors;
    if (props.reflexiveRequiredSchema) {
      requiredSchema = props.reflexiveRequiredSchema;
      questionsArr = removeObsoleteQuestions(props.questions, state.questionsArr);
      errorsArr = removeObsoleteErrors(state.errors, questionsArr);
    } else {
      requiredSchema = !state.fromChild
        ? props.requiredSchema
        : state.requiredSchema;
    }

    const doEnable = ToggleContinueButton(
      requiredSchema,
      state.questionsArr,
      errorsArr
    ) && !props.showReflexiveLoaderFor
    return {
      doEnable,
      questionsArr: questionsArr.length ? questionsArr : state.questionsArr,
      errors: createRequiredFieldErrors(requiredSchema, errorsArr, props.questions)
    };
  }

  shouldComponentUpdate(_, nextState) {
    if (
      this.state.doEnable !== nextState.doEnable ||
      this.state.questionsArr.length === nextState.questionsArr.length
    ) {
      return true;
    } else {
      return false;
    }
  }

  continuebuttonStatus = requiredSchemaGrouped => {
    this.setState({ requiredSchema: requiredSchemaGrouped, fromChild: true });
  };

  setListQuestionStatus = (status, inputRequest) => {
    this.setState({ isListQuestion: status });
    this.props.setInputRequest(inputRequest);
  };

  setGroupQuestionStatus = status => {
    this.setState({
      isGroupedQuestion: status
    });
  };
  handleInputChange = (inputValue, error, question_id) => {
    this.updateQuestionsArrState(question_id, inputValue, error);
  };
  handleAutoSuggestionOnChange = (inputValue, error, question_id) => {
    this.updateQuestionsArrState(question_id, inputValue, error);
  };
  handleLabelChange = (question_id, inputValue) => {
    this.updateQuestionsArrState(question_id, inputValue, ErrorTypes.noError);
  };

  handleRadioChange = (question_id, label, value) => {
    const error =
      value === true && label ? ErrorTypes.noError : ErrorTypes.required;
    this.updateQuestionsArrState(question_id, label, error);
  };

  handleDateChange = (value, outPut, question_id) => {
    this.updateQuestionsArrState(question_id, value, outPut);
  };

  handleSingleSelectBtnClick = (inputValue, question_id, child_question, childQuestionsOn) => {
    const reflexiveOn = shouldMakeReflexiveCall(this.state.questionsArr, childQuestionsOn, question_id, inputValue);
    this.updateQuestionsArrState(question_id, inputValue, 'NO_ERROR');
    if (child_question && reflexiveOn) {
      // due to async update, state errors don't seem to be updated, so update while
      // passing the props.
      this.props.loadReflexiveQuestions(question_id, this.state.questionsArr, {
        ...this.state.errors,
        [question_id]: 'NO_ERROR'
      });
    }
  };

  handleMultiSelectBtnClick = (inputValue, question_id, output, allowSubit, child_question, question_type, childQuestionsOn) => {

    // Split here

    allowSubit ? (inputValue.length > 0 ? this.setState({
      disableClass: ''
    }) :
      this.setState({
        disableClass: 'disable-questions'
      }))
      : this.setState({
        disableClass: ''
      })

    if (inputValue && inputValue.length && !allowSubit) {
      inputValue = inputValue.map(ele => ele.value || ele);
    }    
    else if(inputValue && inputValue.length && allowSubit){
      inputValue = inputValue.length > 0  ? inputValue[0] : null
    } 
    else{
      inputValue = "";
    }

    const reflexiveOn = shouldMakeReflexiveCall(this.state.questionsArr, childQuestionsOn, question_id, inputValue);
    this.updateQuestionsArrState(question_id, inputValue, output,question_type);
    if (child_question && reflexiveOn) {
      // due to async update, state errors don't seem to be updated, so update while
      // passing the props.
      this.props.loadReflexiveQuestions(question_id, this.state.questionsArr, {
        ...this.state.errors,
        [question_id]: output
      });
    }
  };

  handleFormDisable = () => {
    this.setState({
      disableClass: 'disable-questions'
    })
  }

  singleSelectHelper = (requiredSchemaTrue, question_id, selectedOptionId) => {
    if (
      (requiredSchemaTrue &&
        (requiredSchemaTrue.includes(question_id) && selectedOptionId)) ||
      (requiredSchemaTrue && !requiredSchemaTrue.includes(question_id))
    ) {
      this.updateQuestionsArrState(
        question_id,
        selectedOptionId,
        ErrorTypes.noError
      );
    } else if (
      requiredSchemaTrue &&
      requiredSchemaTrue.includes(question_id) &&
      !selectedOptionId
    ) {
      this.updateQuestionsArrState(
        question_id,
        selectedOptionId,
        ErrorTypes.required
      );
    } else {
      this.updateQuestionsArrState(
        question_id,
        selectedOptionId,
        ErrorTypes.noError
      );
    }
  };

  axiosCallHelper = async (uriPath, q_id) => {
    const completedQuestionsFormData = new FormData();
    //const globalParams = getGlobalParams(this.props);
    const globalParams = Object.assign({ 'uid': getUid(this.props.location.search) })
    let formDataForCompeltedQues = {
      ...globalParams,
      ...q_id
    };
    prepareFormData(completedQuestionsFormData, formDataForCompeltedQues);
    return await axiosInstance.post(uriPath, completedQuestionsFormData);
  };

  handleSingleDropDown = async ({ id: selectedOptionId }, required, question_id, child_question, childQuestionsOn) => {
    const reflexiveOn = shouldMakeReflexiveCall(this.state.questionsArr, childQuestionsOn, question_id, selectedOptionId);
    const { requiredSchemaTrue } = this.state;
    /**
     * if the option is selected then no error push it in the questions Arr
     * if the option is not selected and field is required then error
     * push in error array and remove from quesrionArr
     * if the option is not selected and the field is not requried then no error push in question Arr
     */
    if (requiredSchemaTrue.length > 0) {
      this.singleSelectHelper(
        requiredSchemaTrue,
        question_id,
        selectedOptionId
      );
    } else if (this.props.requiredSchema.length > 0) {
      this.singleSelectHelper(
        this.props.requiredSchema,
        question_id,
        selectedOptionId
      );
    } else if (
      Array.isArray(requiredSchemaTrue) &&
      requiredSchemaTrue.length === 0
    ) {
      this.singleSelectHelper(undefined, question_id, selectedOptionId);
    }

    //@descrption
    // checking if question_id contains BeneficiaryRelationship to block reflexive api call
    // this should be ideally handled from backend ini future  
    //bug description https://sureify.atlassian.net/browse/AAA-451
    if (child_question && question_id.indexOf('BeneficiaryRelationship')===-1 && reflexiveOn) {
      this.props.loadReflexiveQuestions(question_id, this.state.questionsArr, this.state.errors);
    }
  };

  handlingFunctions = {
    handleLabelChange: this.handleLabelChange,
    handleInputChange: this.handleInputChange,
    handleDateChange: this.handleDateChange,
    handleRadioChange: this.handleRadioChange,
    handleSingleDropDown: this.handleSingleDropDown,
    handleSingleSelectBtnClick: this.handleSingleSelectBtnClick,
    handleMultiSelectChange: this.handleMultiSelectBtnClick,
    handleAutoSuggestionOnChange: this.handleAutoSuggestionOnChange,
    handleFormDisable: this.handleFormDisable
  };

  questionStructure = ({ questions }) => {
    const { questionsArr, errors, disableClass, nextButtonOnClick } = this.state;
    if (questions && Array.isArray(questions)) {
      return questions.map((question, index) => {
        if (question.question_type !== 'group') {
          return GetElement(
            question,
            index,
            this.props.requiredSchema,
            this.handlingFunctions,
            questionsArr,
            errors,
            nextButtonOnClick,
            this.props.location,
            this.props.showReflexiveLoaderFor,
            disableClass,
            this.props.formotivURI
          );
        } else {
          if (question.display_type === groupedQuestionType.group) {
            this.updateParentQuestionGroup(question);
            return (
              <GroupedQuestions
                key={index}
                question={question}
                questionsArr={questionsArr}
                handlingFunctions={this.handlingFunctions}
                errors={errors}
                questionStructure={this.questionStructure}
                continuebuttonStatus={this.continuebuttonStatus}
                setGroupQuestionStatus={this.setGroupQuestionStatus}
              />
            );
          } else if (question.display_type === groupedQuestionType.list) {
            /**
             * Render the list object of the UI
             * TODO Need to check the props
             */
            return (
              <ListQuestions
                key={index}
                question={question}
                questionsArr={questionsArr}
                handlingFunctions={this.handlingFunctions}
                errors={errors}
                questionStructure={this.questionStructure}
                continuebuttonStatus={this.continuebuttonStatus}
                fromUpdate={this.props.fromUpdate}
                fromListUpdate={this.props.fromListUpdate}
                selectedIndex={this.props.selectedIndex}
                handleListAction={this.props.handleListAction}
                setListQuestionStatus={this.setListQuestionStatus}
                listAction={this.props.listAction}
                formotivURI={this.props.formotivURI}
              />
            );
          } else if (question.display_type === groupedQuestionType.assement_group || question.display_type === groupedQuestionType.assement_extend) {
            return (
              <AssesmentGroupQuestions
                key={index}
                question={question}
                questionsArr={questionsArr}
                handlingFunctions={this.handlingFunctions}
                errors={errors}
                questionStructure={this.questionStructure}
                continuebuttonStatus={this.continuebuttonStatus}
              />
            )
          }

          else {
            return <div>Work in progress! Kindly wait for update</div>;
          }
        }
      });
    } else {
      return null;
    }
  };

  eSignButtonHandler = handle => async () => {
    if (this.props.page_sequence_number === 1) {
      window.formotiv.submit(getForMotivConfig(this.props.formotivURI));
      this.props.history.push(`/interstatial${this.props.location.search}`);
      return;
    }
    if (handle === QUESTIONS_RENDER.next) {
      window.formotiv.submit(getForMotivConfig(this.props.formotivURI));
      // console.log("Esign Button Clicked..!");
      // QUESTIONS API WITH docusign_flag=1;
      this.props.eSignHandler(handle);
      //ActivityLog.saveActivity('docuSignPageDisplayed');
    } else if (handle === QUESTIONS_RENDER.prev) {
      //this.props.history.goBack(-1);
      scrollUP("smooth");
      this.navigateToPreviousPage(handle);
    }
  };

  navigateToPreviousPage = handle => {
    if (this.props.other_params.prev_button_state === 'requote') {
      this.props.history.push(`/requote${this.props.location.search}`);
    } else {
      this.props.getNewSetQuestionsHandler(
        handle,
        JSON.stringify(this.state.questionsArr),
        false,
        null
      );
    }
  };
  /**
   * Handler function to handle the continue/Prev/update button click.
   *
   * 1. When Continue/Prev is clicked:
   * Sends data back to parent and
   * get the new set of questions
   * after the successful response or the same questions with faliure response
   *
   * 2. When Update is clicked:
   * Sends data back to parent and updates the record
   * and show it on the review page
   */

  submitBtnClickHandler = handle => async () => {
    switch (handle) {
      case QUESTIONS_RENDER.update:
        //ActivityLogActivityLog.saveActivity('ChangedPreFilledQuestions');
        break;
      case QUESTIONS_RENDER.prev:
        //ActivityLog.saveActivity('btnPreviousQuestions');
        break;
      case QUESTIONS_RENDER.cancel:
        //ActivityLog.saveActivity('btnCancelUpdate');
        break;
      case QUESTIONS_RENDER.next:
        //ActivityLog.saveActivity('btnNextQuestions');
        break;
      default:
        console.log('Other Action');
    }
    scrollUP("smooth");
    const isListAction =
      this.props.listAction === LIST_ACTION.ADD ||
      this.props.listAction === LIST_ACTION.UPDATE ||
      this.props.listAction === LIST_ACTION.DELETE;
      const formUpdate = get(this.props,"other_params.show_edit_page_flag",false)
    if (isListAction && handle === QUESTIONS_RENDER.cancel) {
      await this.props.getNewSetQuestionsHandler(
        handle,
        JSON.stringify(this.prepareRequest(QUESTIONS_RENDER.cancel))
      );
      this.props.handleListAction(LIST_ACTION.CANCEL)
      /* if (this.props.listAction === LIST_ACTION.ADD) {
        await this.props.getNewSetQuestionsHandler(
          handle,
          JSON.stringify(this.prepareRequest(QUESTIONS_RENDER.cancel))
        );
      } else this.props.handleListAction(LIST_ACTION.CANCEL);*/
      this.continuebuttonStatus([]);
      this.setState({ errors: {} });
    } else if (
      this.state.isGroupedQuestion &&
      (this.props.fromUpdate || formUpdate) &&
      handle === QUESTIONS_RENDER.cancel
    ) {
      window.formotiv.submit(getForMotivConfig(this.props.formotivURI));
      this.props.getNewSetQuestionsHandler (null, null, null, null, {
        isCancelAction: true,
        pageSeqNumber: this.props.page_sequence_number
      });
    // }
    // else if(handle===QUESTIONS_RENDER.cancel){
    //   this.props.getNewSetQuestionsHandler (null, null, null, null, {
    //     isCancelAction: true,
    //     pageSeqNumber: this.props.page_sequence_number
    //   });
    } else {
      window.formotiv.submit(getForMotivConfig(this.props.formotivURI));
      //let review_flag = this.props.other_params.show_edit_page_flag;
      if (handle !== QUESTIONS_RENDER.update) {
        let request;
        if (this.state.isListQuestion) {
          request = this.prepareRequest(QUESTIONS_RENDER.next, this.parentQuestionGroup);
        } else {
          request = this.state.questionsArr;
        }

        if(this.state.doEnable) {
          if(handle===QUESTIONS_RENDER.cancel)
             this.props.handleListAction(LIST_ACTION.CANCEL)
          await this.props.getNewSetQuestionsHandler(
            handle,
            JSON.stringify(request),
            false,
            this.state.isListQuestion ? LIST_ACTION.NEXT : null
          );
          this.setState({errors: {}, nextButtonOnClick: false})
        } else {
          this.setState({nextButtonOnClick: true})
          this.props.setknockoutErrors({ title: "There are issues with your form",
            message: "Please review your answers below and make any and all necessary corrections before hitting 'next' to continue with your application." }
          )
        }
      } else {
        this.props.handleListAction(LIST_ACTION.UPDATE)
        let request;
        if (this.state.isListQuestion) {
          request = this.prepareRequest(
            this.props.fromListUpdate
              ? QUESTIONS_RENDER.update
              : QUESTIONS_RENDER.next
          );
        } else {
          request = this.state.questionsArr;
        }
        //Update or Save in case of List questions
        if (this.parentQuestionGroup) {
          request = [...request, this.parentQuestionGroup];
        };
        this.props.updatedQuestionsArray(
          JSON.stringify(request),
          this.state.isListQuestion,
        );
      }
    }
  };

  prepareRequest = (action, parentQuestionGroup) => {
    let request = this.props.inputRequest;
    if (request) {
      request[0].answer = this.state.questionsArr;
    } else {
      request = this.state.questionsArr;
    }
    if (parentQuestionGroup) {
      request = [...request, parentQuestionGroup]
    }
    if (action === QUESTIONS_RENDER.update) {
      request[0].extra_params.list_completed_flag = false;
    } else if (action === QUESTIONS_RENDER.next) {
      request[0].extra_params.list_completed_flag = true;
      delete request[0].extra_params.current_list_answer_index;
    } else if (action === QUESTIONS_RENDER.cancel) {
      request[0].extra_params.list_completed_flag = false;
      delete request[0].extra_params.current_list_answer_index;
    } else if (action === QUESTIONS_RENDER.prev) {
      delete request[0].extra_params.list_completed_flag;
      delete request[0].extra_params.current_list_answer_index;
    }
    return request;
  };

  updateParentQuestionGroup = (question) => {
    if (
      question &&
      Array.isArray(question.questions) &&
      question.questions[0].display_type === groupedQuestionType.list
    ) {
      this.parentQuestionGroup = {
        "question_id": question.question_id,
        "answer": ""
      }
    }
  }

  render = () => {
    // Get the loading state of questions
    const { doEnable } = this.state;
    // Get the Questions
    const { questions, other_params, listAction } = this.props;
    const cancelLink =
      listAction === LIST_ACTION.ADD ||
        listAction === LIST_ACTION.UPDATE ||
        listAction === LIST_ACTION.DELETE ||
        this.state.isGroupedQuestion ? (
          <ButtonBlue
            label={listAction === LIST_ACTION.DELETE ? 'NO' : 'Cancel'}
            btnType={'link'}
            btnClickHandler={this.submitBtnClickHandler(QUESTIONS_RENDER.cancel)}
          />
        ) : (
          <div />
        );

    return (
      <React.Fragment>
        {/* <h2>{questions.question_text}</h2> */}
        <form className={'normalize-layout-right'} style={{ minHeight: '368px' }} onSubmit={(e)=>e.preventDefault()}>
          {this.questionStructure(questions)}
        </form>
        {/* Buttons */}

        {(other_params && other_params.current_status === 'document') ||
          other_params.current_status === 'docusign' ? (
            <div className={'questions-btn-container normalize-layout-right global-button-margin'}>
              <div className='global-button-wrapper'>
                <ButtonBlue
                  label={other_params.next_button_text}
                  btnClickHandler={this.eSignButtonHandler(QUESTIONS_RENDER.next)}
                />
                <ButtonBlue
                  label={other_params.previous_button_text}
                  btnType={'link'}
                  btnClickHandler={this.eSignButtonHandler(QUESTIONS_RENDER.prev)}
                />
              </div>
            </div>
          ) : other_params && other_params.show_edit_page_flag === true ? (
            <div className={'questions-btn-container normalize-layout-right global-button-margin'}>
              <div className='global-button-wrapper'>
                {other_params &&
                  other_params.next_button_display_status !== false && (
                    <ButtonBlue
                      label={
                        this.props.fromListUpdate &&
                          listAction === LIST_ACTION.DELETE
                          ? 'YES'
                          : 'Update'
                      }
                      btnClickHandler={this.submitBtnClickHandler(
                        QUESTIONS_RENDER.update
                      )}
                      disabled={!doEnable}
                    />
                  )}
                {cancelLink}
              </div>
            </div>
          ) : this.props.fromUpdate ? (
            <div className={'questions-btn-container normalize-layout-right global-button-margin'}>
              <div className='global-button-wrapper'>
                <ButtonBlue
                  label={
                    listAction === LIST_ACTION.DELETE
                      ? 'YES'
                      : listAction === LIST_ACTION.ADD
                        ? 'Save'
                        : 'Update'
                  }
                  btnClickHandler={this.submitBtnClickHandler(
                    QUESTIONS_RENDER.update
                  )}
                  disabled={!doEnable}
                />
                {cancelLink}
              </div>
            </div>
          ) : this.props.fromListUpdate ? (
            <div className={'questions-btn-container normalize-layout-right global-button-margin'}>
              <div className='global-button-wrapper'>
                <ButtonBlue
                  label={
                    listAction === LIST_ACTION.DELETE
                      ? 'YES'
                      : listAction === LIST_ACTION.ADD
                        ? 'Save'
                        : 'Update'
                  }
                  btnClickHandler={this.submitBtnClickHandler(
                    QUESTIONS_RENDER.update
                  )}
                  disabled={!doEnable}
                />
                {cancelLink}
              </div>
            </div>
          ) : (
                  questions && (
                    <div className={'questions-btn-container normalize-layout-right global-button-margin'}>
                      <div className='global-button-wrapper mobile-view'>
                        {(other_params && !other_params.is_next_btn_hidden) && (
                            <ButtonBlue
                              label={other_params.next_button_text}
                              btnClickHandler={this.submitBtnClickHandler(
                                QUESTIONS_RENDER.next
                              )}
                              disabled={!doEnable && TOGGLE_VALIDATION}
                            />
                          )}
                        {(other_params && !other_params.is_prev_btn_hidden) &&
                          (
                            <ButtonBlue
                              label={other_params.prev_button_text}
                              btnType={'link'}
                              btnClickHandler={this.eSignButtonHandler(
                                QUESTIONS_RENDER.prev
                              )}
                            />
                          )}
                      </div>
                    </div>
                  )
                )}
      </React.Fragment>
    );
  };

  updateQuestionsArrState = (question_id, answer, error, question_type) => {
    let { questionsArr } = this.state;
    var empty = []

    const index = questionsArr.findIndex(
      obj => obj.question_id === question_id

    );

    if (error && error !== ErrorTypes.noError && index >= 0) {
      if (question_type&&question_type==="multiselection") {
        questionsArr[index] = { question_id, answer: empty };
      }
      else {
        questionsArr[index] = { question_id, answer: "" };
      }

    } else {
      if (index < 0) {
        questionsArr.push({ question_id, answer: trimSpaces(answer) });
      } else {

        questionsArr[index] = { question_id, answer: trimSpaces(answer) };

      }
    }
    this.setState(prevState => ({
      errors: { ...prevState.errors, [question_id]: error },
      questionsArr: [...questionsArr]
    }));
  };
}
