import React, { Component } from "react";
import './assesmentGroupQuestions.css'

export default class AssesmentGroupQuestions extends Component {
	render() {
		let { question } = this.props
		return (
			<div>
				<p className='assesment-group-title'>{question.question_text}</p>
				<div>{this.props.questionStructure(this.props.question)}</div>
			</div>

		)
	}
}