import React, { useRef } from "react";
import ReactTooltip from "react-tooltip";
import './tooltip.css'

function Tooltip (props) {
    let {value,id,delay,link}=props
    const reactTooltipRef = useRef(null);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    return (
        <span>
            <i data-tip data-iscapture="true" data-for={id} className='fa fa-info-circle info'> </i>
            <ReactTooltip
                id={id}
                delayShow={100}
                delayHide={100}
                clickable={true}
                wrapper='span'
                className='tooltip-box'
                effect='float'
                globalEventOff={ isMobile ? 'touchstart' : undefined }
                ref={reactTooltipRef}
                scrollHide
                afterShow={e => {
                    const { target } = e
                    const { tooltipRef } = reactTooltipRef.current

                    if (!tooltipRef) return

                    const targetRect = target.getBoundingClientRect()
                    const rect = tooltipRef.getBoundingClientRect()

                    const overflownLeft = rect.left < 0
                    const overflownRight = rect.right > window.innerWidth
                    const overflownTop = rect.top < 0;
                    if (overflownLeft) {
                        tooltipRef.style.setProperty('left', '10px')
                        tooltipRef.style.setProperty('right', 'auto')
                    } else if (overflownRight) {
                        tooltipRef.style.setProperty('left', 'auto')
                        tooltipRef.style.setProperty('right', '10px')
                    }
                    if (overflownTop) {
                        tooltipRef.style.setProperty('top', `${targetRect.y + 30}px`);
                        tooltipRef.style.setProperty('bottom', 'auto');
                    }
                }}
            >
                <span className='tooltip-text'>{value}</span>
                { link && ( <div ><a className='tooltip-link' href='http://www.aaa.com/join' target='_blank' rel="noopener noreferrer">{link}</a></div> ) }
            </ReactTooltip>
        </span>
    );
};

export default Tooltip
