import React from "react";
import "./primary.css";

export default ({ label, btnType, disabled, onClick, className, style }) => {
  switch (btnType) {
    case "pill": {
      return (
        <button className={`btnPill ${disabled && "disabled"}`} tabIndex={disabled ? "-1" : ""}>{label}</button>
      );
    }

    case "rounded": {
      return (
        <button
          style={style ? style : {}}
          tabIndex={disabled ? "-1" : ""}
          className={`btnPill btnRound ${disabled && "disabled"} ${className} `}
          onClick={onClick ? onClick : console.log}
        >
          {label}
        </button>
      );
    }

    case "link": {
      return (
        <button
          className={`btnLinkPrimary ${className ? className : ""}`}
          tabIndex={disabled ? "-1" : ""}
          onClick={onClick}
        >
          {label}
        </button>
      );
    }

    default: {
      return null;
    }
  }
};
