import React, { Component } from "react";
import { axiosInstance } from "../../util/axios";
import './protected.css';

export default class Protected extends Component {
    constructor(props){
        super(props)
        this.state={
            isValidIp:true
        }
    }

    componentDidMount(){
        axiosInstance.post('/checkappaccess').then(res=>{
            this.setState({
                isValidIp:res.data.allow_access
            })
        })
    }

    render(){
        if(this.state.isValidIp){
            return(
                <div>
                    {this.props.children}
                </div>
            )
        }
        else{
            return(
                <div className="err-msg">
                    You cannot access our application!!
                </div>
            )
        }
    }
}