import React, { Component } from "react";
import LandingPage from "../LandingPage/landingPage";
import { ButtonBlue, ErrorPopup, Tooltip, } from '../../Components/UIElements/index'
import { getDetails, sendQuotes, setBreadCrumbs, setUid, setSessionId } from '../../redux/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PAGES_LIST } from '../../util/pages';
import {
  forceScroll,
  scrollUP,
  getUid,
  getSessionId,
  getDefaultPlanId,
  fireVirtualPageEventIfNecessary,
  getQueryParamValue,
  saveException,
  isMobile,
  getForMotivConfig
} from '../../util/index';
import './quotes.scss'
import PlanSelector from "../Premium/PlanSelector";
import CoverageAmountSelector from "../Premium/CoverageAmountSelector";
import PremiumRange from "./PremiumRange";

forceScroll()

class Quote extends Component {

  constructor(props) {
    super(props)
    this.premiumRef = React.createRef()
    this.state={
      isQuoteChanged: false
    }
    window.formotiv.init(getForMotivConfig("Your coverage estimate"));
  }

  async componentDidMount() {
    scrollUP()
    this.props.setBreadCrumbs([])
    if(!Boolean(localStorage.getItem('generatedUid')===getUid(this.props.location.search)))
     localStorage.setItem('generatedUid', getUid(this.props.location.search))
    try{
      let data;
      const uid = getUid(this.props.location.search);
      this.props.setUid(uid);
      data = Object.assign({ uid })
      if(!uid) {
        const session_id = getSessionId(this.props.location.search);
        this.props.setSessionId(session_id);
      }
      let {push}=this.props.history

      // if reloading, get details.
      fireVirtualPageEventIfNecessary(
        PAGES_LIST.QUOTE_PAGE,
        getUid(this.props.location.search),
        getQueryParamValue(this.props.location.search, "transaction_id")
      );
      data.page_id = PAGES_LIST.QUOTE_PAGE.page_id;
      data.page_title = PAGES_LIST.QUOTE_PAGE.page_title;
      await this.props.getDetails(data, push);

      // if (this.props.history.action === "POP") {
    // }
    } catch (e) {
      saveException(e);
      console.log('Quotes-Error', e); 
    }
  }

 async componentDidUpdate(nextProps){
   try{
    if(this.props.quoteData !== nextProps.quoteData && this.props.quoteResponseLoaded){
      let data = Object.assign({ 'uid': getUid(this.props.location.search) })
      let { coverage_amount, plan_id } = this.props.quoteData
      Object.assign(data, { 'coverage_amount': coverage_amount, plan_id: plan_id })
      let {push}=this.props.history
      await this.props.sendQuotes(data,push)
      scrollUP()
    }
    if (nextProps.quoteApi !== this.props.quoteApi) {
      let { quoteApi } = this.props
      let quoteResponse = quoteApi.filter(ele =>
        ele.default === true
      )
      if (quoteResponse.length === 0) {
        quoteResponse[0] = quoteApi[0]
      }
      let {id, coverage_amount} = quoteResponse[0]
      this.setState({
        amount: parseInt(coverage_amount.default_value),
        tenure: getDefaultPlanId(id)
      },()=>{
        this.setState({
          isQuoteChanged: true
        })
      })
      // allows scroll only if user changes quote
      if(this.state.isQuoteChanged)
      {
      //make sure in mobile it scrolls to particular position wrt to ref
      const offset = isMobile() ? 400 : -100
      window.scrollTo({top: this.premiumRef.current.offsetTop + offset, behavior:'smooth'})
      }
    }
  } catch (e) {
    saveException(e);
    console.log('Quotes-Error', e); 
  }
 }
 
 componentWillReceiveProps(nextProps) {
   if (nextProps.isError && nextProps.isQuotePageError) {
     this.props.history.push(`/error${this.props.location.search}`);
   }
 }

  handleBackClick = () => {
    if (
        this.props.isCampaignMounted ||
        Boolean(localStorage.getItem(`isCampaignMounted${getUid(this.props.location.search)}`)) ||
        window.location.href.match('session_id')    // if the app is running on session id, then go to campaign page.
      ) {
        this.props.history.push(`/${this.props.location.search}`);
      } else {
        window.location.href=document.referrer
      }
  }

  handleBtnClick = () => {
    this.props.history.push(`/interstatial${this.props.location.search}`)
    window.formotiv.submit(getForMotivConfig("Your coverage estimate"));
  }

  getPremium = () => {
    let { premium } = this.getQuoteResponse();
    return premium;
  }

  getQuoteResponse = () => {
    let { quoteApi } = this.props

    let quoteResponse = quoteApi.filter(ele =>
      ele.default === true
    )
    if (quoteResponse.length === 0) {
      quoteResponse[0] = quoteApi[0]
    }

    return quoteResponse[0];
  }

  render() {
    let premium = this.getQuoteResponse();

    let leftText = (
      <div>
        <p className='global-left-panel-title' dangerouslySetInnerHTML={{ __html: 'Your coverage estimate' }} />
        <p
          dangerouslySetInnerHTML={{
            __html: '<span class=\'global-left-panel-content\'><p class=\'left-content-wrap\'>Congrats on taking the first step toward protecting your loved ones. Here’s a rough idea of what you can expect to pay for life insurance. Adjust the coverage amount and term length to see how it affects your estimated rate.</p></span>'
          }}
        />
        <b dangerouslySetInnerHTML={{ __html: 'Adjust coverage' }} />
        <p
          dangerouslySetInnerHTML={{
            __html: '<span class=\'global-left-panel-content\'><p class=\'left-content-wrap\'>Coverage is the total amount that you’d like your policy to pay in the event of your death. Adjust the coverage amount to balance your monthly budget with your coverage needs.</p></span>'
          }}
        />
        <b dangerouslySetInnerHTML={{ __html: 'Adjust term' }} />
        <p
          dangerouslySetInnerHTML={{
            __html: '<span class=\'global-left-panel-content\'><p class=\'left-content-wrap\'>A term is the length of time your premium will remain the same. Selecting a shorter term will often result in a lower premium, while longer terms cost more.</p></span>'
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: '<span class=\'global-left-panel-content\'><p class=\'left-content-wrap\'>* These numbers represent the minimum and maximum rates you could receive depending on the results of your application</p></span>'
          }}
        />
      </div>
    );

    // removing 'we are sorry' title from error message
    const sorryErrorMessage = this.props.errorMessage && this.props.errorMessage.replace("We\’re sorry. ",'')
    
    return (
      <LandingPage loading={this.props.inProgress} leftText={leftText}>
        <div className={`content-wrapper ${this.props.quoteDataLoading ? 'div-loading' : ''}`}>
          <div className={"quote-content-wrapper"}>
            <div>
              <div className={"normalize-layout-right"}>
              {this.props.isError ? 
                <ErrorPopup 
                  title={'We\'re Sorry '}
                  message={!this.props.isQuotePageError ? sorryErrorMessage : 'Based on the information you’ve provided, you do not meet our age guidelines for this product. Please call (888) 422-7020 for a personal consultation with a AAA Life Insurance Agent to explore other options.'}
                  height='150px'
                /> : null}
                <PremiumRange quoteResponse={premium} getRef={this.premiumRef }/>
                <p>Current AAA members save 10%. If you're a member, your savings are reflected in <span className='nowrap'> your estimate.
                <Tooltip value={`This estimated range is meant to give you an idea of what you'll pay for coverage if you're approved. You can adjust your coverage amount and term length, which will change your estimate. Once you fill out your application, we'll give you an exact rate for your policy.`} />
                </span>
                </p>
                <CoverageAmountSelector 
                  page="Q"
                  isLoading={this.props.quoteDataLoading? true : false}
                  altFormURI={"Your coverage estimate"}
                />

              </div>
            </div>

            <PlanSelector page="Q" altFormURI={"Your coverage estimate"}/>

          </div>
          <div className={"global-button-margin normalize-layout-right"}>
            <div className={"global-button-wrapper"}>
              <ButtonBlue
                label={"Back"}
                btnType='link'
                btnClickHandler={this.handleBackClick}
              />
              <ButtonBlue
                label={"Next"}
                btnType='pill'
                btnClickHandler={this.handleBtnClick}
                disabled={this.props.isError}
              />
            </div>
          </div>
        </div>
      </LandingPage>
    );
  }
}

let mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendQuotes,
    setBreadCrumbs,
    getDetails,
    setUid,
    setSessionId
  }, dispatch);
}
let mapStateToProps = (state) => {
  return {
    inProgress: (state.landingPage.loading && !state.landingPage.quoteResponseLoaded),
    quoteApi: state.quotePageReducers.quoteApi,
    tenureApi: state.quotePageReducers.tenureApi,
    quoteData: state.landingPage.quoteResponse,
    quoteResponseLoaded: state.landingPage.quoteResponseLoaded,
    isError: state.quotePageReducers.error || state.premiumReducer.premiumError,
    errorMessage: state.premiumReducer.errorMessage,
    isQuotePageError: state.quotePageReducers.error,
    quoteDataLoading: (state.quotePageReducers.loading && !state.quotePageReducers.quoteApiLoaded),
    isCampaignMounted: state.landingPage.isCampaignMounted
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Quote)
