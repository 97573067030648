import get from 'lodash/get';
import { axiosInstance } from './util/axios';

const exceptionRoutes = [
  '/',
  '/quote',
  '/interstatial',
  '/questions'
]

const initApp = async ({ location, history }) => {
  // const { pathname, search } = location;
  // if (exceptionRoutes.indexOf(pathname) < 0) {
  //   const uid = search.replace("?uid=", "")
  //   const fd = new FormData();
  //   fd.set('uid', uid);
  //   const response = await axiosInstance.post('/questions', fd)
  //   const currentStatus = get(response, 'data.response.other_params.current_status', '');
  //   history.push(`/${currentStatus}${search}`, {
  //     response: JSON.stringify(response)
  //   })
  // }
}

export default initApp;
