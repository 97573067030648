const coverageAmountOptions = (coverageAmount) => {
	const {
		min_value,
		max_value,
		step_length,
		default_value,
		currency_symbol
	} = coverageAmount;

	const coverageOptions = [];

	for (let index = parseInt(min_value, 10);
		index <= parseInt(max_value, 10);
		index = index + parseInt(step_length, 10)
	) {
		coverageOptions.push({
			value: index,
			label: `${currency_symbol}${index}`
		})
	}

	return {
		coverageOptions,
		defaultValue: parseInt(default_value, 10),
		currencySymbol: currency_symbol
	}
}

const { coverageOptions, defaultValue } = coverageAmountOptions({
	currency_symbol: "$",
	default_value: "25000",
	max_value: "500000",
	min_value: "25000",
	step_length: "25000",
});

export const MAX_LENGTH = {
	zipcode: 5,
	weight: 4,
	email: 128,
};

export const INPUT_TYPES = {
	INPUT: "INPUT",
	DATEPICKER: "DATEPICKER",
	DROPDOWN: "DROPDOWN",
	SSELECTION: "SINGLE_SELECTION",
	PLAN_SELECTOR: "PLAN_SELECTOR",
	COVERAGE_AMOUNT_SELECTOR: "COVERAGE_AMOUNT_SELECTOR",
}

export const FORM_FIELDS = [
	{
		maxLength: MAX_LENGTH.zipcode,
		placeholder: "Enter your zip",
		label: "ZIP code",
		width: "175px",
		type: "number",
		id: "zipcode",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.INPUT
	},
	{
		maxLength: null,
		placeholder: "",
		label: "Gender",
		width: "",
		type: "radio",
		id: "gender",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.INPUT
	},
	{
		maxLength: null,
		placeholder: "",
		label: "Date of birth",
		width: "",
		type: "",
		id: "dob",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.DATEPICKER,
		maxDate: new Date()
	},
	{
		maxLength: {
			ftHeight: 1,
			inHeight: 2
		},
		maxValue: {
			ftHeight: 8,
			inHeight: 11
		},
		placeholder: "0",
		label: "Height",
		width: "94px",
		type: "number",
		id: "height",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.INPUT
	},
	{
		maxLength: MAX_LENGTH.weight,
		placeholder: "Enter your weight",
		label: "Weight",
		width: "270px",
		type: "number",
		id: "weight",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.INPUT,
		subtitle: "lbs"
	},
	{
		maxLength: null,
		placeholder: "Select usage",
		label: "When was the last time you used any type of nicotine product?",
		width: "365px",
		type: "number",
		id: "nicotine_use",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.DROPDOWN,
		isSearchable: false,
		options: [
			{ value: 'Never', label: 'Never' },
			{ value: 'MoreThan60MonthsAgo', label: '60 Months Ago or More' },
			{ value: 'WithinPast36To59Months', label: '36 to 59 Months Ago ' },
			{ value: 'WithinPast12To35Months', label: '12 to 35 Months Ago' },
			{ value: 'LessThan12MonthsAgo', label: 'Less than 12 Months Ago' }
		],
		tooltip: true,
		tooltipValue: 'Nicotine products include any of the following: cigarettes, cigars, electronic cigarettes, chewing tobacco, nicotine gum, nicotine patches, pipes or hookah.',

	},
	{
		maxLength: null,
		placeholder: "",
		label: "Are you a AAA member?",
		width: "",
		type: "number",
		id: "is_member",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.SSELECTION,
		tooltip: true,
		tooltipValue: "You don't need to be a AAA member to apply for life insurance. However, members of AAA do receive some perks.",
		tooltipLink: "Learn more about joining AAA",
		href: "http://www.aaa.com/join",
	},
	{
		maxLength: MAX_LENGTH.email,
		placeholder: "Enter your email address",
		label: "Email",
		width: "365px",
		type: "text",
		id: "email",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.INPUT,
		tooltip: false,
		tooltipValue: "",
		tooltipLink: "",
		href: "",
	},
	{
		maxLength: null,
		placeholder: "",
		label: "Are you replacing an existing policy?",
		width: "",
		type: "",
		id: "replace_existing_insurance",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.SSELECTION,
		tooltip: false,
		tooltipValue: "",
		tooltipLink: "",
		href: "",
	},
	{
		maxLength: null,
		placeholder: "Select term length",
		label: "Term length",
		width: "365px",
		type: "",
		id: "plan_id",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.PLAN_SELECTOR,
		isSearchable: false,
		options: [],
		tooltip: false,
		tooltipValue: '',
	},
	{
		maxLength: null,
		placeholder: "Select coverage amount",
		label: "Coverage amount",
		width: "365px",
		type: "",
		id: "coverage_amount",
		defaultErrorMessage: "",
		inputType: INPUT_TYPES.COVERAGE_AMOUNT_SELECTOR,
		isSearchable: false,
		options: coverageOptions,
		tooltip: false,
		tooltipValue: '',
		defaultValue,
	}
];
