export default function log() {
  global.console.log = (function() {
    var orig = console.log;
    return function() {
      try {
        arguments[0] = "[" + new Date().toUTCString() + "] " + arguments[0];
        orig.apply(console, arguments);
      } catch (err) {
        orig.apply(console, arguments);
      }
    };
  })();

  global.console.error = (function () {
    var orig = console.error;
    return function() {
      if (process.env.REACT_APP_NODE_ENV === "local" ||
          process.env.REACT_APP_NODE_ENV === "development") {
        orig.apply(console, arguments);
      }
    }
  })();

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
};
