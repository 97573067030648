import React from "react";
import "./Checkbox.css";

export default ({
  id,
  checkboxName,
  rightTxt,
  handleCheckboxChange,
  checkboxValue,
  footerTextStyle,
  checked,
  className,
  borderPosition
}) => (
  <label className="containerCheckbox" style={borderPosition}>
    <p
      style={footerTextStyle}
      className={
        rightTxt.length < (window.screen.width > 500 ? 100 : 50)
          ? "pd-top-10"
          : "pd-top-0"
      }
    >
    <span dangerouslySetInnerHTML={{__html:rightTxt}}/>
    </p>
    <input
      id={id}
      name={checkboxName}
      className={className}
      type="checkbox"
      onChange={e => {handleCheckboxChange(checkboxValue, e)}}
      value={checkboxValue}
      checked={checked}
    />
    <span className="checkmarkCheckbox" />
  </label>
);
