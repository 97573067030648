import React from 'react';

export const HELPER = {
	feetHeightError(feet, inches) {
		return parseInt(feet) > 9 || parseInt(inches) > 11;
	},
	zipCodeError(zipcode, isZipError) {
		const zipCodeReg = /^[0-9]{5}$/;
		const isError = (zipCodeReg.test(zipcode) && !isZipError || zipcode === '') ? false : true;

		return isError;
	},
	emailError(email, mailError) {
		const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const isError = emailReg.test(email) && !mailError || email === '' || email === null ? false : true;

		return isError;
	},
	getLeftText() {
		return (
			<div className='line-dist'>
				<p
					className='global-left-panel-title'
					dangerouslySetInnerHTML={{ __html: '<div class=\'padding-bottom-10\'>Affordable coverage.</div> <div> Convenient online application.</div>' }} 
				/>
				<p
					dangerouslySetInnerHTML={{
						__html: '<span class=\'global-left-panel-content\'><p class=\'left-subtitle\'>Fill out the fields to get your personal coverage estimate. </br > Then, submit the short, online application and receive an official decision and rate in minutes.</p>'
					}}
				/>
			</div>
		)
	},
}
