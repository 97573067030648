import React from "react";
import "./ReviewLabel.css";
import { ErrorTypes } from "../../../util";

export default class ReviewLabel extends React.Component {
  componentDidMount() {
    const {
      questionObj: { question_id, response }
    } = this.props;

    if (response)
      this.props.handleLabelChange(question_id, response, ErrorTypes.noError);
  }

  render() {
    const {
      questionObj: { question_id, question_text, response, validations }
    } = this.props;
    // let validations = { disp_line: { value: "dashed", position: "bottom" } };
    let line_display = validations &&
      validations.disp_line && (
        <div
          className={
            validations.disp_line.value === "solid"
              ? "review-solid-line"
              : "review-dotted-line-break"
          }
        />
      );
    return (
      <div key={question_id}>
        {validations &&
          validations.disp_line &&
          validations.disp_line.position === "top" &&
          line_display}
        <div className="display_review_flex_center">
          <div
            className="display_review_question"
            htmlFor={question_id}
            dangerouslySetInnerHTML={{ __html: question_text }}
          />
          <div className="display_review_answer ">{response}</div>
        </div>
        {validations &&
          validations.disp_line &&
          validations.disp_line.position === "bottom" &&
          line_display}
      </div>
    );
  }
}
