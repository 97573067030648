
import {
	CLEAR_ERROR,
	GET_DETAILS_START,
	GET_DETAILS_SUCCESS,
	CREATE_QUOTE_START,
	CREATE_QUOTE_SUCCESS,
	SET_UID,
	SET_SESSION_ID,
	IS_CAMPAIGN_MOUNTED
} from '../types';
let INITIAL_STATE = {
	loading: true,
	createQuoteLoading: false,
	uid: '',
	sessionId: '',
	quoteResponse: {},
	quoteResponseLoaded: false,
	zipError: false,
	zipcode: '',
	zipErrorInfo: '',
	DOB: '',
	DOBError: false,
	DOBErrorInfo: '',
	backendError: '',
	feildError: {},
	isCampaignMounted:false
}

export default function landingPageReducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_DETAILS_START:
			return {
				...state,
				loading: true,
				quoteResponseLoaded: false,
			}
		
		case CREATE_QUOTE_START:
			return {
				...state,
				createQuoteLoading: true,
			}

		case CREATE_QUOTE_SUCCESS:
			if (payload.data.errors) {
				return {
					...state,
					createQuoteLoading: false,
					uid: payload.data.response.uid,
					backendError: payload.data.errors.userMessage,
					feildError: payload.data.errors.field_errors ? payload.data.errors.field_errors : {}
				}
			} else {
				return {
					...state,
					createQuoteLoading: false,
					uid: payload.data.response.uid,
					backendError: '',
					feildError: {}
				}
			};
		case SET_UID:
			return {
				...state,
				uid: payload
			};
		case SET_SESSION_ID:
			return {
				...state,
				sessionId: payload
			};
		case GET_DETAILS_SUCCESS: return {
			...state, quoteResponse: payload, loading: false, quoteResponseLoaded: true,
		}
		case CLEAR_ERROR:
			return {
				...state,
				zipError: false,
				zipErrorInfo: '',
				zipcode: '',
			};
		case IS_CAMPAIGN_MOUNTED:
			return {
				...state, isCampaignMounted : payload
			}	
		default:
			return state;
	}
}
