import React, { Component } from "react";
import Autosuggest from 'react-autosuggest';
import {ErrorTypes, getErrMsg, getUid} from "../../../util";
import './autosuggest.css';
import * as utils from '../../../util/index';
import { axiosInstance } from "../../../util/axios";
import debounce from "debounce-promise";
import {Tooltip} from "..";

const getSuggestions = (value,response) => {
  var inputValue;
  var inputLength;
  if (value) {
    inputValue = value.trim().toLowerCase();
    inputLength = inputValue.length;
  }
  return (inputLength  === 0 || response === undefined) ? [] : response
};

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => ( 
  <div id={suggestion.id} className={suggestion.id==='loading'?'no-pointer':'txt-transform '}>
    {suggestion.value}
  </div>    
);

export default class Autosuggestion extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: '',
        suggestions: [],
        response:[]
      };
    }

    getErrorMessage = errorType => {
      return getErrMsg(this.props.questionObj, errorType);
    };
  
    autosuggestion = async (data) => {
      const formData = new FormData()        
        utils.prepareFormData(formData, data);
        this.setState({
          suggestions: [{id:"loading", value:"loading..."}] 
        });
        await axiosInstance.post(`/Search`, formData).then(res => {
          const response = utils.converToArrayIfRequired(res.data.response);
          this.setState({
            response: response,
            suggestions: getSuggestions(this.state.value, response)
          })
        })
        .catch(err=>{
          utils.saveException({apiName:'/Search', message: err})
        })       
      }
           
    autosuggestionDebounce = debounce(this.autosuggestion, 500)
  suggestionSelect = (event, {suggestion}) => {
    if(suggestion.id!=='loading'){
    const {
      questionObj: {
        assessment_category
      }
    } = this.props;
    let answer={
      "id":suggestion.id,
      "label":suggestion.value,
      "inferred":"false",
      "addedeBySearchVariable":true,
      "category":assessment_category ? assessment_category : null
    }
    this.props.handleAutoSuggestionOnChange([answer],ErrorTypes.noError,this.props.questionObj.question_id)
    this.setState({
      value: suggestion.value
    })
   }
   else
   this.setState({
     value:this.state.value
  })
  }
    onChange = (event, { newValue }) => {
      if(newValue!==undefined){
        let data = Object.assign({ 'uid': getUid(this.props.location.search),"key":newValue,"question_id":this.props.questionObj.question_id})

      this.autosuggestionDebounce(data);
      this.setState({value:newValue});
      newValue ? this.props.handleAutoSuggestionOnChange([],ErrorTypes.mismatch,this.props.questionObj.question_id) :  this.props.handleAutoSuggestionOnChange([],ErrorTypes.required,this.props.questionObj.question_id)
      }
      
    };
 
    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: getSuggestions(value,this.state.response)
      });
    };

    onSuggestionsClearRequested = () => {
      return null
    };
  
  componentDidMount = () => {
    if(this.props.questionObj.response){
      this.setState({
        value: this.props.questionObj.response[0].label,
        suggestions: this.props.questionObj.question_status==="invalid" ?[]:[1] //putting dummy value until the response resolves
      })
      let data = Object.assign({ 'uid': getUid(this.props.location.search),"key":this.props.questionObj.response[0].label,"question_id":this.props.questionObj.question_id})
      this.autosuggestion(data);
      this.props.handleAutoSuggestionOnChange(this.props.questionObj.response,ErrorTypes.noError,this.props.questionObj.question_id)
    } else
    if(this.props.questionObj.validations  && this.props.questionObj.validations.required && this.props.questionObj.validations.required === false){
      this.props.handleAutoSuggestionOnChange([],ErrorTypes.noError,this.props.questionObj.question_id)
    }
  }

  onKeyDown=(e)=>{
    if(e.keyCode===13){
      e.preventDefault()
      e.stopPropagation();
    }
  }
    
    render() {
      const { value, suggestions } = this.state;
      const {
        questionObj: {
          question_type,
          question_text,
          question_id,
          tooltip
        },
        requiredError,
        nextButtonOnClick
      } = this.props;
      let toolTipValue = <div dangerouslySetInnerHTML={{__html:tooltip}}/> 
      const inputProps = {
        placeholder: 'Enter here',
        value: value ? value : this.state.value,
        onChange: this.onChange,
        onKeyDown: this.onKeyDown,
        id:{question_id},
        type:{question_type} ,
        style:{
        textTransform: 'capitalize',
        }      
      };
      const errorInput = {
        value,
        onChange: this.onChange,
        id:{question_id},
        type:{question_type},
        style:{
          border: '1px solid red'
        }
      }
      return (
        <div>
          <label htmlFor={question_id} className="global-label-text">
          {question_text}{" "}{tooltip ? <Tooltip value={toolTipValue} id={question_id}/> : null}
          </label>
          <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={ suggestions.length!==0 || value === ''? inputProps : errorInput }
          onSuggestionSelected={this.suggestionSelect}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          />
             {requiredError && nextButtonOnClick &&
          (requiredError !== ErrorTypes.noError) && (
            <p
              style={{
                fontSize: 12,
                color: "red",
                marginTop: 0,
                marginBottom: 15
              }}
            >
              {this.getErrorMessage(requiredError)}
            </p>
          )}            
        </div>
      );
    }
  }
