import React, { Component } from "react";
import { getUid, getParams } from "../../util";
import './uidProtected.css';

export default class UidProtected extends Component {
    constructor(props){
        super(props)
        this.state = {
            isValidUid: false
        };
    }

    componentDidMount() {
        const sessionId = getParams("session_id");
        if (process.env.NODE_ENV === "local" || process.env.NODE_ENV === "development") {
            this.setState({
                isValidUid: true
            });
            return;
        }
        if (sessionId) {
            this.setState({
                isValidUid: true
            });
        } else {
            const validUid = localStorage.getItem('generatedUid');
            let uid = getUid(this.props.location.search);

            if (uid === validUid) {
                this.setState({
                    isValidUid: true
                });            
            }
        }
    }

    render() {
        if (this.state.isValidUid) {
            return (
                <div>
                    {this.props.children}
                </div>
            )
        } else {
            return (
                <div className="err-msg">
                <p>
                    You are not an authorised user!!
                </p>
                    <a href="/">Home Page</a> 
                </div>
            )
        }
    }
}