import React from "react";
import {
  Input,
  Datepicker,
  RadioButton,
  SingleSelectionButton,
  MultiSelectDropdown,
  Dropdown,
  ReviewLabel,
  Autosuggestion
} from "../Components/UIElements";

export const GetElementFunc = (
  questionObj,
  index,
  requiredSchemaTrue,
  {
    handleLabelChange,
    handleInputChange,
    handleDateChange,
    handleRadioChange,
    handleSingleDropDown,
    handleSingleSelectBtnClick,
    handleMultiSelectChange,
    handleAutoSuggestionOnChange,
    handleFormDisable
  },
  questionsArr,
  requiredError,
  nextButtonOnClick,
  location,
  showReflexiveLoaderFor,
  disableClass,
  formotivURI
) => {
  const presentation_type = questionObj.presentation_type
    ? questionObj.presentation_type
    : "questions";
  if (presentation_type === "review") {
    return (
      <ReviewLabel
        questionObj={questionObj}
        key={index}
        handleLabelChange={handleLabelChange}
      />
    );
  } else {
    switch (questionObj.question_type) {
         
      case "text":
        return (
          <Input
            questionObj={questionObj}
            key={index}
            handleInputChange={handleInputChange}
            required={requiredSchemaTrue.includes(questionObj.question_id)}
            response={questionObj.response}
            disableClass={disableClass}
            requiredError={requiredError}
            nextButtonOnClick={nextButtonOnClick}
          />
        );
      case "number":
        return (
          <Input
            type={"number"}
            questionObj={questionObj}
            key={index}
            handleInputChange={handleInputChange}
            required={requiredSchemaTrue.includes(questionObj.question_id)}
            response={questionObj.response}
            disableClass={disableClass}
            requiredError={requiredError}
            nextButtonOnClick={nextButtonOnClick}
          />
        );
      case "date":
        return (
          <Datepicker
            questionObj={questionObj}
            key={index}
            handleChange={handleDateChange}
            required={requiredSchemaTrue.includes(questionObj.question_id)}
            requiredError={requiredError}
            nextButtonOnClick={nextButtonOnClick}
          />
        );
      case "singleselection": {
        if (questionObj.display_type && questionObj.display_type === "radio") {
          return (
            <div key={index}>
              <RadioButton
                questionObj={questionObj}
                key={index}
                handleRadioChange={handleRadioChange}
                required={requiredSchemaTrue.includes(questionObj.question_id)}
                requiredError={requiredError}
                nextButtonOnClick={nextButtonOnClick}
              />
            </div>
          );
        } else if (
          questionObj.display_type &&
          questionObj.display_type === "radio_button"
        ) {
          return (
            <div key={index}>
              <SingleSelectionButton
                questionObj={questionObj}
                key={index}
                required={requiredSchemaTrue.includes(questionObj.question_id)}
                handleSingleSelectBtnClick={handleSingleSelectBtnClick}
                showReflexiveLoaderFor={showReflexiveLoaderFor}
                disableClass={disableClass}
                requiredError={requiredError}
                nextButtonOnClick={nextButtonOnClick}
                formotivURI={formotivURI}
              />
            </div>
          );
        } else {
          return (
            <Dropdown
              key={index}
              questionObj={questionObj}
              handleSingleDropDown={handleSingleDropDown}
              required={requiredSchemaTrue.includes(questionObj.question_id)}
              showReflexiveLoaderFor={showReflexiveLoaderFor}
              disableClass={disableClass}
              requiredError={requiredError}
              nextButtonOnClick={nextButtonOnClick}
              formotivURI={formotivURI}
            />
          );
        }
      }
      case "multiselection":
        return (
          <MultiSelectDropdown
            questionObj={questionObj}
            key={index}
            required={requiredSchemaTrue.includes(questionObj.question_id)}
            handleMultiSelectChange={handleMultiSelectChange}
            handleFormDisable={handleFormDisable}
            showReflexiveLoaderFor={showReflexiveLoaderFor}
            requiredError={requiredError}
            nextButtonOnClick={nextButtonOnClick}
            formotivURI={formotivURI}
          />
        );
      case "label":
        return (
          <div key={index}>
            <div
              className="label-text"
              htmlFor={questionObj.question_id}
              dangerouslySetInnerHTML={{ __html: questionObj.question_text }}
            />
          </div>
        );
        case "autocomplete-text":
        return (
          <Autosuggestion
            questionObj={questionObj}
            key={index}
            handleAutoSuggestionOnChange={handleAutoSuggestionOnChange}
            required={requiredSchemaTrue.includes(questionObj.question_id)}
            location={location}
            requiredError={requiredError}
            nextButtonOnClick={nextButtonOnClick}
          />
        );
      default:
        return "Question type not supported";
    }
  }
};
