import React from "react";
import debounce from "debounce-promise";
import "./Input.css";
import {ErrorTypes, getParams, getLengthFromRegex, isMobile, getErrMsg} from "../../../util";
import {Tooltip} from "..";
import emojiRegex from 'emoji-regex';
import get from "lodash/get";
import { formattedData, getActualValue, hasMaxLength, isNumberFieldQuestion, mod10CheckDigit } from "./input-util";

export default class Input extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      error: "",
      isAllowAction:''
    };
    this.hasMask = false;
    this.clearMode = false;
  }

  getErrorMessage = errorType => {
    return getErrMsg(this.props.questionObj, errorType);
  };

  getErrorType = (
    value,
    { moduloValue, min_length, max_length, pattern, required, min_value, max_value }
  ) => {
    var validInputPattern = /^[^a-zA-Z0-9]+$/;
    if(value && moduloValue === 10 && !mod10CheckDigit(value)){
      return ErrorTypes.modulo;
    }
    else if (pattern && !pattern.test(value) && value && value.toString().length > 0) {
      return ErrorTypes.mismatch;
    } else if (value && max_length && value > max_length) {
      return "MAX_LENGTH";
    } else if (value && min_length && value < min_length && value.toString().length > 0) {
      return ErrorTypes.minLength;
    } else if (required === true && (!value || value.toString().length === 0)) {
      return ErrorTypes.required;
    } else if (value && max_value && parseInt(value) > max_value) {
      return ErrorTypes.maxValue;
    } else if (value && min_value && parseInt(value) < min_value) {
      return ErrorTypes.minValue;
    } else if (value == 0) {
      if(min_value !== 0 && (required === true || value.toString().length > 0)) return ErrorTypes.allZeros;
      else return ErrorTypes.noError;
    } else if (value && validInputPattern.test(value)) {
      return ErrorTypes.mismatch;
    } else { 
      return ErrorTypes.noError;
    }
  };

  componentDidMount() {
    const {
      questionObj: { question_id, question_status, question_status_message }
    } = this.props;

    this.mask = get(this, "props.questionObj.validations.mask", false);

    if (this.props.response || this.props.response===0) {
      this.clearMode = true;
      this.hasMask = get(this, 'props.questionObj.validations.mask.mask_char', null);
      const inputValue = formattedData(this.props.questionObj, this.props.response, null, this.hasMask, true);
      this.setState({
        inputValue: inputValue.toString(),
        error:
          question_status && question_status.toLowerCase() === "invalid"
            ? question_status_message
            : ErrorTypes.noError
      });
      this.props.handleInputChange(
        this.props.response,
        ErrorTypes.noError,
        question_id
      );
    }
  }

  getErrorTypeDebounce = debounce(this.getErrorType, 400);

  /**
   * @description finds out if the user input should be cleared.
   * Following four condistions should satisfy to clear the input field:
   * 0. The component is in clear mode.
   * 1. The question field requires masking.
   * 2. There is a valid response, indicates the user is in update mode.
   * 3. If the length of response is excatly 1 more the value of the user input field,
   *    indicates one character has been deleted.
   * Update shouldClear to false, because the subsequent inputs given by the user should
   * not clear the field.
   * @param {String} value user input
   * @returns {Boolean}
   */
  shouldClearInput(value){
    if (this.clearMode && this.mask && this.props.response && this.props.response.length - value.length >= 1) {
      this.clearMode = false;
      return true;
    }
    return false;
  }

  /**
   * @description if the masked input is present, do not allow user to input anything else
   * unless user has not cleared the field. Or when the validations contains max_value or max_length
   * values, prevent user from entering anything else.
   * @param {String} value input value
   * @param {String} question_id
   * @param {validations} validations validation object
   */
  shouldPreventInput (value, question_id, validations) {
    const maxLength = get(validations, 'max_length.value');
    const maxValue = get(validations, 'max_value.value');
    if (this.clearMode && this.mask && this.props.response && (this.props.response.length <= value.length)) {
      return true;
    }
    else if ((maxLength && value.toString().length > maxLength) || (maxValue && getActualValue(question_id, value) > maxValue)) {
      return true;
    }
    return false;
  }

  preventMobileInput = (regexPattern, value) => {
    if (regexPattern.test(value)) return true;
    return false;
  }

  handleInputOnChange = async ({ target: { value } }) => {
    // check for emoji
    const regex = emojiRegex();
		if (regex.exec(value)) {
			return;
		}
    const {
      questionObj: { validations, question_id },
      type
    } = this.props;
    const moduloValue = get(validations, "modulo.value", 0);
    const regexValue = get(validations,"pattern.value", '');
    let formated_value = value;
    const numberRegex = new RegExp(/^[0-9.,]*$/);
    const zerosRegex = /^00[0-9]*/;
    const isAutoFormatted = get(validations, 'auto_format.value');
    if(type === 'number' && !isAutoFormatted && zerosRegex.test(value)) {
      return parseFloat(value);
    }

    if((type==='number' || isNumberFieldQuestion(question_id)) && !numberRegex.test(getActualValue(question_id, formated_value)) ){
      return
    }

    const maxLength = hasMaxLength(question_id);
    if(formated_value && maxLength) {
      if(getActualValue(question_id, formated_value).length > maxLength) return;
    }

    if (isMobile()) {
      if(( type === 'number' || isNumberFieldQuestion(question_id) ) && !isAutoFormatted) {
        const numberRegex = new RegExp('^\\d+$');
        if (value && !numberRegex.test(value)) return;
      } else if (isAutoFormatted) {
        const regexPattern = /[a-zA-Z!@$.'`~%^#&_+=\[\]{};:"\\|<>\/?]/g
        if(this.preventMobileInput(regexPattern, value)) return;
      }
    }
   
    if (this.shouldPreventInput(value, question_id, validations)) {
      return;
    }

    if (this.shouldClearInput(value)) {
      formated_value = "";
    } else {
      if (
        validations &&
        validations.mask &&
        this.state &&
        this.state.inputValue &&
        this.state.inputValue.toUpperCase().indexOf(validations.mask.mask_char) >=
          0
      ) {
        this.clearMode = false;     // when the input field is cleared, set the clearMode to false
        formated_value = "";
      }

      formated_value = formattedData(this.props.questionObj, value);
    }

    const patternValue = get(validations, "pattern.value", "");
    let regexPattern = null;
    if (patternValue) {
      const updatedPatternValue = this.completeRegex(patternValue);
      regexPattern = new RegExp(
          updatedPatternValue
          .split("")
          .slice(0, updatedPatternValue.length)
          .join("")
      )
    }

    // Removes leading spaces
    if(value) {
      formated_value = formated_value.trimStart();
    }

    const sessionId = getParams("session_id")
    const length = getLengthFromRegex(regexValue)
    if(sessionId && value.length>length){
      if(this.state.isAllowAction)
          this.setState({ inputValue: value });
      else
        return    
    }
    else{
      this.setState({ inputValue: formated_value });
    }

    const outPut = this.getErrorType(getActualValue(this.props.questionObj.question_id, formated_value), {
      moduloValue,
      min_length: validations.min_length ? validations.min_length.value : 0,
      max_length: validations.max_length
        ? validations.max_length.value
        : false,
      pattern: regexPattern,
      required: validations.required ? validations.required.value : false,
      min_value: validations.min_value
        ? parseInt(validations.min_value.value)
        : false,
      max_value: validations.max_value
        ? parseInt(validations.max_value.value)
        : false
    });
    this.setState({ error: outPut });

    // Send the data back to parent with error message and question_id
    this.props.handleInputChange(
      getActualValue(this.props.questionObj.question_id, formated_value),
      outPut,
      this.props.questionObj.question_id
    );
  };

  handlePaste = e => {
    let value = '';
    if (window.clipboardData && window.clipboardData.getData) { // IE
      value = window.clipboardData.getData('Text');
    } else if (e.clipboardData && e.clipboardData.getData) {
      value = e.clipboardData.getData('text/plain');
    }
    const {
      questionObj: { validations, question_id }
    } = this.props;
    const patternValue = get(validations, "pattern.value", "");
    let regexPattern = null;
    if (patternValue) {
      const updatedPatternValue = this.completeRegex(patternValue);
      regexPattern = new RegExp(
          updatedPatternValue
          .split("")
          .slice(0, updatedPatternValue.length)
          .join("")
      )
    }
    if(value && regexPattern) {
      const autoFormat = get(validations, 'auto_format.value');
      const formattedValue = autoFormat && formattedData(this.props.questionObj, value, null, null, true);
      const actualValue = getActualValue(question_id, value);
      if(value !== formattedValue && value !== actualValue) {
        e.preventDefault(); 
      }
    }
    const { inputValue } = this.state;
    if(inputValue && value) value = inputValue.concat(value);
    if (this.shouldPreventInput(value, question_id, validations)) {
      e.preventDefault();
    }
  }

  handleInputOnBlur = async ({ target: { value } }) => {
    const {
      questionObj: { validations, question_id }
    } = this.props;
    const { error } = this.state;
    if(error === ErrorTypes.noError && (!this.clearMode || !this.hasMask)) {
      let formated_value = value;
      if (
        validations &&
        validations.auto_format &&
        validations.auto_format.value &&
        validations.auto_format.value.length > 0
      ) {
        formated_value = validations.auto_format
          ? formattedData(this.props.questionObj, value, null, null, true)
          : value;
      }

      this.setState({ inputValue: formated_value });

      this.props.handleInputChange(
        getActualValue(this.props.questionObj.question_id, formated_value),
        this.state.error,
        question_id
      );
    }
  }

  getborderStyle = () => {
    const { error } = this.state;
    const { requiredError, nextButtonOnClick } = this.props;
    if ((requiredError && requiredError !== ErrorTypes.noError && nextButtonOnClick) || (error && error !== ErrorTypes.noError)) { return {
        borderColor: "red"
      }} else return null;
    };

  /**
   * @description utility function to update regex pattern value.
   * 1. If the pattern value does not contain $ at the end, add it.
   * 2. If the pattern value does not contain ^ at the start, add it.
   * @param {String} str existing pattern value received in API response
   */
  completeRegex(existingPattern) {
    let patternValue = existingPattern;
    if (existingPattern.slice(-1) !== "$") {
      patternValue = `${existingPattern}$`;
    }
    if (existingPattern.slice(0, 1) !== "^") {
      patternValue = `^${patternValue}`;
    }
    return patternValue;
  }

  handleKeyDown = event => { 
    const {
      questionObj,
      type
    } = this.props;
    const question_id = get(questionObj,"question_id",'')
    if((type==='number' || isNumberFieldQuestion(question_id))) {
      const num = parseInt(event.key);
      let key = event.keyCode || event.charCode;
      // if key is ctrl/cmd key, allow propagation
      if(event.ctrlKey || event.metaKey) {}
      // if not a number, prevent user input.
			else if (isNaN(num) && !(key === 8 || key === 9 || key === 46 || key === 37 || key === 39)) {
				event.preventDefault();
				event.stopPropagation();
      }
      (event.key ==='e'||event.key ==='E'||event.key ==='+'||event.key==='-'||event.key==='ArrowDown' ||event.key==='ArrowUp' ) && event.preventDefault();
		}
    const maskChar = get(this, "props.questionObj.validations.mask.mask_char", "");
    if (this.mask && event.key === maskChar) {
      event.preventDefault();
      event.stopPropagation();
    }
    const sessionId = getParams("session_id")
    if(event.ctrlKey && event.key==='v' && sessionId){
      this.setState({
        isAllowAction: true
      })
    }
    else if(event.key==='Backspace' && sessionId){
      this.setState({
        isAllowAction: true
      })
    }
    else{
      this.setState({
        isAllowAction: false
      })
    }
  };

  handleKeyPress = event => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();
      this.handleInputOnChange(event);
    }
  };

  render() {
    const {
      questionObj: {
        question_text,
        question_id,
        validations,
        question_status_message,
        tooltip,
        is_readonly
      },
      disableClass,
      requiredError,
      nextButtonOnClick
    } = this.props;
    const { error } = this.state;
    let toolTipValue = <div dangerouslySetInnerHTML={{__html:tooltip}}/>  
    return (
      <div className={`custom-text-input`}>
        <label htmlFor={question_id} className="global-label-text">
          {question_text}{" "}{tooltip ? <Tooltip id={question_id} value={toolTipValue} /> : null}  
          {/* {validations &&
            validations.required &&
            validations.required.value &&
            validations.required.value === true && (
              <span className="label_icon_star">*</span>
          )} */}
        </label>

        <input tabIndex={disableClass ? "-1" : "0"}
          id={question_id}
          type='text'
          placeholder={
            validations && validations.placeholder_text
              ? validations.placeholder_text.value
              : ""
          }
          minLength={
            validations && validations.min_length
              ? validations.min_length.value
              : 0
          }
          maxLength={
            validations && validations.max_length
              ? validations.max_length.value
              : null
          }
          required={
            validations && validations.required
              ? validations.required.value
              : false
          }
          onChange={this.handleInputOnChange}
          onPaste={event => this.handlePaste(event)}
          onBlur={this.handleInputOnBlur}
          value={this.state.inputValue||''}
          style={this.getborderStyle()}
          onKeyPress={event => this.handleKeyPress(event)}
          onKeyDown={event => this.handleKeyDown(event)}
          disabled={is_readonly}
        />
            
        {(error && error !== ErrorTypes.noError && (question_status_message || this.getErrorMessage(error))) ? (
          <p className='show-error-msg'>
          {question_status_message ? question_status_message : this.getErrorMessage(error)}
        </p>
        ) : 
        (requiredError !== ErrorTypes.noError && this.getErrorMessage(requiredError)) && nextButtonOnClick && (
              <p className='show-error-msg'>
              {this.getErrorMessage(requiredError)}
            </p>
          )}
        {error &&
          error === ErrorTypes.mismatch &&
          validations &&
          validations.sample_value &&
          validations.sample_value.value &&
          validations.sample_value.value.length > 0 && (
            <p className="error_type_mismatch">
              {`eg: ${validations.sample_value.value}`}
            </p>
          )}
      </div>
    );
  }
}
