import React from "react";
import "./Loader.css";

export default props => (
  <div className='loader-block'>
  <div className="lds-ring">
    <div />
    <div />
    <div />
    <div />
  </div>
  {props.text ? <p>
  {props.text}
  </p> : null}
  </div>
);
