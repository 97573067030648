import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import Reducers from './redux/reducers/index.js'
import logger from 'redux-logger';


const getMiddlewares = () => {
    let middlewares = [thunk];
    if (process.env.NODE_ENV === "development") {
      middlewares = [...middlewares, logger]
    }
    return middlewares;
  }

export default createStore(Reducers, {}, applyMiddleware(...getMiddlewares()));  