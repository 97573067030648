import React, { Component } from "react";
import "./landingPage.css";
import constants from '../../util/constants'
import { connect } from 'react-redux';
import { Loader } from "../../Components/UIElements";
class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state={
      activeBread:''
    }
  } 
  componentDidUpdate(prevprops){
    if(prevprops.breadcrumbs!==this.props.breadcrumbs){
      let {breadcrumbs}=this.props
      let value = breadcrumbs.filter((ele,index)=>{
        return ele.state==='active'
      })
      if(value.length)      
      this.setState({
        activeBread:value[0].title
      })
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.props.loading ? (
          <div className={"loading-canvas-front"}>
            <span>
              <Loader text={this.props.text} />
            </span>
          </div>
        ) : (
        <div className="canvas-for-content" style={wrapperInLineStyle}>
          <div className="left-canvas-content-wrapper">
            <div className="left-canvas-content normalize-layout-left">
              {/* <p className='mobile-breadcrumb-title'>{this.state.activeBread}</p> */}
              {this.props.leftText}
            </div>
          </div>
          <div className="right-canvas-content-wrapper">
            <div className="right-canvas-content">
              {this.props.leftText ? this.props.children : ""}
            </div>
          </div>
        </div>
        )}
      </React.Fragment>
    );
  }
}

const wrapperInLineStyle = {
  minHeight: constants.screenMinHeightForContent
};

const mapStateToProps = state => {
  return {
    breadcrumbs: state.utilReducer.breadcrumbs,
  }
}
export default connect(mapStateToProps, null)(LandingPage);