import { QUOTE_API, GET_QUOTE, ERROR, GET_QUOTE_DATA_START, GET_QUOTE_DATA_SUCCESS } from '../types';

let INITIAL_STATE = {
	quoteApi: [{
		"id": "YourLife-0",
		"title": "",
		"premium": {
			"title": "",
			"currency_symbol": "$",
			"premium": '0',
			"premium_frequency_label": "",
			"premium_frequency": ""
		},
		"coverage_amount": {
			"title": "",
			"currency_symbol": "$",
			"min_value": "0",
			"max_value": "0",
			"step_length": "0",
			"default_value": "0"
		},
		"default": true
	}],
	tenureApi: {
		"title": "",
		"min_year": "0",
		"max_year": "0",
		"year_increment": "0",
		"default_value": "0"
	},
	loading: false,
	quoteApiLoaded: true,
	quotes: {},
	error: false,
}

export default function quotePageReducers(state = INITIAL_STATE, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_QUOTE_DATA_START:
			return {
				...state,
				loading: true,
				quoteApiLoaded: false,
			}
		case GET_QUOTE_DATA_SUCCESS:
			return {
				...state,
				...payload,
				quoteApiLoaded: true,
			};
		case ERROR:
			return {
				...state, error: true
			}
		default:
			return { ...state };
	}
}