const originConfig = {
	local: {
		"API_BASE_URL": `http://${window.location.host}/api`,
		"WS_BASE_URL": `http://${window.location.host}`
	},
	development: {
		"API_BASE_URL": `https://${window.location.host}/api`,
		"WS_BASE_URL": `https://${window.location.host}`
	},
	stg: {
		"API_BASE_URL": `https://${window.location.host}/api`,
		"WS_BASE_URL": `https://${window.location.host}`
	},
	uat: {
		"API_BASE_URL": `https://${window.location.host}/api`,
		"WS_BASE_URL": `https://${window.location.host}`
	},
	production: {
		"API_BASE_URL": `https://${window.location.host}/api`,
		"WS_BASE_URL": `https://${window.location.host}`
	},
	preprod: {
		"API_BASE_URL": `https://${window.location.host}/api`,
		"WS_BASE_URL": `https://${window.location.host}`
	}
}

export default originConfig;
