import React from "react";
import "./medical.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setBreadCrumbs } from "../../redux/actions";
import { getUid, prepareFormData, scrollUP, getResponseFromLocation, 
    parseResponse, fireVirtualPageEventIfNecessary, getQueryParamValue, redirectIfNeeded, getForMotivConfig } from '../../util/index'
import  LandingPage  from "../LandingPage/landingPage";
import { axiosInstance } from "../../util/axios";
import { PAGES_LIST } from '../../util/pages';
import { ButtonBlue } from "../../Components/UIElements";
import { withLastLocation } from 'react-router-last-location';
import { get } from "lodash";

class MedicalScreen extends React.Component {

  constructor(props){
    super(props);
    window.formotiv.init(getForMotivConfig("Medical Screening"));
  }

  state = {
    message: "",
    loading:true,
    leftTextHeading: "",
    leftTextDesc: "",
    leftTextTitle: "",
    selected:false,
    otherParams: null,
    userChoice: {}

  };

  // call an api
    async componentDidMount() {
      this.props.setBreadCrumbs([])   
      window.inputChange=e=> {
        const val = e.target.type === 'checkbox' ? e.target.checked : e.target.value
        const choice = this.state.userChoice
        choice[e.target.name] = val
        this.setState({
          userChoice: choice,
          selected: true
        })
        if(e.target.value==='screening-yes')
        {
          document.getElementById('yes-option').classList.remove('hidden')
          document.getElementById('no-option').classList.add('hidden')
          choice['yes-offers'] = false
          this.setState({
            userChoice: choice
          })
        }
        else if(e.target.value==='screening-no'){
          document.getElementById('yes-offers').checked = false
          document.getElementById('no-option').classList.remove('hidden')
          document.getElementById('yes-option').classList.add('hidden')
        }
      }
      scrollUP();
      this.props.getPageStatus('loading')
      let res
      if(this.isBackToHealthPage()){
       res =  await this.axiosCallHelper('/questions')
      }
      else
      res = await getResponseFromLocation(this.props);
      const didRedirect = redirectIfNeeded(this.props, res, {});
      if (!didRedirect) {
        const { data, page_desc, other_params } = parseResponse(res);
        const { title, desc, heading } = page_desc;
        const { message } = data;
        let { virtual_page_view } = other_params;

        if (virtual_page_view) {
          this.props.getPageStatus(virtual_page_view)
          virtual_page_view = virtual_page_view.toUpperCase();
          fireVirtualPageEventIfNecessary(
            PAGES_LIST[virtual_page_view],
            getUid(this.props.location.search),
            getQueryParamValue(this.props.location.search, "transaction_id")
          );
        }

        this.setState({
            leftTextTitle: title,
            leftTextDesc: desc,
            leftTextHeading: heading,
            message,
            loading: false,
            otherParams: other_params
        });
      }
      scrollUP();
  }

  renderLeftTextHelper = (leftTextHeading, leftTextTitle, leftTextDesc) => {
    return (
        <div>
            {
              leftTextHeading && <h5 className="left-text-heading">{leftTextHeading}</h5>
            }
            {/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
            <p className='global-left-panel-title' dangerouslySetInnerHTML={{ __html: leftTextTitle }} />
            <p 
                dangerouslySetInnerHTML={{ __html: leftTextDesc }}
            />
        </div>
    );
};

axiosCallHelper = async (uriPath, data) => {
    const fd = new FormData();
    const globalParams=Object.assign({ 'uid': getUid(this.props.location.search) })
    let medicalScreening = {
      ...globalParams,
      ...data,
    };
    prepareFormData(fd, medicalScreening);
    return await axiosInstance.post(uriPath, fd);
  };

  handleSubmit = async ()=>{
    window.formotiv.submit(getForMotivConfig("Medical Screening"));
    const { userChoice } = this.state
    this.setState({
      loading: true
    })
    const res = await this.axiosCallHelper('/questions', userChoice)
    redirectIfNeeded(this.props, res)
  }

  isBackToHealthPage =()=> {
		const historyAction = get(this.props, "history.action", "");
		return historyAction === "POP" && this.props.lastLocation !== null;
	  }
  

  render() {
      const {
          leftTextDesc,
          leftTextTitle,
          leftTextHeading,
          otherParams,
          loading,
          selected,
          message,
      } = this.state;
      const leftTextHTML = this.renderLeftTextHelper(leftTextHeading, leftTextTitle, leftTextDesc);
      return (
          <LandingPage leftText={leftTextHTML} loading={loading}>
              <div className='review-questions-area normalize-layout-right'>
                <div className='thankyou-message'>
                  <p dangerouslySetInnerHTML={{ __html: message }}/>
                </div>
              </div>
              <div className={'questions-btn-container normalize-layout-right'}>
                      <div className='global-button-wrapper mobile-view submit-button-medical'>
                        {(otherParams && !otherParams.is_next_btn_hidden) && (
                            <ButtonBlue
                              label={otherParams.next_button_text}
                              btnClickHandler={ this.handleSubmit }
                              disabled={ !selected }
                            />
                          )}
                        {(otherParams && !otherParams.is_prev_btn_hidden) &&
                          (
                            <ButtonBlue
                              label={otherParams.prev_button_text}
                              btnType={'link'}
                              // btnClickHandler={this.eSignButtonHandler(
                              //   QUESTIONS_RENDER.prev
                              // )}
                            />
                          )}
                      </div>
                    </div>
          </LandingPage>
      )
  }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setBreadCrumbs }, dispatch);
}

export default withLastLocation(connect(null, mapDispatchToProps)(MedicalScreen));
