import { getParams } from ".";

const PAYMENT_MAPPING = {
  "development": {
    EL: {
      paymentUrl: "https://tst4.expresslifeins.com/payment/payment.html",
      successUrl: `https://tst.term.expresslifeins.com/paymentsuccess`,
      domain: ".expresslifeins.com"
    },
    AAA: {
      paymentUrl: "https://tstaaa04.aaalife.com/payment/payment.html",
      successUrl: `https://tst.term.aaalife.com/paymentsuccess`,
      domain: ".aaalife.com"
    }
  },
  "uat": {
    EL: {
      paymentUrl: "https://tst4.expresslifeins.com/payment/payment.html",
      successUrl: `https://tst.term.expresslifeins.com/paymentsuccess`,
      domain: ".expresslifeins.com"
    },
    AAA: {
      paymentUrl: "https://tstaaa04.aaalife.com/payment/payment.html",
      successUrl: `https://tst.term.aaalife.com/paymentsuccess`,
      domain: ".aaalife.com"
    }
  },
  "production": {
    EL: {
      paymentUrl: "https://www.expresslifeins.com/payment/payment.html",
      successUrl: `https://term.expresslifeins.com/paymentsuccess`,
      domain: ".expresslifeins.com"
    },
    AAA: {
      paymentUrl: "https://www.aaalife.com/payment/payment.html",
      successUrl: `https://term.aaalife.com/paymentsuccess`,
      domain: ".aaalife.com"
    }
  },
  "preprod": {
    EL: {
      paymentUrl: "https://tst1.expresslifeins.com/payment/payment.html",
      successUrl: `https://tst1.term.expresslifeins.com/paymentsuccess`,
      domain: ".expresslifeins.com"
    },
    AAA: {
      paymentUrl: "https://tstaaa01.aaalife.com/payment/payment.html",
      successUrl: `https://tst1.term.aaalife.com/paymentsuccess`,
      domain: ".aaalife.com"
    }
  },
};

const paymentInfo = () => {
  const currentUrl = window.location.hostname;
  const currentEnv = process.env.REACT_APP_NODE_ENV;
  const uid = getParams("uid");
  const sessionId = getParams('session_id');
  let currentDomain = "AAA";

  if (currentUrl.match(/expresslifeins/)) {
    currentDomain = "EL"; 
  }

  const response = PAYMENT_MAPPING[currentEnv][currentDomain];
  let successUrl = response.successUrl;
  if (uid) {
    successUrl = `${successUrl}?uid=${uid}`
  } else if (sessionId) {
    successUrl = `${successUrl}?session_id=${sessionId}`;
  }
  successUrl = `${successUrl}&payment=success`

  return {
    ...response,
    successUrl,
  }
}

export default paymentInfo;
