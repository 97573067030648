import React, { useEffect, useState } from "react";
import {
  redirectIfNeeded,
  parseResponse,
  getParams,
  scrollUP,
  saveException,
  fireVirtualPageEventIfNecessary,
  getUid,
  getQueryParamValue,
  callQuestionsAPI,
  getForMotivConfig
} from "../../util";
import { PAGES_LIST } from '../../util/pages';
import paymentInfo from "../../util/payment-info";
import { LandingPage } from '..'
import { ButtonBlue, Tooltip } from '../../Components/UIElements'
import { setBreadCrumbs } from '../../redux/actions/index'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import isArray from "lodash/isArray";
import "./congratulations.scss";

// uncomment this line to test local
import { congratulationsData } from "./congratulations-data";

const initialState = {
  loading: true,
  questions: [],
  title: "",
  desc: "",
  heading: "",
  nextButtonText: "",
  prevButtonText: "",
  pageSequenceNumber: 0,
  premium: 0,
  term_length: 0,
  policy_number: 0, 
  payment_url: "",
  success_url: "",
  error_url: "",
}

const renderLeftTextHelper = (leftTextHead, leftTextBody, leftTextTitle) => {
  return (
    <div>
      {/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
      <p className='global-left-panel-page-title' dangerouslySetInnerHTML={{ __html: leftTextTitle }} />
      <p className='global-left-panel-title' dangerouslySetInnerHTML={{ __html: leftTextHead }} />
      <p
        dangerouslySetInnerHTML={{ __html: leftTextBody }}
      />
    </div>
  );
};

const getValue = (list, key) => {
  const data = list.filter(item => item.question_id === key)[0];
  if (data) {
    return data.original_value;
  }
  return "";
}

const getResponseFromHtmlElement = (response) => {
  let start = response.search(/>/i) + 1;
  let end  = response.search(/<\//i);
  return response.substring(start, end);
}

const questionStructure = (questions) => {
  if (questions && isArray(questions)) {
    return questions.map((question, index) => {
      if (question) {
        const { question_type, question_text, response, tooltip, question_id, orientation } = question;
        switch(question_type) {
          case "html_template":
            if(index === 0 && question_id.includes("PolicyNumber")){
              const policyNumber = getResponseFromHtmlElement(response);
              window.formotiv.submitFinal(getForMotivConfig("Congratulations", policyNumber));
            }
            return (
              <div className={`html-data align-${orientation}`} key={question_id}>
                <div className='question-text'>
                  <div dangerouslySetInnerHTML={{ __html: question_text }} />
                  {
                    tooltip && <Tooltip value={tooltip} id={question_id} />
                  }
                </div>
                <p className='html-paragraph' dangerouslySetInnerHTML={{ __html: response }} />
              </div>
            );
          default:
            return null
        }
      } else {
        return null;
      }
    });
  }
  return null;
}

const  deletePaymentCookie = () => {
  let { domain } = paymentInfo()
  //to delete a cookie, we need to set it to past date
  document.cookie = `aaa_pi=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:01 GMT; secure;`;
}

const shouldPaymentCookieBeDeleted = () =>{
  const excludedEnv = ["stg","local"];
  return !excludedEnv.includes(process.env.REACT_APP_NODE_ENV);
}

function Congratulations (props) {  
  const [state, setState] = useState(initialState);
  
  useEffect(() => {
    scrollUP();
    //delete aaa_pi cookie, so that user wont be able to navigate to payment page by clicking browser back.
    if(shouldPaymentCookieBeDeleted()){
      deletePaymentCookie();
    }
    async function loadResponse() {
      setState({
        ...state,
        loading: true,
      });
      try {

        fireVirtualPageEventIfNecessary(
          PAGES_LIST.COVERED,
          getUid(props.location && props.location.search),
          getQueryParamValue(props.location && props.location.search, "transaction_id")
        );

        const uid = getParams("uid");
        const payment = getParams("payment");
        const redirect = getParams("redirect");

        let requestParams = {
          uid,
        };

        if (payment && !redirect) {
          requestParams = {
            ...requestParams,
            payment_success: payment,
          };
        } else {
          requestParams = {
            ...requestParams,
            redirect,
          }
        }

        const response = await callQuestionsAPI(requestParams, null, props.location.search);

        if (response) {
          const { questionnaire, other_params, page_desc } = parseResponse(response);
          const didRedirect = redirectIfNeeded(props, response, { skipApiCall: true });

          if (!didRedirect) {
            const { page_sequence_number, questions } = questionnaire;
            const { title, desc, heading } = page_desc;
            const { next_button_text, prev_button_text } = other_params;

            setState({
              ...state,
              loading: false,
              questions,
              title,
              desc,
              heading,
              nextButtonText: next_button_text,
              prevButtonText: prev_button_text,
              pageSequenceNumber: page_sequence_number,
            });
          }
        } else {
          setState({
            loading: false,
          });

          throw new Error("Response not received.");
        }
      } catch (error) {
        saveException(error);
        console.error(error);
        props.history.push(`/error${props.location.search}`);
      }
    }

    loadResponse();
    scrollUP();
    window.formotiv.init(getForMotivConfig("Congratulations"));
  }, [])

  props.setBreadCrumbs([]);

  return (
    <LandingPage loading={state.loading} leftText={renderLeftTextHelper(state.title, state.desc, state.heading)}>
      <div className='normalize-layout-right congratulations-questions-wrapper'>
					{questionStructure(state.questions)}
				</div>
    </LandingPage>
  )
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setBreadCrumbs }, dispatch);
}

export default connect(null, mapDispatchToProps)(Congratulations);
