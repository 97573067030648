import React from "react";
import { Provider } from 'react-redux'
import { withRouter } from "react-router-dom";
import Store from './store'
import HealthCheck from "./health-check";

const store = Store

const Main =(props)=>{
    return(
      <Provider store={store}>
        <HealthCheck {...props}/>
        {/* <GTM/> */}
      </Provider>  
    )
}

export default withRouter(Main)
  