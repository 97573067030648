import React from "react";
import "./SingleSelectionButton.css";
import { Tooltip, Loader } from "..";
import {ErrorTypes, getErrMsg, getForMotivConfig} from "../../../util"

export default class SingleSelectionButton extends React.Component {
  state = {
    response: ""
  };
  handleClick = (id, elementName) => {
    if (this.state.response === id) {
      return;
    }
    this.setState({ response: id });
    this.props.handleSingleSelectBtnClick(  
      id,
      this.props.questionObj.question_id,
      this.props.questionObj.child_questions,
      this.props.questionObj.child_questions_on
    );
    window.formotiv.inputActivity(getForMotivConfig(this.props.formotivURI), elementName, "radio", id);
  };

  onKeyDown = (e, id) => {

    if(this.props.showReflexiveLoaderFor){
      e.preventDefault();
      return;
    }
    if (this.state.response === id) {
      return;
    }
    if(e.keyCode === 13 ) {

      this.setState({ response: id });
      this.props.handleSingleSelectBtnClick(
        id,
        this.props.questionObj.question_id,
        this.props.questionObj.child_questions,
        this.props.questionObj.child_questions_on
      );
    }
  }

  getErrorMessage = errorType => {
    return getErrMsg(this.props.questionObj, errorType);
  };

  componentDidMount = () => {
    const {
      questionObj: {
        question_id,
        question_text,
        validations,
        response_options,
        response,
        child_questions_on
      }
    } = this.props;

    if (response) {
      this.props.handleSingleSelectBtnClick(response.id, question_id, undefined, child_questions_on);
    }

    this.setState({
      response: response ? response.id : "",
      question_text,
      question_id,
      required: validations ? validations.required : false,
      response_options,
      response_label: response ? response.label : ""
    });
  };

  render() {
    const {
      questionObj: { question_id, question_text, response_options, validations, tooltip }, disableClass, requiredError, nextButtonOnClick
    } = this.props;

    let toolTipValue = <div dangerouslySetInnerHTML={{ __html: tooltip }} />

    const { response } = this.state;
    const value =
      validations && validations.required ? validations.required.value : false;
    return (
      <React.Fragment>
        <label htmlFor={question_id} className="global-label-text">
          {question_text} {value}{tooltip ? <Tooltip value={toolTipValue} id={question_id} /> : null}
          {/* {question_text} {value && <span style={{ color: "#0b808e" }}>*</span>} */}
        </label>
        <div className={"single-selection-button-group"} id={question_id}>
          {response_options &&
            response_options.map(({ id, label }, index) => {
              return (
                <div className="radio-toolbar"  onKeyDown={(e) => this.onKeyDown(e, id)} >

                  <input  tabIndex='-1' type="radio" id={question_id + "_" + label} 
                    checked={id === response ? true : false} />
                  <label for={question_id +"_" + label} className={`single-selection-btn ${id === response &&
                        "single-selected-active"}`} tabIndex={disableClass ? -1 : 0}  onClick={() => this.handleClick(id, question_id + "_" + label) }
                  id={index}>{label}</label>                    
                    </div>
                      );
            })}
          {
            this.props.showReflexiveLoaderFor === question_id
              ? (
                <div className="reflexive-question-loader">
                  <Loader />
                </div>
              )
              : null
          }
        </div>
        {requiredError && nextButtonOnClick &&
          requiredError !== ErrorTypes.noError && (
            <p
              style={{
                fontSize: 12,
                color: "red",
                marginTop: 0,
                marginBottom: 15
              }}
            >
              {this.getErrorMessage(requiredError)}
            </p>
          )}
      </React.Fragment>
    );
  }
}
