import React, { useState, useEffect } from "react";
import LandingPage from "../LandingPage/landingPage";
import ButtonBlue from "../../Components/UIElements/ButtonBlue/button";

import "./challenge-question.scss";
import {
  getParams,
  saveException,
  callQuestionsAPI,
  parseResponse,
  redirectIfNeeded,
  scrollUP,
  getForMotivConfig
} from "../../util";

const initialState = {
  loading: true,
  questions: [],
}

export default function (props) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    async function loadResponse () {
      let requestParams = {}

      try {
        setState({
          ...state,
          loading: true,
        });

        if(props.location.pathname.match("continue")) {
          requestParams = {
            remarketing_token: true
          }
        }
        
        if(props.location.pathname.match("challenge")) {
          requestParams = {
            followed_link: true
          }
        }
 
        const response = await callQuestionsAPI(requestParams, null, props.location.search);
        if (response) {
          const didRedirect = redirectIfNeeded(props, response, {fromReview: true});

          if (!didRedirect) {
            const { questions } = parseResponse(response).questionnaire;
            setState({
              ...state,
              loading: false,
              questions,
            })
          }
        } else {
          throw new Error ("Response not received.");
        }
      } catch (err) {
        console.error (err);
        saveException (err);
        props.history.push(`/error${props.location.search}`);
      }
    }
    
    loadResponse();
    scrollUP();
    window.formotiv.init(getForMotivConfig("Challenge Questions"));
  }, []);

  return (
    <LandingPage loading={state.loading} leftText={"Test"}>
      <div className='normalize-layout-right congratulations-questions-wrapper'>
					Questions will appear here.
      </div>
      <div className={"global-button-margin normalize-layout-right review-question-margin"}>
					<div className={"global-button-wrapper"}>
						<ButtonBlue
							label={"Submit"}
							btnType='pill'
							btnClickHandler={() => {
                window.formotiv.submit(getForMotivConfig("Challenge Questions"));
              }}
						/>
					</div>
				</div>
    </LandingPage>
  )
}

