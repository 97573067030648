export const QUOTE_API='quote_api'
export const INFO_ADDED = 'INFO_ADDED'
export const GET_INFO='get_info'
export const GET_QUOTE='get_quote'
export const ERROR='error'
export const ZIP="zip"
export const BREADCRUMBS='breadcrumbs'
export const AWAITRESPONSE='AWAITRESPONSE'
export const AUTOSUGGEST='AUTOSUGGEST'
export const DOB='DOB'
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const UPDATE_TERM_LENGTH_OPTIONS = "UPDATE_TERM_LENGTH_OPTIONS";
export const IS_CAMPAIGN_MOUNTED = "is_campaign_mounted"

export const CREATE_QUOTE_START = "CREATE_QUOTE_START";
export const CREATE_QUOTE_SUCCESS = "CREATE_QUOTE_SUCCESS";
export const GET_DETAILS_START = "GET_DETAILS_START";
export const GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
export const GET_QUOTE_DATA_START = "GET_QUOTE_DATA_START";
export const GET_QUOTE_DATA_SUCCESS = "GET_QUOTE_DATA_SUCCESS";

export const SET_SELECTED = "SET_SELECTED";
export const SET_COVERAGE_AMOUNT = "SET_COVERAGE_AMOUNT";
export const SET_PLAN_ID = "SET_PLAN_ID";
export const SET_UID = "SET_UID";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const UPDATE_PREMIMUM_PAGE_ERROR = "UPDATE_PREMIMUM_PAGE_ERROR";
