import React from "react";
import "./GroupedQuestions.css";

import { CreateRequiredSchema } from "../../../util";

export default class GroupedQuestions extends React.Component {
	componentDidMount = () => {
		this.props.setGroupQuestionStatus(true);

		// check is mounted before calling props' questionStructure callback
		// to ensure component is mounted and the correct order of render
		// of grouped and list question is maintained.
		this.__isMounted = true;
	};
	render() {
		const { validations } = this.props.question;
		//let validations = { disp_line: { value: "solid", position: "bottom" } };
		let line_display = validations &&
			validations.disp_line && (
				<div
					className={
						validations.disp_line.value === "solid"
							? "group-solid-line"
							: "group-dotted-line-break"
					}
				/>
			);

		let indexLvl3Exists = false;
		if (
			this.props.question &&
			this.props.question.index != null &&
			this.props.question.index !== undefined
		) {
			indexLvl3Exists =
				String(this.props.question.index).split(".").length > 2 ? true : false;
		}
		return (
			<React.Fragment>
				{validations &&
					validations.disp_line &&
					validations.disp_line.position === "top" &&
					line_display}

				{indexLvl3Exists ? (
					<div className="group_question_lvl_3">
						{this.props.question.question_text}
					</div>
				) : (
						<h4 className={`padding-bottom-15 ${this.props.question.is_hidden ? "hide" : "show"}`}>
							{this.props.question.question_text}
						</h4>
					)}
				<div>{this.__isMounted && this.props.questionStructure(this.props.question)}</div>
				{validations &&
					validations.disp_line &&
					validations.disp_line.position === "bottom" &&
					line_display}
			</React.Fragment>
		);
	}
}