import React from "react";
import { formatAmount } from '../../util/index'

export const HELPER = {
	getTermTooltip() {
		return (
      <div>
        <p>
          Consider what you’d like to cover with your policy, and think about how long you expect your specific needs to last.
          Factors to consider:
        </p>
        <ul>
          <li>How many years until your house is paid off</li>
          <li>How long childcare and extracurricular activities need to be covered</li>
          <li>Future college or education expenses for your spouse or your children</li>
          <li>When you plan to retire</li>
        </ul>
			</div>
    )
	},

	getCoverageTooltip() {
		return (
      <div>
        <p>
		If you were no longer here, what would you like your life insurance policy to pay for? Some common examples are:
        </p>
        <ul>
          <li>A mortgage or car loan</li>
          <li>Credit card debt</li>
          <li>Medical bills</li>
          <li>Student loans or education expenses</li>
        </ul>
      </div>
    )
	},

	getLabels(min, max, label) {
		return {
			[parseInt(min)]: formatAmount(parseInt(min), 0, label),
			[parseInt(max)]: formatAmount(parseInt(max), 0, label)
		}
	},

  getTermLengthOptions(termLengths) {
		return termLengths.map(term => ({
			value: term.term_length,
			label: `${term.term_length} Years`
		}));
	},

	getOptions(selected, options) {
		if (options.length) {
			return options;
		}
		return [];
	},

	getRangeAttributes(coverageRanges) {
		const max = parseInt(coverageRanges[1].max_value, 10);
		const min = parseInt(coverageRanges[0].min_value, 10);
		const step_1 = parseInt(coverageRanges[0].step_length, 10);
		const step_2 = parseInt(coverageRanges[1].step_length, 10);
		const mid = parseInt(coverageRanges[0].max_value, 10);

		return {
			max,
			min,
			step_1,
			step_2,
			mid
		}
	},

	getCoverageAmountOptions(coverageAmount) {
		const { coverage_ranges, currency_symbol } = coverageAmount;
		const { max, min, step_1, step_2, mid } = this.getRangeAttributes(coverage_ranges);

		let options = [];
		if (min === 0) return [];
		for (let index = min;
			index <= max;
			index = (index < mid ? (index + step_1) : (index + step_2))) {
				options.push ({
					value: index,
					label: formatAmount(index, 0, currency_symbol)
				});
			}
		return options;
	},

	roundOff(value, mid, step_2) {
		const rem = value % step_2;
		if (value <= mid || rem === 0) return value;
		return value + rem;
	}
}