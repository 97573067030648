import React from "react";
import Select from "react-select";
import debounce from "debounce-promise";
import "./MultiSelectDropdown.css";
import get from 'lodash/get'
import { Checkbox, Tooltip, Loader } from "..";
import { CustomOption, isMobile, ErrorTypes, capitalizeFirstLetter, getForMotivConfig } from "../../../util";

export default class MultiSelectDropdown extends React.Component {
  state = {
    display_type: "checkbox",
    orientation: "",
    question_text: "",
    question_id: "",
    index: "",
    response_options: [],
    selected_options: [],
    required_value: "",
    required_error_message: "",
    error: ""
  };

  componentDidMount() {
    if (this.props.questionObj) {
      let {
        questionObj: {
          display_type,
          orientation,
          question_text,
          question_id,
          validations,
          index,
          response_options,
          response,
          allow_form_submit,
          child_questions
        }
      } = this.props;
      const selectedOptions = [];
      if (response) {
        if (display_type === "checkbox") {
          if(allow_form_submit)
           response=[response]
          response.forEach(item => {
            selectedOptions.push(item.id);
          });
        } else {
          response.forEach(item => {
            selectedOptions.push({ value: item.id, label: capitalizeFirstLetter(item.label) });
          });
        }
      }

      const dropDownOptions = [];
      response_options.forEach(item => {
        dropDownOptions.push({ value: item.id, label: capitalizeFirstLetter(item.label) });
      });

      const outPut = this.getErrorType(selectedOptions, {
        min_value:
          validations && validations.min_selection_length
            ? validations.min_selection_length.value
            : 0,
        max_value:
          validations && validations.max_selection_length
            ? validations.max_selection_length.value
            : Number.MAX_SAFE_INTEGER,
        required: false
      });

      let value =
        this.props.questionObj &&
        this.props.questionObj.response &&
        this.props.questionObj.response.length > 0 &&
        Array.isArray(this.props.questionObj.response) &&
        this.props.questionObj.response.map(option => option.id);
       if( !value && allow_form_submit)
         value =  [get(this.props,"questionObj.response.id", false)]
      if (
        this.props.questionObj &&
        this.props.questionObj.response &&
        (this.props.questionObj.response.length > 0 || allow_form_submit)
      ) {
        this.props.handleMultiSelectChange(
          value,
          this.props.questionObj.question_id,
          outPut,
          allow_form_submit,
          undefined,
          this.props.questionObj.question_type,
        );
       
      }

      this.setState({
        max_value:
          validations && validations.max_selection_length
            ? validations.max_selection_length.value
            : Number.MAX_SAFE_INTEGER,
        min_value:
          validations && validations.min_selection_length
            ? validations.min_selection_length.value
            : 0,
        display_type: display_type,
        orientation: orientation,
        question_text: question_text,
        question_id: question_id,
        index: index,
        response_options: dropDownOptions,
        selected_options: selectedOptions,
        required_value:
          validations && validations.required
            ? validations.required.value
            : false,
        required_error_message:
          validations && validations.required
            ? validations.required.error_message
            : "",
        max_selection_length:
          validations && validations.max_selection_length
            ? validations.max_selection_length.value
            : Number.MAX_SAFE_INTEGER,
        min_selection_length:
          validations && validations.min_selection_length
            ? validations.min_selection_length.value
            : 0,
        max_selection_length_error_message:
          validations && validations.max_selection_length
            ? validations.max_selection_length.error_message
            : "",
        min_selection_length_error_message:
          validations && validations.min_selection_length
            ? validations.min_selection_length.error_message
            : "",
        required: validations ? validations.required : "false",
        error: outPut,
        allow_form_submit: allow_form_submit
      });

      if (validations.disp_line) {
        switch (validations.disp_line.position) {
          case 'bottom':
            this.setState({
              border: { borderBottom: '1px solid rgba(215, 215, 215, 1)', paddingBottom: '11px' }
            })
            break;
          case 'top':
            this.setState({
              border: { borderTop: '1px solid rgba(215, 215, 215, 1)', paddingBottom: '11px' }
            })
            break;
          default:
            break;
        }
      }
      console.log(response, allow_form_submit, allow_form_submit && response === undefined)

      if (allow_form_submit && (response === undefined || response.length === 0)) {
        this.props.handleFormDisable()
      }
    }
    if(this.select && this.select.select && this.select.select.inputRef){
      this.select.select.inputRef.name = `${this.props.questionObj.question_id}`;
    }
  }

  getErrorType = (value, { min_value, max_value, pattern, required }) => {
    if (value && value.length > max_value) {
      return "MAX_VALUE";
    } else if (value && value.length < min_value) {
      return "MIN_VALUE";
    } else if ((value === null || value.length === 0) && required === true) {
      return "REQUIRED";
    } else {
      return "NO_ERROR";
    }
  };

  getErrorTypeDebounce = debounce(this.getErrorType, 2000);

  handleInputOnChange = value => {
    const {
      questionObj: { validations }
    } = this.props;
    if (JSON.stringify(this.state.selected_options) === JSON.stringify(value)) {
      return;
    }
    // ADD FACTOR

    const outPut = this.getErrorType(value, {
      min_value:
        validations && validations.min_selection_length
          ? validations.min_selection_length.value
          : 0,
      max_value:
        validations && validations.max_selection_length
          ? validations.max_selection_length.value
          : Number.MAX_SAFE_INTEGER,
      required:
        validations && validations.required && validations.required.value
    });

    this.setState({
      error: outPut,
      selected_options: value
    });
    // Send the data back to parent with error message and question_id
    this.props.handleMultiSelectChange(
      value,
      this.props.questionObj.question_id,
      outPut,
      this.state.allow_form_submit,
      this.props.questionObj.child_questions,
      this.props.questionObj.question_type,
      this.props.questionObj.child_questions_on
    );
  };

  getborderStyle = error => {
    if (!error || error === "NO_ERROR") {
      return null;
    }
    return {
      borderColor: "red"
    };
  };

  handleChange = selectedOption => {
    this.setState({
      selected_options: selectedOption
    });
    this.handleInputOnChange(selectedOption);
    if(this.select && this.select.select && this.select.select.inputRef){
      window.formotiv.inputActivity(getForMotivConfig(this.props.formotivURI), `${this.props.questionObj.question_id}`, "dropdown", selectedOption.id);
    }
    //this.setState();
  };

  handleCheckboxGroupChange = (selectedValue, event) => {
    //this.handleChange(selectedValue);
  };

  handleCheckboxChange = (selectedValue, event) => {
    //event.preventDefault();
    if (event.target.checked) {
      this.handleChange(this.state.selected_options.concat(selectedValue));
    } else {
      this.handleChange(
        this.state.selected_options.filter(value => value !== selectedValue)
      );
    }
  };
  getErrorMessage = errorType => {
    const {
      questionObj: { question_text, validations }
    } = this.props;

    // ADD FACTOR
    const min_length_error_message =
      validations && validations.min_selection_length
        ? validations.min_selection_length.error_message
        : "";
    const max_length_error_message =
      validations && validations.max_selection_length
        ? validations.max_selection_length.error_message
        : "";

    const errors = {
      MAX_VALUE: max_length_error_message,
      MIN_VALUE: min_length_error_message,
      REQUIRED: validations && validations.required.error_message
    };

    let error_message = errors[errorType];
    if(!error_message && errorType === ErrorTypes.required) {
      if(question_text.length < 20)
        error_message = "The " + question_text.toLowerCase() + " field is required.";
      else
        error_message = "This field is required.";
    }
    return error_message;
  };

  onKeyDown = (e) => {
    let { response_options } = this.props.questionObj
    let optionFilter = response_options.filter((ele) => {
      return ele.label.toUpperCase().indexOf((e.target.value).toUpperCase()) > -1
    })
    if ((!this.isMenuOpen || optionFilter.length === 0) && e.keyCode === 13) {
      console.log('hi')
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render() {
    const {
      required_value,
      response_options,
      selected_options,
      question_text,
      question_id,
      display_type,
      orientation,
      error,
      border
    } = this.state;
    let { questionObj:{ tooltip }, requiredError, nextButtonOnClick } = this.props
    let toolTipValue = <div dangerouslySetInnerHTML={{ __html: tooltip }} />
    const colourStyles = {
      option: styles => {
        return {
          ...styles,
          backgroundColor: "#fff",
          color: "#000"
        };
      }
    };

    const customStyle = this.getborderStyle(error);
    const prevStyle = {
      width: this.props.selectContainerWidth || "100%",
      margin: "30px 0"
    };
    const widthStyle = {
      width: isMobile() ? "100%" : "360px"
    };
    const currentStyle = {
      ...customStyle,
      ...prevStyle
    };
    const errorHtml =
    ((error && error !== "NO_ERROR") || requiredError && requiredError !== ErrorTypes.noError && nextButtonOnClick ) ? (
        <p
          style={{
            fontSize: 12,
            color: "red",
            marginTop: 8,
            marginBottom: 15
          }}
        >
          {this.getErrorMessage(requiredError)}
        </p>
      ) : (
          ""
        );

    if (display_type === "checkbox") {
      let checkboxes = "";
      if (orientation === "rows") {
        checkboxes = response_options.map((item, i) => (
          <div className={"col-sm-12 dv-row-chkBox"} key={i}>
            <label className="checkbox-container">
              <Checkbox
                className="checkbox-checkmark"
                value={item.value}
                checkboxValue={item.value}
                handleCheckboxChange={this.handleCheckboxChange}
                checked={selected_options.includes(item.value)}
                id={i}
                rightTxt={item.label}
                borderPosition={border}
                checkboxName={`${question_id}_${display_type}_${i}`}
              />
            </label>
          </div>
        ));
      }
      if (orientation === "columns") {
        checkboxes = response_options.map((item, i) => (
          <div className={"col-sm-3"} key={i}>
            <label className="checkbox-container">
              <Checkbox
                className="checkbox-checkmark"
                value={item.value}
                checkboxValue={item.value}
                id={i}
                handleCheckboxChange={this.handleCheckboxChange}
                checked={selected_options.includes(item.value)}
                rightTxt={item.label}
                checkboxName={`${question_id}_${display_type}_${i}`}
              />
            </label>
          </div>
        ));
      }

      let checkBoxGroups = <div id={question_id}>{checkboxes} </div>;

      return (
        <div>
          <label htmlFor={question_id} className="global-label-text">
            {question_text}{" "}{tooltip ? <Tooltip value={toolTipValue} id={question_id} /> : null}
            {/* {required_value && <span className="label_icon_star">*</span>} */}
          </label>
          <div className={""} style={currentStyle}>
            <div className="check-box-groups">{checkBoxGroups}</div>
            {errorHtml}
          </div>
        </div>
      );
    } else {
      return (
        <div className={`react-select-custom`} style={currentStyle}>
        <label htmlFor={question_id} className="global-label-text">
          {question_text} {required_value}{tooltip ? <Tooltip value={toolTipValue} id={question_id} /> : null}
          {/* {required_value && <span className="label_icon_star">*</span>} */}
        </label>

        <div className={`react-select-custom react-select-wrapper`} style={widthStyle}>
        <div className="multi-select-box-loader-container">
          <div className="multi-select-container">
            <Select
              id={question_id}
              // className={this.props.className}
              value={selected_options}
              onChange={this.handleChange}
              options={response_options}
              styles={colourStyles.option(`react-select-custom`)}
              closeMenuOnSelect={true}
              isMulti={true}
              onMenuOpen={() => this.isMenuOpen = true}
              onMenuClose={() => this.isMenuOpen = false}
              hideSelectedOptions={false}
              isSearchable={true}
              onKeyDown={this.onKeyDown}
              components={{ Option: CustomOption }}
              ref={ref => this.select = ref }
            />
          </div>
          {
            this.props.showReflexiveLoaderFor === question_id
              ? (
                <div className="reflexive-question-loader react-select-loader">
                  <Loader />
                </div>
              )
              : null
          }
        </div>
          {errorHtml}
        </div>
      </div> 
      );
    }
  }
}
