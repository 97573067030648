import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import { setBreadCrumbs } from '../../redux/actions/index'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { LandingPage } from '..'
import { ButtonBlue } from '../../Components/UIElements/index';
import {
	saveException,
	getUid,
	prepareFormData,
	parseResponse,
	redirectIfNeeded,
	callQuestionsAPI,
	getResponseFromLocation,
	axiosCallHelper,
	fireVirtualPageEventIfNecessary,
	getQueryParamValue,
	scrollUP,
	getForMotivConfig
} from '../../util/index'
import { axiosInstance } from "../../util/axios";
import './review.scss';
import { PAGES_LIST } from '../../util/pages';
import get from 'lodash/get';
import { formattedData } from '../../Components/UIElements/Input/input-util';
import Checkbox from "../../Components/UIElements/Checkbox/index";
import { withLastLocation } from 'react-router-last-location';


class Review extends Component {

	constructor(props) {
		super(props)
		this.state = {
			leftTextBody: '',
			leftTextHead: '',
			leftTextTitle: '',
			reviewQuestions: [],
			loading: true,
			page_sequence_number: 0,
			nextButtonText: '',
			prevButtonText: '',
			isChecked:false,
			isError:false,
			text:''
		}
		window.formotiv.init(getForMotivConfig("Review"));
	}

	async componentDidMount() {
		scrollUP();
		fireVirtualPageEventIfNecessary(
            PAGES_LIST.REVIEW_PAGE,
            getUid(this.props.location.search),
            getQueryParamValue(this.props.location.search, "transaction_id")
				);
		if(this.isBackToQuestionsPage()){
			this.setState({
				loading: true,
			})
			let response
			// if landed to review page by back button from the questions page (review flow) CANCEL the update
			if(get(this.props,'lastLocation.pathname','')==='/questions'){
				const res = JSON.parse(get(this.props,'lastLocation.state.response','{}'))
				const pageSequenceNumber = get(res,'data.response.data.questionnarie.page_sequence_number',1) 
				response =	await axiosCallHelper(this.props, '/questions', '', {action: 'CANCEL_UPDATE', page_sequence_number:pageSequenceNumber})
			}else{
			// hard-coding page_sequence_number of signature_page
			 response = await axiosCallHelper(this.props,'/questions','', {prev_page_flag: 1, page_sequence_number:10,})
			}
			const didRedirect = redirectIfNeeded(this.props, response);
			if (didRedirect) {
				return;
			}
			this.processResponseAndUpdateState(response);
			this.setState({
				loading: false
			})
			return
		}	
		try {
			let response = await getResponseFromLocation(this.props);
			const didRedirect = redirectIfNeeded(this.props, response);
			if (didRedirect) {
				return;
			}
			this.processResponseAndUpdateState(response);
		} catch (err) {
			console.error("Error, ", err);
		}
	}

	isBackToQuestionsPage () {
    const historyAction = get(this, "props.history.action", "");
    return historyAction === "POP" && this.props.lastLocation !== null;
  }

	processResponseAndUpdateState = (response) => {
		try {
			const { questionnaire, other_params, page_desc, breadcrumbs } = parseResponse(response);
			this.props.setBreadCrumbs(breadcrumbs || []);

			if (questionnaire && other_params && page_desc) {
				const { page_sequence_number, questions } = questionnaire;
				const { title, desc, heading } = page_desc;
				const { next_button_text, prev_button_text } = other_params;
				this.setState({
					leftTextHead: title,
					leftTextBody: desc,
					leftTextTitle: heading,
					reviewQuestions: questions,
					loading: false,
					nextButtonText: next_button_text,
					prevButtonText: prev_button_text,
					page_sequence_number,
				});
			}
		} catch (error) {
			console.error("Error", error);
		}
	}

	renderLeftTextHelper = (leftTextHead, leftTextBody, leftTextTitle) => {
		return (
			<div>
				{/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
				<p className='global-left-panel-page-title' dangerouslySetInnerHTML={{ __html: leftTextTitle }} />
				<p className='global-left-panel-title' dangerouslySetInnerHTML={{ __html: leftTextHead }} />
				<p
					dangerouslySetInnerHTML={{ __html: leftTextBody }}
				/>
			</div>
		);
	};

	axiosCallHelper = async (uriPath, q_id) => {
		const completedQuestionsFormData = new FormData();
		//const globalParams = getGlobalParams(this.props);
		const globalParams = Object.assign({ 'uid': getUid(this.props.location.search) })
		let formDataForCompeltedQues = {
			...globalParams,
			...q_id
		};
		prepareFormData(completedQuestionsFormData, formDataForCompeltedQues);
		return await axiosInstance.post(uriPath, completedQuestionsFormData);
	};


	updateQuestion = async (question_id) => {
		scrollUP()
		this.setState({ loading: true });
		try {
			let response = await axiosCallHelper(this.props, "/questions", { q_id: question_id });

			if (response.status === 200) {
				if (response && response.loginRedirectUrl) {
					window.location.href = response.loginRedirectUrl;
					return;
				}
				const didRedirect = redirectIfNeeded(this.props, response, { fromUpdate: true });
				if (!didRedirect) {
					this.setState({
						loading: false
					});
					console.info("Redirect did not happen when it was expected,");
				}
				return;
			}
		} catch (e) {
			saveException({ apiName: '/v1/question', message: e });
			console.log(e);
		}
		this.setState({ loading: false });
	}

	getFormattedResponse = (ele) => {
		const hasAutoFormat = get(ele, 'validations.auto_format', null);
		const hasMask = get(ele, 'validations.mask.mask_char', null);
		const metricObj = get(ele, 'validations.metric', null);
		if ((hasAutoFormat || metricObj) && !hasMask) {
			return formattedData(ele, ele.response, '$', null, true);
		}
		return ele.response;
	}

	handleCheckboxChange = (value,event) => {
		let isChecked = event.target.checked ? true : false
		this.setState({
			isChecked,
			isError:false
		})
	};

	getReviewQuestions = (reviewQuestions) => {
		let reviewQuestionsFormatted = reviewQuestions.map((ele, index) => {
			if (ele.is_hidden) {
				return null;
			}
			let hasMetricParam = get(ele, "validations.metric", null);
			if (ele.question_type !== "group" && ele.display_type !== "heading")
				return (
					<div key={index} className='review-question'>
						<p className={`review-question-text ${ele.is_editable ? null : 'non-editable'}`}>{ele.question_text}</p>
						<p className={`review-question-answer ${hasMetricParam ? 'non-capitalized' : ''} ${ele.response==='Yes' || ele.response==='No' ? 'answer-bold' : ''}`}>
							{this.getFormattedResponse(ele)}
						</p>
						{ele.is_editable ? <div className='review-question-update'><span className='update-text' onClick={() => this.updateQuestion(ele.question_id)}>Update</span></div> : null}
					</div>
				)
			else if (ele.display_type === "breadcrumb" && ele.question_type === "group")
				return (
					<div key={index} className='review-header-area'>
						<p className='review-question-header'>{ele.question_text}</p>
					</div>
				)
			else if (ele.display_type !== "breadcrumb" && ele.question_type === "group") {
				return (
					<div key={index} className='review-sub-header-area'>
						<p className='review-question-header'>{ele.question_text}</p>
						{
							// back-end needs to is_editable false for this question.
							ele.is_editable
								? (
									<div className='review-question-update'>
										<span
											className='update-text'
											onClick={() => this.updateQuestion(ele.question_id)}>
											Update
										</span>
									</div>)
								: null
						}
					</div>
				)
			} else if (ele.display_type === "heading") {
				return (
					<div key={index} className='review-question'>
						<p className='review-question-text '><span className='text-heading'> {ele.question_text}</span> </p>
					</div>
				)
			}
		})
		return reviewQuestionsFormatted;
	}

	handleBackClick = async () => {
		this.onButtonClick({
			"prev_page_flag": 1
		}, { fromReview: true });
	}

	handleBtnClick = () => {   
		//Uncomment if else validations to validate check box once review page is combined
		// if(this.state.isChecked)
		// {
		this.onButtonClick({
			"next_page_flag": 1,
			review_completed: true,
		}, {
			skipApiCall: true
		});
		scrollUP()
		window.formotiv.submit(getForMotivConfig("Review"));
		// this.setState({
		// 	text: 'We are processing your application. This may take up to 2 minutes.'
		// })
		// }
		// else{
		// 	this.setState({
		// 		isError:true
		// 	})
		// }
	}

	onButtonClick = async (formParams, stateParams) => {
		try {
			this.setState({
				loading: true,
			});
			const response = await callQuestionsAPI(
					formParams,
					this.state.page_sequence_number,
					this.props.location.search
				);
			// handleBackClick should redirect the user to questions page
			if (response) {
				const didRedirect = redirectIfNeeded(this.props, response, stateParams);
				if (!didRedirect) {
					this.setState({ loading: false });
				}
			} else {
				throw new Error("Issue with API call in handleBackClick, review.js")
			}
			
		} catch (error) {
			this.setState({ loading: false });
			console.error("Error, ", error);
		}
	}

	render() {
		// scrollUP();
		const {
			leftTextBody,
			leftTextHead,
			leftTextTitle,
			reviewQuestions,
			loading,
			text
		} = this.state;
		const leftTextHTML = this.renderLeftTextHelper(leftTextHead, leftTextBody, leftTextTitle);
		return (
			<LandingPage leftText={leftTextHTML} loading={loading} text={text}>
				<div className='review-questions-area normalize-layout-right'>
					{this.getReviewQuestions(reviewQuestions)}
				</div>
				<div className={"global-button-margin normalize-layout-right review-question-margin"}>
					<div className={"global-button-wrapper"}>
						<ButtonBlue
							label={this.state.prevButtonText}
							btnType='link'
							btnClickHandler={this.handleBackClick}
						/>
						<ButtonBlue
							label={this.state.nextButtonText}
							btnType='pill'
							btnClickHandler={this.handleBtnClick}
							disabled={this.props.isError}
						/>
					</div>
				</div>
			</LandingPage>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setBreadCrumbs }, dispatch);
}

export default withLastLocation(connect(null, mapDispatchToProps)(Review));