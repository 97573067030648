import {BREADCRUMBS,ERROR} from '../types';
import { getRandomValue } from '../../util';

const INITIAL_STATE = {
    breadcrumbs: [],
    errorMessage:'',
    breadcrumbsLoaded: false,
    sessionTrackResponse:{},
    fakeState: getRandomValue()
}


export default function utilReducer(state = INITIAL_STATE, action) {
    
    const { type,payload } = action;
    switch (type) {       
        case BREADCRUMBS:   
            return { ...state, 
                breadcrumbs:payload,
                breadcrumbsLoaded: true
            }; 
        case ERROR:return{
            ...state, errorMessage:payload ? payload : ''
        }
        
        case "SESSION_TRACK":return{
            ...state, sessionTrackResponse:payload
        }
        case "FAKE_UPDATE_STORE": return {
            ...state,
            fakeState: getRandomValue(),
        }
        default:
            return {...state};
    }
}