import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { LastLocationProvider } from 'react-router-last-location';
import "./App.css";
import Header from "./Components/Header/header";
import Footer from "./Components/Footer/footer";
import { Campaign, Questions, Quote, Error, Review, PaymentMessage, Sign, MedicalScreen } from './Pages/index'
import Protected from './Components/Protected/protected';
import UidProtected from './Components/UidProtected/uidProtected';
import Interstatial from "./Pages/Interstatial/interstatial";
import constants from './util/constants';
import MessageScreen from './Pages/MessageScreen';
import Congratulations from "./Pages/Congratulations";
import Approved from './Pages/Approved';
import ChallengeQuestion from "./Pages/ChallengeQuestion";
import { Beforeunload } from 'react-beforeunload';
import {connect} from 'react-redux'
import get from "lodash/get"
import {getParams} from './util'
import initApp from './initApp';


class App extends Component {
  constructor(props){
    super(props)
    this.state={
      status:''
    }

  }
  componentDidUpdate=(prevProps)=>{
    if(JSON.stringify(prevProps.sessionTrackResponse)!==JSON.stringify(this.props.sessionTrackResponse)){
      const currentStatus = get(this.props.sessionTrackResponse,"data.response.other_params.current_status",'')
      if(currentStatus==='timeout')
      {
        const uid = getParams('uid')
        this.props.history.push(`/timeout?uid=${uid}`)
      }
      if(currentStatus==='authcode')
      {
        const uid = getParams('uid')
        const session_id = getParams('session_id')
        if(uid)
          this.props.history.push(`/authcode?uid=${uid}`)
        if(session_id)
        this.props.history.push(`/authcode?session_id=${session_id}`)  
      }
        
    }
  }
  componentDidMount() {
    initApp(this.props);
  }

  getPageStatus=(page)=>{
    this.setState({
      status:page
    })
  }

  render() {
    const { status } = this.state
    return (
        <React.Fragment>
          <div className="global-header normalize-header">
            <Header status={status} />
          </div>
          <div className='global-body-canvas-root'>
            <div className="global-body-canvas" style={wrapperInLineStyle}>
              <Protected>
                <LastLocationProvider>
                <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <Beforeunload onBeforeunload={() => "You'll lose your data!"}>
                          <Campaign {...this.props} />
                        </Beforeunload>
                      )
                    }/>
                   <Route
                    exact
                    path="/payment"
                    render={() => <PaymentMessage {...this.props} />}
                  />
                  <Route
                    exact
                    path="/error"
                    render={() => <Error {...this.props} />}
                  />
                  <Route
                    exact
                    path="/paymentsuccess"
                    render={() => <Congratulations {...this.props} />}
                  />
                  <Route
                    exact
                    path="/authcode"
                    render={() => <Sign {...this.props} />}
                  />
                  <Route
                    exact
                    key="/timeout"
                    path="/timeout"
                    render={() => <Sign {...this.props} />}
                  />
                  <Route
                    exact
                    path="/approved"
                    render={() => <Approved {...this.props} getPageStatus={ this.getPageStatus }/>}
                  />
                  <Route
                    exact
                    path="/quote"
                    render={() => <Quote {...this.props} />}
                  />
                  <Route
                    exact
                    path="/challenge"
                    render={() => <ChallengeQuestion {...this.props} />}
                  />
                  <Route
                    exact
                    path="/continue"
                    render={() => <ChallengeQuestion {...this.props} />}
                  />
                  <Route
                    exact
                    path="/system-error"
                    render={() => <MessageScreen {...this.props} messageType="System Error"/>}
                  />
                  <Route
                    exact
                    path="/system-unavailable"
                    render={() => <MessageScreen {...this.props} messageType="System Unavailable"/>}
                  />
                  <Route
                    exact
                    key="/app-system-unavailable"
                    path="/app-system-unavailable"
                    render={() => <Sign {...this.props} />}
                  />
                <UidProtected location={this.props.location}>
                  <Route
                    exact
                    path="/interstatial"
                    render={() => <Interstatial {...this.props} />}
                  />
                    <Route
                      exact
                      path="/questions"
                      render={() => (
                        <Beforeunload onBeforeunload={() => "You'll lose your data!"}>
                          <Questions {...this.props} />
                        </Beforeunload>
                      )
                    }/>
                  <Route
                    exact
                    path="/review"
                    render={() => <Review {...this.props} />}
                  />
                  <Route
                    exact
                    path="/thankyou"
                    render={() => <MessageScreen {...this.props} messageType="Thank You"/>}
                  />
                  <Route
                    exact
                    path="/completed"
                    render={() => <MessageScreen {...this.props} getPageStatus={ this.getPageStatus } messageType="Completed" />}
                  />
                  <Route
                    exact
                    path="/medical_screening"
                    render={() => <MedicalScreen {...this.props} getPageStatus={ this.getPageStatus } />}
                  />
                  <Route
                    exact
                    path="/signature"
                    render={() => <Sign {...this.props} />}
                  />
               </UidProtected>
                </Switch>
                </LastLocationProvider>
              </Protected>
            </div>
          </div>
          <Footer />
        </React.Fragment>
    )
  }
}

const wrapperInLineStyle = {
  minHeight: constants.screenMinHeightForContent
};

const mapStateToProps =(state)=>{
  return{
    sessionTrackResponse: state.utilReducer.sessionTrackResponse,
  }
} 
export default withRouter(connect(mapStateToProps)(App))