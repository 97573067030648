import React, { Component } from "react";
import './input.css'
import emojiRegex from 'emoji-regex';
import { isMobile } from '../../../../util'

export default class InputFeild extends Component {

	constructor(props) {
		super(props)
		this.state = {
			value: ''
		}
	}

	componentDidMount() {
		if (this.props.value) {
			this.setState({
				value: this.props.value
			})
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value) {
			this.setState({
				value: nextProps.value
			})
		}
	}

	onChange = (e) => {
		let maxAllowed = this.props.maxLength;
		let maxValue = this.props.maxValue;

		const { id } = e.target;

		if (this.props.maxLength && this.props.maxLength[id]) {
			maxAllowed = this.props.maxLength[id];
		}

		if (this.props.maxValue && this.props.maxValue[id]) {
			maxValue = this.props.maxValue[id];
		}

		if (maxAllowed && e.target.value.length > maxAllowed) {
			return;
		}

		if (maxValue && e.target.value > maxValue) {
			return;
		}

		// check for emoji
		const { value } = e.target;
		const regex = emojiRegex();
		if (regex.exec(value)) {
			e.preventDefault();
			e.stopPropagation();
			return;
		}

		if (isMobile()) {
			const { type } = this.props;
			const numberRegex = new RegExp('^\\d+$');
			if (type === 'number' && value) {
				if (!numberRegex.test(value)) return;
			}
		}

		this.setState({
			value: e.target.value.trim(),
		})
		this.props.onInputChnage(e)
	}

	isAllowedKey = (keyCode) => {
		return [8, 9, 46, 37, 39].indexOf(keyCode) > -1;
	}

	onkeydown = (e) => {
		if(e.target.value === '' && e.keyCode === 32){
			e.preventDefault();
		}

		if (this.props.type === 'number') {
			const num = parseInt(e.key);
			var key = e.keyCode || e.charCode;
			const numberRegex = new RegExp('^\\d+$');
			// if not a number, prevent user input.
			if (isNaN(num) && !(this.isAllowedKey(key) && !numberRegex.test(num))) {
				e.preventDefault();
				e.stopPropagation();
			}
		}
	}

		getFeetValue(ftHeight) {
			const { value } = this.state;
			if(!value && !ftHeight) return value;
			if (ftHeight) return ftHeight;
			return value.split('.')[0];
		}

		getInchValue(inHeight) {
			const { value } = this.state;
			if(!value && !inHeight) return value; 
			if (inHeight) return inHeight;
			const firstDot = value.indexOf('.');
			return value.slice(firstDot + 1);
		}


	  render() {
		const { type, placeholder, width, label, id, error, isError, value, ftHeight, inHeight, subtitle } = this.props;

		if (type !== 'radio')
			return (
				<div className='input-static-container'>
					<p className='input-title'>{label}</p>
					{label === 'Height' ? (<div className='input-field-div'>
					<input placeholder={placeholder} id={`ft${label}`} className='input-type-area moz-input' type={"text"} onKeyDown={this.onkeydown} style={{ width: width }} onChange={this.onChange} value={this.getFeetValue(ftHeight)} />
                        <p className='input-field-label'>Ft.</p>
                        <input placeholder={placeholder} id={`in${label}`} className='input-type-area moz-input' type={"text"}  onKeyDown={this.onkeydown} style={{ width: width }} onChange={this.onChange} value={this.getInchValue(inHeight)} />
                        <p className='input-field-label'>In.</p>
					</div>)
						: (<div className="input-field-div"><input pattern={this.props.pattern} autoComplete="off" id={id} className='input-type-area' type={'text'} onKeyDown={this.onkeydown} placeholder={placeholder} style={{ width: width }} onChange={this.onChange} value={this.state.value}/>
							{subtitle && <p className="input-field-label">{subtitle}</p>}
							</div>)
					}
					{isError && error && <p className='input-error-message'>{error}</p>}
				</div>
			)
		else {
			return (
				<div className='input-static-container'>
					<p className='input-title'>{label}</p>
					<div className='input-field-div'>
						<label className="input-radio-label">Male
                           <input id={id} type={type} name="Gender" onChange={this.onChange} value='Male' checked={value === 'Male' ? "checked" : null} />
							<span className="input-radio-checkmark"></span>
						</label>
					</div>
					<div className='input-field-div'>
						<label className="input-radio-label">Female
                           <input id={id} type={type} name="Gender" onChange={this.onChange} value='Female' checked={value === 'Female'? "checked" : null} />
							<span className="input-radio-checkmark"></span>
						</label>
					</div>
					{isError && error && <p className='input-error-message'>{error}</p>}
				</div>

			)
		}
	}

}