import React from "react";
import "./MessageScreen.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setBreadCrumbs } from "../../redux/actions";
import { 
  getUid,
  prepareFormData,
  scrollUP,
  getResponseFromLocation, 
  parseResponse,
  fireVirtualPageEventIfNecessary,
  getQueryParamValue,
  redirectIfNeeded,
  saveException,
  getForMotivConfig 
} from '../../util/index'
import  LandingPage  from "../LandingPage/landingPage";
import { axiosInstance } from "../../util/axios";
import { PAGES_LIST } from '../../util/pages';


class MessageScreen extends React.Component {

  constructor(props){
    super(props);
    window.formotiv.init(getForMotivConfig(props.messageType));
    window.formotiv.submitFinal(getForMotivConfig(this.props.messageType));
  }

  state = {
    message: "",
    loading:true,
    leftTextHeading: "",
    leftTextDesc: "",
    leftTextTitle: "",
    selected:-1,

  };

  // call an api
    async componentDidMount() {
      this.props.setBreadCrumbs([])   
      scrollUP();
      try {
        this.props.getPageStatus && this.props.getPageStatus('loading')
        let res = await getResponseFromLocation(this.props);
        if(res) {
          const didRedirect = redirectIfNeeded(this.props, res, {});
          if (!didRedirect) {
            const { data, page_desc, other_params } = parseResponse(res);
            const { title, desc, heading } = page_desc;
            const { message } = data;
            let { virtual_page_view } = other_params;
            this.props.getPageStatus && this.props.getPageStatus(virtual_page_view)

            if (virtual_page_view) {
              virtual_page_view = virtual_page_view.toUpperCase();
              fireVirtualPageEventIfNecessary(
                PAGES_LIST[virtual_page_view],
                getUid(this.props.location.search),
                getQueryParamValue(this.props.location.search, "transaction_id")
              );
            }

            this.setState({
                leftTextTitle: title,
                leftTextDesc: desc,
                leftTextHeading: heading,
                message,
                loading: false
            });
          }
        } else {
          throw new Error ("Response not received.");
        }
      } catch (error) {
        console.error (error);
        saveException (error);
        this.props.history.push(`/error${this.props.location.search}`);
      }
      scrollUP();
  }

  renderLeftTextHelper = (leftTextHeading, leftTextTitle, leftTextDesc) => {
    return (
        <div>
            {
              leftTextHeading && <h5 className="left-text-heading">{leftTextHeading}</h5>
            }
            {/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
            <p className='global-left-panel-title' dangerouslySetInnerHTML={{ __html: leftTextTitle }} />
            <p 
                dangerouslySetInnerHTML={{ __html: leftTextDesc }}
            />
        </div>
    );
};

axiosCallHelper = async (uriPath) => {
    const completedQuestionsFormData = new FormData();
    const globalParams=Object.assign({ 'uid': getUid(this.props.location.search) })
    let formDataForCompeltedQues = {
      ...globalParams
    };
    prepareFormData(completedQuestionsFormData, formDataForCompeltedQues);
    return await axiosInstance.post(uriPath, completedQuestionsFormData);
  };

  render() {
      const {
          leftTextDesc,
          leftTextTitle,
          leftTextHeading,
          message,
          loading,
      } = this.state;
      const leftTextHTML = this.renderLeftTextHelper(leftTextHeading, leftTextTitle, leftTextDesc);
      return (
          <LandingPage leftText={leftTextHTML} loading={loading}>
              <div className='review-questions-area normalize-layout-right'>
                <div className='thankyou-message'>
                  <p dangerouslySetInnerHTML={{ __html: message }}/>
                </div>
              </div>
          </LandingPage>
      )
  }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setBreadCrumbs }, dispatch);
}

export default connect(null, mapDispatchToProps)(MessageScreen);
