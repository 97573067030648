import {combineReducers} from 'redux';
import landingPage from './landingPageReducer';
import quotePageReducers from './quotePageReducers';
import utilReducer from './utilReducers'
import premiumReducer from './premiumReducer';

export default combineReducers({
 landingPage,
 quotePageReducers,
 utilReducer,
 premiumReducer,
});
