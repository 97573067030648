import Slider from "react-rangeslider";
import "./slider.css";
import React from "react";
import { formatAmount } from '../../../util/index'
export default class RangeSlider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      amount: this.props.defaultValue
    };
  }
  componentDidUpdate(nextProps){
    if(nextProps.defaultValue!==this.props.defaultValue){
      this.setState({
      amount:this.props.defaultValue
    })
    }
  }

  handleOnChange = (value, event) => {
    // https://sureify.atlassian.net/browse/AAA-355
    if (this.props.id === "tenure" && this.props.skip && this.props.skip.indexOf(value) > -1) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.props.isLoading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    let amount;
    let step = value <= this.props.mid ? this.props.step : this.props.step2;

    if (value % step === 0) {
      amount = value;
    } else {
      amount = (value % step) + value;
    }

    // do not make changes if current and previous values are same.
    if (this.state.amount === amount) {
      return;
    }

    this.setState({ amount });
    if (event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 38 || event.keyCode === 40) {
      this.handleOnChangeComplete(amount);
    }
  }

  roundOff(value) {
    const rem = value % this.props.step;
    if (value <= this.props.mid || rem === 0) {
      return value;
    }
    return value + rem;
  }

  handleOnChangeComplete = (value,id) => {
    //ActivityLog.saveActivity("coverageAmountSlider");
    this.setState({ amount: value });   
    this.props.getSliderValue(value,id);
  };

  render() {
    let {min,max,id} =this.props      
    const formatCurrency = amount =>
      formatAmount(amount, 0, this.props.formatSymbol);
    const { amount } = this.state;
    const slider =
      ((this.props.min !== undefined) && (this.props.max !== undefined)) ? (
        <Slider
          min={min}
          max={max}
          value={amount}
          step={this.props.step ? this.props.step : 1}
          labels={
            this.props.horizontalLabels ? this.props.horizontalLabels : {}
          }
          orientation={this.props.orientation}
          format={formatCurrency}
          tooltip={false}
          onChange={this.handleOnChange}
          id={id}
          onChangeComplete={() => this.handleOnChangeComplete(amount, id)}
        />
      ) : (
          <div />
        );
    return (
      <div className="row slider-container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center padding-null">
          <span className="defaultValue"> {formatCurrency(amount)}</span>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center padding-null">
          {slider}
        </div>
      </div>
    )
  }
}