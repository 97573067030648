import React, { useEffect, useState } from "react";
import {
  getResponseFromLocation,
  redirectIfNeeded,
  parseResponse,
  getParams,
  saveException,
  scrollUP,
  axiosCallHelper,
  fireVirtualPageEventIfNecessary,
  getUid,
  getQueryParamValue,
  callQuestionsAPI,
  handleGtmQuestionsSubmission,
  getForMotivConfig
} from "../../util";
import { PAGES_LIST } from '../../util/pages';
import { LandingPage } from '..'
import { ButtonBlue, Tooltip } from '../../Components/UIElements'
import { setBreadCrumbs } from '../../redux/actions/index'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import isArray from "lodash/isArray";
import "./approved.scss";
import localDb from "../../util/local-db";
import paymentInfo from "../../util/payment-info";
import { get } from "lodash";
import { withLastLocation } from "react-router-last-location";

const initialState = {
  loading: true,
  questions: [],
  title: "",
  desc: "",
  heading: "",
  nextButtonText: "",
  prevButtonText: "",
  pageSequenceNumber: 0,
  premium: 0,
  term_length: 0,
  policy_number: 0,
  payment_url: "",
  success_url: "",
  error_url: "",
}

const renderLeftTextHelper = (leftTextHead, leftTextBody, leftTextTitle) => {
  return (
    <div>
      {/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
      <p className='global-left-panel-page-title' dangerouslySetInnerHTML={{ __html: leftTextTitle }} />
      <p className='global-left-panel-title' dangerouslySetInnerHTML={{ __html: leftTextHead }} />
      <p
        dangerouslySetInnerHTML={{ __html: leftTextBody }}
      />
    </div>
  );
};

const getValue = (list, key) => {
  const data = list.filter(item => item.question_id === key)[0];
  if (data) {
    return data.original_value;
  }
  return "";
}

const onPaymentClick = async (pageFlag, state, props) => {
  const formParam = { ...pageFlag }
  //  console.log(formParam,state.pageSequenceNumber,props.location.search)
  const response = await callQuestionsAPI(formParam, state.pageSequenceNumber, props.location.search);
  const didRedirect = redirectIfNeeded(props, response)
  if (!didRedirect) {
    fireVirtualPageEventIfNecessary(
      PAGES_LIST.PAYMENT_PAGE,
      getUid(props.location.search),
      getQueryParamValue(props.location.search, "transaction_id")
    );

    const { questionnaire, other_params} = parseResponse(response);
    const { questions } = questionnaire;
    const { payment_url, success_url, uid } = other_params;
    const cookieData = {
      term_length: getValue(questions, "Approved.TermLength"),
      premium: getValue(questions, "Approved.MonthlyPremiumRate"),
      policy_number: getValue(questions, "Approved.PolicyNumber"),
      payment_url,
      success_url,
      uid
    }
    setCookieAndRedirect(cookieData);
    return;
  }
}

const setCookieAndRedirect = (state) => {
  const { term_length, policy_number, premium, uid } = state
  let { successUrl, paymentUrl, domain } = paymentInfo();

  if (successUrl.match(/null/)) {
    successUrl = successUrl.replace('null', uid);
  }

  document.cookie = "aaa_pi =" + JSON.stringify({
    term_length,
    premium: premium,
    policy_number,
    url: successUrl,
  }) + `;path=/;domain=${domain};secure`;
  window.location.href = paymentUrl;
}

const getTooltipElement = (tooltip) => {
  return <div dangerouslySetInnerHTML={{ __html: tooltip }} />
}
const questionStructure = (questions) => {
  if (questions && isArray(questions)) {
    return questions.map(question => {
      const { question_type, is_hidden, question_text, response, tooltip, question_id, orientation } = question;
      switch (question_type) {
        case "html_template":
          if (is_hidden) {
            return null;
          }
          return (
            <div className={`html-data align-${orientation}`} key={question_id}>
              <div className='question-text'>
                <div dangerouslySetInnerHTML={{ __html: question_text }} />
                {
                  tooltip && <Tooltip value={getTooltipElement(tooltip)} id={question_id} />
                }
              </div>
              <p className='html-paragraph' dangerouslySetInnerHTML={{ __html: response }} />
            </div>
          );
        default:
          return null
      }
    });
  }
  return null;
}

function Approved(props) {
  const [state, setState] = useState(initialState);

  scrollUP();
  useEffect(() => {
    async function loadResponse() {
      setState({
        ...state,
        loading: true,
      });
      props.getPageStatus('loading')
      try {
        let response = null;
        const autoPayment = getParams("autopay");
        if (autoPayment) {
          response = await axiosCallHelper(props, "/questions");
        } else {
          response = await getResponseFromLocation(props);
        }

        if (response) {

          handleGtmQuestionsSubmission("[]");

          const { questionnaire, other_params, page_desc, breadcrumbs } = parseResponse(response);
          const { page_sequence_number, questions } = questionnaire;
          const { next_button_text, prev_button_text, payment_url, success_url, uid, current_status } = other_params;
          props.getPageStatus(current_status)

          if (autoPayment) {
            const cookieData = {
              term_length: getValue(questions, "Approved.TermLength"),
              premium: getValue(questions, "Approved.MonthlyPremiumRate"),
              policy_number: getValue(questions, "Approved.PolicyNumber"),
              payment_url,
              success_url,
              uid
            }
            setCookieAndRedirect(cookieData);
            return;
          }
          const didRedirect = redirectIfNeeded(props, response);

          if (!didRedirect) {
            const { title, desc, heading } = page_desc;

            setState({
              ...state,
              loading: false,
              questions,
              title,
              desc,
              heading,
              nextButtonText: next_button_text,
              prevButtonText: prev_button_text,
              pageSequenceNumber: page_sequence_number,
              term_length: getValue(questions, "Approved.TermLength"),
              premium: getValue(questions, "Approved.MonthlyPremiumRate"),
              policy_number: getValue(questions, "Approved.PolicyNumber"),
              payment_url,
              success_url,
            });
          }
          if (breadcrumbs.length) {
            props.setBreadCrumbs(breadcrumbs);
          }
        } else {
          setState({
            loading: false,
          });
          throw new Error("Response not received.")
        }
      } catch (error) {
        setState({
          ...state,
          loading: false,
        });
        saveException(error);
        props.history.push(`/error${props.location.search}`);
      }
    }

    async function makeApiCall(){
      setState({
        ...state,
        loading: true,
      });
      let response
      try {
      response = await axiosCallHelper(props, "/questions");
      const didRedirect = redirectIfNeeded(props, response);
      if(didRedirect){
        return
      }
      const { questionnaire, other_params, page_desc, breadcrumbs } = parseResponse(response);
      const { page_sequence_number, questions } = questionnaire;
      const { next_button_text, prev_button_text, payment_url, success_url, uid, current_status } = other_params;
      props.getPageStatus(current_status)
      const { title, desc, heading } = page_desc;

            setState({
              ...state,
              loading: false,
              questions,
              title,
              desc,
              heading,
              nextButtonText: next_button_text,
              prevButtonText: prev_button_text,
              pageSequenceNumber: page_sequence_number,
              term_length: getValue(questions, "Approved.TermLength"),
              premium: getValue(questions, "Approved.MonthlyPremiumRate"),
              policy_number: getValue(questions, "Approved.PolicyNumber"),
              payment_url,
              success_url,
            });
          if (breadcrumbs.length) {
            props.setBreadCrumbs(breadcrumbs);
          }
         else {
          setState({
            loading: false,
          });
          throw new Error("Response not received.")
        }
        
      } catch (error) {
        setState({
          ...state,
          loading: false,
        });
        saveException(error);
        props.history.push(`/error${props.location.search}`);
      }
    }

    // this is congratulations for approved policy
    fireVirtualPageEventIfNecessary(
      PAGES_LIST.THANKYOU_PAGE,
      getUid(props.location && props.location.search),
      getQueryParamValue(props.location && props.location.search, "transaction_id")
    );

    localDb.destroyLocalDb(props.location.search);
    if(isBackToApprovedPage()){
      makeApiCall()
    }
    loadResponse();
    scrollUP();
    window.formotiv.init(getForMotivConfig("Approved"));
  }, []);

  const isBackToApprovedPage =()=> {
    const historyAction = get(props, "history.action", "");
    return historyAction === "POP" && props.lastLocation !== null;
  }

  return (
    <LandingPage loading={state.loading} leftText={renderLeftTextHelper(state.title, state.desc, state.heading)}>
      <div className='normalize-layout-right approved-questions-wrapper'>
        {questionStructure(state.questions)}
      </div>
      <div className={"global-button-margin normalize-layout-right review-question-margin"}>
        <div className={"global-button-wrapper"}>
          <ButtonBlue
            label={state.nextButtonText}
            btnType='pill'
            btnClickHandler={() => {
              window.formotiv.submit(getForMotivConfig("Approved"));
              setState({ loading: true })
              onPaymentClick({ "next_page_flag": 1 }, state, props);
            }}
          />
        </div>
      </div>
    </LandingPage>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setBreadCrumbs }, dispatch);
}

export default withLastLocation(connect(null, mapDispatchToProps)(Approved));
