import React, { useState, useEffect, Component } from "react"
import { LandingPage } from "..";
import { ButtonBlue } from '../../Components/UIElements/index';
import { setBreadCrumbs } from '../../redux/actions/index'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './interstatial.css';
import {
	scrollUP,
	callQuestionsAPI,
	redirectIfNeeded,
	saveException,
	fireVirtualPageEventIfNecessary,
	getUid,
	getQueryParamValue,
	getForMotivConfig
} from "../../util";
import { PAGES_LIST } from '../../util/pages';
import localDb from "../../util/local-db";

const leftText = (
	<div>
		<p className='global-left-panel-title' dangerouslySetInnerHTML={{ __html: 'Ready to get started?' }} />
		<p
			dangerouslySetInnerHTML={{
				__html: `<span class='global-left-panel-content'>
    <p class='left-content-wrap'>
        You’re only 10 minutes away from submitting your life insurance application.
    </p>
    <div class='padding-left-20'>
        <p class='left-content-wrap'>
        To complete your application, you’ll need to know your:
    </p>
    <ul class="left-content-list">
        <li>AAA Membership number (if applicable)</li>
        <li>Driver’s license or government ID number</li>
        <li>Personal medical history</li>
        <li>Social Security Number</li>
    </ul>
    </div>
</span>`
			}}
		/>
	</div>
);

class Interstatial extends Component {
	constructor(props) {
    	super(props);
    	this.state = {
    		loading: false
	    };
		window.formotiv.init(getForMotivConfig("Ready to get started?"));
  	}
  	componentDidMount() { 
  		scrollUP();
		this.props.setBreadCrumbs([]);
		fireVirtualPageEventIfNecessary(
			PAGES_LIST.INTERSTATIAL_PAGE,
			getUid(this.props.location.search),
			getQueryParamValue(this.props.location.search, "transaction_id")
		);
	}
	componentDidUpdate() {
		scrollUP();
	}
	componentWillUnmount() {
		scrollUP();
	}
	startApplication = async () => {
		scrollUP();
		try {
			this.setState({
				loading: true
			});
			const response = await callQuestionsAPI({}, "1", this.props.location.search);
			const didRedirect = redirectIfNeeded(this.props, response, {
				fromReview: true,	// prevents one more /questins api call in questions component.
			});

			if (!didRedirect) {
				this.setState({
					loading: false
				});
				throw new Error("Response for questions API was not received.");
			}
		} catch (error) {
			saveException(error);
		}
	}
	render() {
		return (
			<LandingPage loading={this.state.loading} leftText={leftText}>
					<div className='content-wrapper'>
						<div className='normalize-layout-right'>
							<div className='interstital-wrapper'>
								<p className="interstatial-covid19-message">COVID-19 Customer Information: <a target="_blank" href="https://www.aaalife.com/coronavirus-updates">CLICK HERE ></a></p>
								<p className='interstatial-title-wrapper'>Complete your application in four simple steps:</p>
								<ol className='interstatial-ordered-list'>
									<li>Enter some information about yourself, your health, and your lifestyle.</li>
									<li>Choose who you’d like to receive the funds if you passed away (also known as a beneficiary).</li>
									<li>Review and sign your application.</li>
									<li>Provide your payment information.</li>
								</ol>
								<p className='interstatial-conclusion'>After you submit your application, we’ll review the information you provided in less than two minutes. Some applicants may need to provide additional information. If necessary, you will be contacted by call, or email to complete a short health screening.</p>
							</div>
						</div>
						<div className={"global-button-margin normalize-layout-right"}>
							<div className={"global-button-wrapper"}>
								<ButtonBlue
									label={"Back"}
									btnType='link'
									btnClickHandler={() => {
										this.props.history.push(`/quote${this.props.location.search}`);
									}}
								/>
								<ButtonBlue
									label={"Agree and continue"}
									btnType='pill'
									btnClickHandler={() => {
										this.startApplication()
										window.formotiv.submit(getForMotivConfig("Ready to get started?"))
									}}
								/>
							</div>
						</div>
					</div>
			</LandingPage>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setBreadCrumbs }, dispatch);
}


export default connect(null, mapDispatchToProps)(Interstatial);
