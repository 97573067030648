const PAGES_LIST = {
  "LANDING_PAGE": {
    "page_id": 'et2-start',
    "page_title": "et2/"
  },
  "PAYMENT_PAGE": {
    "page_id": "et2-app-payment",
    "page_title": "et2/app/payment"
  },
  "QUOTE_PAGE": {
    "page_id": "et2-quote",
    "page_title": "et2/quote"
  },
  "INTERSTATIAL_PAGE": {
    "page_id": "et2-app-interstitial",
    "page_title": "et2/app/interstitial"
  },
  "QUESTIONS_PAGE": {
    "page_id": "et2-questions",
    "page_title": "et2/questions"
  },
  "ERROR_PAGE": {
    "page_id": "et2-error",
    "page_title": "et2/error"
  },
  "REVIEW_PAGE": {
    "page_id": "et2-app-review-application-review",
    "page_title": "et2/app/review/application-review"
  },
  "THANKYOU_PAGE": {
    "page_id": "et2-app-congratulations",
    "page_title": "et2/app/congratulations"
  },
  "COMPLETED_PAGE": {
    "page_id": "et2-app-complete",
    "page_title": "et2/app/complete"
  },
  "SIGNATURE": {
    "page_id": "et2-app-signature",
    "page_title": "et2/app/signature"
  },
  "COVERED": {
    "page_id": "et2-app-you_are_covered",
    "page_title": "et2/app/you_are_covered",
  },
  "DECLINED": {
    "page_id": "et2-app-declined",
    "page_title": "et2/app/declined"
  },
  "HEALTH_SCREENING": {
    "page_id": "et2-app-health_screening",
    "page_title": "et2/app/health_screening"
  },
  "SYSTEM_ERROR": {
    "page_id": "et2-app-system_error",
    "page_title": "et2/app/system_error"
  },
  "PROCESSING": {
    "page_id": "et2-app-processing",
    "page_title": "et2/app/processing"
  },
  "UNABLE_TO_FINISH": {
    "page_id": "et2-app-unable_to_finish",
    "page_title": "et2/app/unable_to_finish"
  },
  "SYSTEM_UNAVAILABLE": {
    "page_id": "et2-system_unavailable",
    "page_title": "et2/system_unavailable"
  },
  "APP_SYSTEM_UNAVAILABLE": {
    "page_id": "et2-app-system_unavailable",
    "page_title": "et2/app/system_unavailable"
  },
  "CONTINUE_CODE_SUBMIT": {
    "page_id": "et2-app-continue_code_submit",
    "page_title": "et2/app/continue_code_submit"
  },
  "CONTINUE_APP_VERIFICATION": {
    "page_id": "et2-app-continue_app_verification",
    "page_title": "et2/app/continue_app_verification"
  },
  "HEALTH_SCREENING_YES":{
    "page_id": "et2-app-health_screening/yes_paramed",
    "page_title": "et2/app/health_screening/yes_paramed"
  },
  "HEALTH_SCREENING_NO":{
    "page_id": "et2-app-health_screening/no_paramed",
    "page_title": "et2/app/health_screening/no_paramed"
  },
  "HEALTH_SCREENING_NO_OFFERS":{
    "page_id": "et2-app-health_screening/no_paramed_yes_offers",
    "page_title": "et2/app/health_screening/no_paramed_yes_offers"
  },
  "HEALTH_SCREENING_ABANDONED":{
    "page_id": "et2-app-health_screening/time_out",
    "page_title": "et2/app/health_screening/time_out"
  },

}
export { PAGES_LIST };
