import {
  SET_COVERAGE_AMOUNT,
  SET_PLAN_ID,
  UPDATE_TERM_LENGTH_OPTIONS,
  GET_DETAILS_SUCCESS,
  SET_SELECTED,
  GET_QUOTE_DATA_SUCCESS,
  UPDATE_PREMIMUM_PAGE_ERROR
} from "../types";
import { getPlanId, getDefaultPlanId } from "../../util";

const defaultCoverageAmount = {
  currency_symbol: "$",
  default_value: "25000",
  max_value: "500000",
  min_value: "25000",
  step_length: "25000",
}

const initialState = {
  selectedCoverageAmount: '',
  selectedPlanId: '',
  termLengths: [],
  coverageAmount: {
    "title": "Coverage amount",
    "coverage_ranges": [
      {
        "min_value": "0",
        "max_value": "0",
        "step_length": "0"
      },
      {
        "min_value": "0",
        "max_value": "0",
        "step_length": "0"
      }
    ],
    "currency_symbol": "$"
  },
  premiumError: false,
  errorMessage: ''
};

const defaultPlanId = (termLength) => {
  if (termLength === "") {
    return "";
  }
  return getPlanId(termLength);
}

const checkOption = (option, planList) => {
  return planList.filter(plan => plan.term_length === getDefaultPlanId(option)).length;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED:
      return {
        ...state,
        [`selected${action.key}`]: action.value
      };
    case UPDATE_TERM_LENGTH_OPTIONS:
      return {
        ...state,
        termLengths: [...action.payload.termLength],
        coverageAmount: { ...action.payload.coverageAmount },
        selectedCoverageAmount: (state.selectedCoverageAmount || action.payload.defaultCoverageAmount),
        selectedPlanId: action.payload.quoteResponseLoaded && checkOption(state.selectedPlanId, [...action.payload.termLength])  ? state.selectedPlanId : defaultPlanId([action.payload.defaultTermLength])
      };

    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        selectedPlanId: action.payload.plan_id,
      }
    case GET_QUOTE_DATA_SUCCESS:
      return {
        ...state,
        coverageAmount: action.payload.coverageAmount,
      };
    case UPDATE_PREMIMUM_PAGE_ERROR:
      return {
        ...state,
        premiumError: action.payload.data.errors && action.payload.data.errors.userMessage !== '',
        errorMessage: action.payload.data.errors && action.payload.data.errors.userMessage
      }
    default:
      return state;
  }
}
