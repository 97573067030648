import React from "react";
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import App from "./App";
import io from "socket.io-client";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import findLast from "lodash/findLast";
import moment from "moment";
import momenttz from "moment-timezone";
import originConfig from "./util/config";

import { getParams, getRandomValue } from "./util";

export let currentSocket = null;
class HealthCheck extends React.PureComponent {
  constructor(props) {
    super(props);
    this.healthCheckInitiated = false;
    this.uid = "";
    this.excludePages = ["timeout", "authcode"];
    this._connectSocket = this._connectSocket.bind(this);
  }

  _shouldStartSocketConnection() {
    const currentPath = window.location.pathname.replace("/", "");
    if (currentPath === "") {
      return true;
    }
    return !this.excludePages.filter(pagePath => pagePath.match(currentPath)).length;
  }

  componentDidMount() {
    const { uid, sessionId, sessionType } = this._getIds();

    if (uid || sessionId) {
      this.uid = uid || sessionId;
      this.sessionType = sessionType;
      if (this._shouldStartSocketConnection()) {
        this._connectSocket.call(this);
        this.healthCheckInitiated = true;
        console.log("HEALTH CHECK::: Initiating Health Check in Component Did Mount.");
        console.log("HEALTH CHECK::: UID: ", this.uid);
      }
    }
    if (this.healthCheckInitiated) {
      this._emitUpdatePageVisits(this.props.location.pathname.match(/interstatial/));
    }
  }

  componentDidUpdate(prevProps) {
    const { uid, sessionId, sessionType } = this._getIds();
    if (!this.healthCheckInitiated && (uid || sessionId)) {
      this.uid = uid || sessionId;
      this.sessionType = sessionType;
      if (this._shouldStartSocketConnection()) {
        this._connectSocket.call(this);
        this.healthCheckInitiated = true;
        console.log("HEALTH CHECK::: Initiating Health Check in Component Did Update.");
        console.log("HEALTH CHECK::: UID: ", this.uid);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.fakeState !== nextProps.fakeState && this.healthCheckInitiated) {
      this._emitUpdatePageVisits(nextProps.location.pathname.match(/interstatial/));
    }
  }

  _emitUpdatePageVisits (isInterstitialPage) {
    this.socket.emit("UPDATE_PAGE_VISITS",  JSON.stringify({
      uid: this.uid,
      last_visited_page: findLast(window.initialTagManager, function (e) {
        return e;
      }),
      tag_manager: uniqBy(window.initialTagManager, function (e) {
        return e.page_id;
      }),
      isInterstitialPage: !!(isInterstitialPage)
    }));
  }
  _getIds() {
    const { location } = this.props;
    let uid = "", sessionId = "", sessionType = "";
    if (location.search.match(/uid/)) {
      uid = getParams('uid');
      sessionType = "uid";
    }
    if (location.search.match(/session_id/)) {
      sessionId = getParams('session_id');
      sessionType = "session_id";
    }
    return {
      uid,
      sessionId,
      sessionType,
    }
  }

  _getUserData () {
    return {
      userAgent: navigator.userAgent,
      sourcePage: window.location.pathname,
      sourceUrl: window.location.search,
      userTimeZone: moment(),
      currentDateTime: momenttz.tz.guess(),
    }
  }

  _connectSocket(_this) {
    let config = originConfig[process.env.REACT_APP_NODE_ENV||"development"];
    console.log("Current Socket available before creating new socket connection?");
    console.log(process.env.REACT_APP_NODE_ENV, config.WS_BASE_URL);
    this.socket = io.connect(config.WS_BASE_URL, {
      query: {
        ...this._getUserData(),
        uid: this.uid,
        sessionType: this.sessionType
      },
      reconnection: true
    });
    this.socket.on('disconnected', function(s) {
      this.socket.emit("DISCONNECTED", {data: '12345'});
    })
    console.log("Connecting : ", config.WS_BASE_URL);
    this.socket.emit('START_SESSION', JSON.stringify({
      ...this._getUserData(),
      uid: this.uid,
      sessionType: this.sessionType
    }));
    this.socket.on('reconnect', () => {
      console.log("Reconnecting : ", config.WS_BASE_URL);
      this.socket.emit('START_SESSION', JSON.stringify({
        ...this._getUserData(),
        uid: this.uid,
        sessionType: this.sessionType,
        reconnect: true
      }))
    });
    this.socket.on("PING_EXPIRE_SESSION", (data) => {
      console.log("HEALTH CHECK::: SOCKET Data received", JSON.stringify(data));
      const uid = getParams('uid');
      const sessionId = getParams('session_id')
      if (uid === data.uid || sessionId === data.uid) {
        const currentStatus = get(data, "res.response.other_params.current_status", "");
        const pathname = window.location.pathname;
        if (pathname && currentStatus && pathname.toLowerCase().indexOf(currentStatus) < 0) {
          let searchParam = "";
          if (uid) {
            searchParam = `?uid=${uid}`;
          } else {
            searchParam = `?session_id=${sessionId}`
          }
          this.socket.emit("PONG_EXPIRE_SESSION", JSON.stringify({
            uid: this.uid,
            searchParam,
            ...this._getUserData(),
          }));
          this.props.history.push(`/${currentStatus}${searchParam}`, {
            response: {
              data: data.res
            },
            skipApiCall: true,
          })
        }
      }
    });
    console.log('Creating socket connection.');
  }

  render () {
    return (<App {...this.props}/>)
  }
}

const mapStateToProps = (state) => ({
  fakeState: state.utilReducer ? state.utilReducer.fakeState : getRandomValue()
});

const HC = connect(mapStateToProps, null)(HealthCheck);

export default withRouter(HC);
