import React, { Component } from 'react'
import { LandingPage } from '..'
import { ButtonBlue } from '../../Components/UIElements'
import {
	saveException,
	getUid,
	prepareFormData,
	parseResponse,
	getResponseFromLocation,
	callQuestionsAPI,
	redirectIfNeeded,
	fireVirtualPageEventIfNecessary,
	getQueryParamValue,
	axiosCallHelper,
	scrollUP,
	handleGtmVirtalPageVisits,
	getForMotivConfig
} from '../../util/index'
import { axiosInstance } from "../../util/axios";
import { setBreadCrumbs } from '../../redux/actions/index'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import Checkbox from "../../Components/UIElements/Checkbox/index";
import isObject from "lodash/isObject";
import './sign.css';
import { PAGES_LIST } from '../../util/pages';
import get from "lodash/get";
import { withLastLocation } from 'react-router-last-location';

class Sign extends Component {

	constructor(props){
		super(props);
		window.formotiv.init(getForMotivConfig("Signature"));
	}

	state = {
		loading: true,
		questions: [],
		checkboxValues: [],
		isChecked: false,
		isAllBoxChecked: [],
		doEnable: false,
		nextButtonText: "",
		isAuthCodePage: false,
		text:'',
		isClicked:false
	}

	async componentDidMount() {
		scrollUP()
		//making mandatory questions api call to make sure to route to completed page when clicked on browser back
		// adding check for authocode & app-system-unavailable, in these case, next_page_flag should be 1.
		const params= (this.props.location.pathname === "/authcode" || this.props.location.pathname === "/app-system-unavailable")
									? {"next_page_flag":"1"} : null;
		const currentState = get(this, "props.location.state", {});
		const { skipApiCall, response } = currentState;
		let res = null;
		// make api call irrespective of page if landed from back button
		if(this.isBackToSignPage()  ) {
			res = await axiosCallHelper(this.props, "/questions",'',params)
		}
		// skips api call for app-system-unavailable page
		else if ((skipApiCall  || this.props.location.pathname === "/app-system-unavailable") && response) {
			res = response
		}
		else{
			res = await axiosCallHelper(this.props, "/questions",'',params)
		}

		const { questionnaire, other_params, page_desc, breadcrumbs } = parseResponse(res);
		this.props.setBreadCrumbs(breadcrumbs || []);
		try {
			if(res) {
			const didRedirect = redirectIfNeeded(this.props, res);
			if (!didRedirect) {
				let { virtual_page_view } = other_params;
				if (virtual_page_view) {
					virtual_page_view = virtual_page_view.toUpperCase();
					fireVirtualPageEventIfNecessary(
					PAGES_LIST[virtual_page_view],
					getUid(this.props.location.search),
					getQueryParamValue(this.props.location.search, "transaction_id")
				);
				}

				const { page_sequence_number, questions } = questionnaire;
				const { title, desc, heading } = page_desc;
				const { current_status: currentStatus,
						next_button_text, 
						next_button_url,
						prev_button_text, 
						next_button_action,
						is_next_btn_hidden,
						is_prev_btn_hidden } = other_params;
				handleGtmVirtalPageVisits(questions);
				this.setState({
					leftTextHead: title,
					leftTextBody: desc,
					leftTextTitle: heading,
					questions,
					loading: false,
					nextButtonText: next_button_text,
					prevButtonText: prev_button_text,
					nextButtonDisable: is_next_btn_hidden,
					prevButtonDisable: is_prev_btn_hidden,
					nextButtonAction: next_button_action,
					nextButtonUrl: next_button_url,
					page_sequence_number,
					isAuthCodePage: currentStatus === "app-system-unavailable",
					isTimeOutPage: currentStatus === "timeout"
				});
			}
		} else {
			throw new Error ("Response not received.");
		}
   	  } catch(error){
			console.error (error);
			saveException (error);
			this.props.history.push(`/error${this.props.location.search}`);
		}
	}

	isBackToSignPage =()=> {
		const historyAction = get(this.props, "history.action", "");
		return historyAction === "POP" && this.props.lastLocation !== null;
	  }

	converToArrayIfRequired = (questions) => {
		if (isObject(questions)) {
			return Object.keys(questions).reduce((acc, key) => {
				acc.push(questions[key]);
				return acc;
			}, [])
		}
		return questions;
	}

	handleCheckboxChange = (selectedValue, event) => {
		let isActive = event.target.checked ? true : false
		let checkBoxStatus = this.state.isAllBoxChecked
		checkBoxStatus[parseInt(event.target.id)] = isActive
		this.setState({
			[event.target.id]: isActive,
			isAllBoxChecked: checkBoxStatus,
			doEnable: checkBoxStatus.indexOf(false) >= 0 ? false : true
		})
	};


	questionStructure = (questions) => {
		questions = this.converToArrayIfRequired(questions);
		if (questions && Array.isArray(questions)) {
			return questions.map((question, index) => {
				const { question_type, question_text, response, response_options, question_id,validations } = question
				switch (question_type) {
					case 'html_template':
						return (
							<div key={question_id} className='html-data'>
								<div dangerouslySetInnerHTML={{ __html: question_text }} />
								<p className='html-paragraph' dangerouslySetInnerHTML={{ __html: response }} />
							</div>
						)
					case 'multiselection': if (question.display_type === 'checkbox') {
						return (
							<div key={question_id}>
								{
									response_options.map((item, index) => (
										<Checkbox
											key={question_id}
											className="checkbox-checkmark"
											value={item.id}
											checkboxValue={item.id}
											handleCheckboxChange={this.handleCheckboxChange}
											checked={this.state.id}
											id={index}
											rightTxt={item.label}
											formotivURI={"Signature"}
											checkboxName={`${question_id}_checkbox_${index}`}
										/>
									))
								}
								{this.state.isClicked && !this.state.doEnable ? <p style={{ color: "red", fontSize: 12 }}>{validations.required.message}</p> : null}
							</div>
						);
					}
					else {
						return null;
					}
					default: return null;
				}
			})
		}
	}

	axiosCallHelper = async (uriPath, q_id) => {
		const completedQuestionsFormData = new FormData();
		//const globalParams = getGlobalParams(this.props);
		const globalParams = Object.assign({ 'uid': getUid(this.props.location.search) })
		let formDataForCompeltedQues = {
			...globalParams,
			...q_id
		};
		prepareFormData(completedQuestionsFormData, formDataForCompeltedQues);
		return await axiosInstance.post(uriPath, completedQuestionsFormData);
	};

	renderLeftTextHelper = (leftTextHead, leftTextBody, leftTextTitle) => {
		return (
			<div>
				{/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
				<p className='global-left-panel-page-title' dangerouslySetInnerHTML={{ __html: leftTextTitle }} />
				<p className='global-left-panel-title' dangerouslySetInnerHTML={{ __html: leftTextHead }} />
				<p
					dangerouslySetInnerHTML={{ __html: leftTextBody }}
				/>
			</div>
		);
	};

	checkCheckBoxClick = () => {

	}

	handleBackClick = async () => {
		this.onButtonClick({
			"prev_page_flag": 1
		}, { fromReview: true });
	}

	handleBtnClick = () => {
		scrollUP();
		window.formotiv.submit(getForMotivConfig("Signature"));
		const currentStatus = get(this, "props.location.pathname", "");
		this.setState({
			isClicked:true
		})
		if (currentStatus.match(/signature/)) {
			fireVirtualPageEventIfNecessary(
				PAGES_LIST.PROCESSING,
				getUid(this.props.location.search),
				getQueryParamValue(this.props.location.search, "transaction_id")
			);
			if(this.state.doEnable){
				this.setState({
					text : 'We are processing your application. This may take up to 2 minutes.',
				})
				this.onButtonClick({
					"next_page_flag": 1,
					"signature_completed": true,
				}, null).then(()=>{
				});
			}
		}else{
			this.onButtonClick({
				"next_page_flag": 1,
			}, null).then(()=>{});
		}
	}

	onButtonClick = async (pageFlag, stateParams) => {
		try {
			this.setState({
				loading: true,
			});

			let formParams = { ...pageFlag };

			if (this.state.isAuthCodePage || (!this.state.nextButtonAction && this.state.isTimeOutPage)) {
				formParams = {
					"regenerate_session": "true"
				}
			}
			if (this.props.location.pathname.match(/system-unavailable/)) {
				formParams = {
					...formParams,
					"life_plus": "true"
				}
			}

			if (this.state.isTimeOutPage && this.state.nextButtonAction) {
				const referrer = document.referrer.indexOf(window.location.origin)>-1 ? '' : document.referrer
				const redirectionUrl = referrer || window.location.origin
				window.location.href = this.state.nextButtonUrl ? this.state.nextButtonUrl : redirectionUrl;
				this.setState({
					loading: true,
				});
				return
			}

			const response = await callQuestionsAPI(
					formParams,
					this.state.page_sequence_number,
					this.props.location.search
				);

			// handleBackClick should redirect the user to questions page
			if (response) {
				const didRedirect = redirectIfNeeded(this.props, response, stateParams);
				if (!didRedirect) {
					this.setState({ loading: false });
				}
			} else {
				throw new Error("Issue with API call in handleBackClick, review.js")
			}
			
		} catch (error) {
			this.setState({ loading: false });
			console.error("Error, ", error);
		}
	}

	render() {
		let {
			loading,
			questions,
			leftTextHead,
			leftTextTitle,
			leftTextBody,
			doEnable,
			nextButtonText,
			isAuthCodePage,
			nextButtonDisable,
			prevButtonDisable,
			text
		} = this.state
		// if (isAuthCodePage || !nextButtonDisable) {
		// 	doEnable = true;
		// }
		const leftTextHTML = this.renderLeftTextHelper(leftTextHead, leftTextBody, leftTextTitle);
		return (
			<LandingPage leftText={leftTextHTML} loading={loading} text={text} >
				<div className='normalize-layout-right'>
					{this.questionStructure(questions)}
				</div>
				<div className={"global-button-margin normalize-layout-right review-question-margin"}>
					<div className={"global-button-wrapper"}>
						{
							!this.state.isAuthCodePage && !this.state.prevButtonDisable &&
							<ButtonBlue
								label={"Back"}
								btnType={prevButtonDisable ? "" : 'link'}
								btnClickHandler={this.handleBackClick}
								disabled={prevButtonDisable ? prevButtonDisable : false}
							/>
						}
						{
							!this.state.nextButtonDisable && 
							<ButtonBlue
								label={nextButtonText}
								btnType='pill'
								btnClickHandler={this.handleBtnClick}
								// disabled={!doEnable}
							/>
						}
					</div>
				</div>
			</LandingPage>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setBreadCrumbs }, dispatch);
}

export default withLastLocation(connect(null, mapDispatchToProps)(Sign));
