/* eslint-disable no-mixed-operators */
import React, { Component } from "react";
import { LandingPage } from '..'
import './campaign.css';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import moment from "moment";
import * as utils from '../../util/index';
import { PAGES_LIST } from '../../util/pages';
import {
	ButtonBlue,
	ErrorPopup
} from '../../Components/UIElements/index';
import {
	getUid,
	getSessionId,
	scrollUP,
	forceScroll,
	getPlanId,
	getForMotivConfig
} from '../../util/index';
import {
	addDetails,
	getDetails,
	setBreadCrumbs,
	clearError,
	getTermLength,
	sendQuoteRequest,
	setUid,
	setSessionId,
	isCampaignMounted
} from '../../redux/actions'
import FormFields from "./FormFields";
import { HELPER } from "./helper";

forceScroll()

class Campaign extends Component {

	constructor(props) {
		super(props)
		this.myRef = React.createRef()
		this.scrollPage = false;
		this.state = {
			ftHeight: '',
			inHeight: '',
			zipcode: '',
			is_member: '',
			gender: '',
			dob: '',
			replace_existing_insurance: '',
			weight: '',
			nicotine_use: '',
			ftHeightError: false,
			weightError: false,
			zipCodeError: false,
			memberCodeError: false,
			dobError: false,
			genderError: false,
			replacePolicyError: false,
			nicotineError: false,
			isPageLevelError: false,
			error: false,
			errorTitle: '',
			errorMessage: '',
			isZipError: false,
			email: '',
			mailError: '',
			plan_id: '',
			coverage_amount: '',
			height:''
		}
		window.formotiv.init(getForMotivConfig("Affordable coverage. Convenient online application."));
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isError) {
			this.props.history.push(`/error${this.props.location.search}`);
		}
	}

	async componentDidMount() {
		this.props.setBreadCrumbs([])
		this.props.isCampaignMounted(true);
		utils.fireVirtualPageEventIfNecessary(
			PAGES_LIST.LANDING_PAGE
		);
		scrollUP()
		try {
			if (this.props.location.search) {
				const { push } = this.props.history
				const uid = getUid(this.props.location.search);
				const session_id = getSessionId(this.props.location.search);
				if(uid || session_id) {
					let data = Object.assign({ 'uid': uid })
					this.props.setUid(uid);
					this.props.setSessionId(session_id);
					this.props.getDetails(data, push)
				}
			}
		} catch (e) {
			utils.saveException({ apiName: '/v1/quote/premium', message: e });
			console.log('Campaign-Error', e);
		}
	}

	async componentDidUpdate(prevProps) {
		try {
			const uid = getUid(this.props.location.search);
			const session_id = getSessionId(this.props.location.search);
			if ((uid || session_id) && this.props.quoteData !== prevProps.quoteData) {
				let {
					dob,
					gender,
					is_member,
					height,
					nicotine_use,
					replace_existing_insurance,
					zipcode,
					weight,
					email
				} = this.props.quoteData;
				let date = dob;
				if (date) {
					date = moment(moment(dob).format('MM/DD/YYYY')).toDate();
					date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
				}

				if (dob) {
					this.props.getTermLength({ dob, is_nicotine_user: (nicotine_use === 'LessThan12MonthsAgo') ? "true" : "false", }, this.props.history.push)
				}
				const heights = height.toString().split(".");
				const ftHeight = heights[0] || "";
				const inHeight = heights[1] || "";
				this.setState({
					dob: date,
					gender,
					is_member,
					ftHeight,
					inHeight,
					nicotine_use,
					replace_existing_insurance,
					zipcode,
					weight,
					email,
					height: height
				})
			}

			if (this.props.zipnum !== prevProps.zipnum) {
				let { zipError, zipErrorInfo } = this.props
				let title = zipErrorInfo.split('.')[0]
				let message = zipErrorInfo.replace(title + '.', '')
				if (zipError) {
					this.scrollPage = true;
					this.setState({
						isZipError: zipError,
						errorTitle: title,
						errorMessage: message
					})
				}
				else {
					this.setState({
						isZipError: zipError
					})
				}
			}

			if (this.props.backendError && prevProps.backendError !== this.props.backendError) {
				let { backendError } = this.props
				let title = backendError.split('.')[0]
				let message = backendError.replace(title + '.', '')
				this.setState({
					errorTitle: title,
					errorMessage: message,
					isPageLevelError: true
				})
				scrollUP()
			}

			if (this.props.feildError !== {} && prevProps.feildError !== this.props.feildError) {
				scrollUP()
			}

			// if landingPageReducer zip error is true &&
			// if component state's zip error is true &&
			// if zip code's value in input box is empty,
			// clear top level zip error
			if (this.props.zipError && this.state.isZipError && !this.state.zipcode) {
				this.props.clearError();

			}
		} catch (e) {
			utils.saveException({ apiName: 'campaign/componentDidUpdate', message: e });
			console.log('DidUpdateError', e);
		}
	}

	handleBtnClick = () => {
		scrollUP()
		const {
			zipcode,
			dob,
			gender,
			is_member,
			ftHeight,
			inHeight,
			replace_existing_insurance,
			weight,
			nicotine_use,
			email,
			plan_id,
			coverage_amount,
		} = this.state;

		let height = '';
		if (ftHeight === '' && inHeight === '') {
			height = '';
		} else {
			height = `${ftHeight}.${inHeight}`;
		}

		let basicInfo = {
			zipcode,
			dob,
			gender,
			is_member,
			height,
			replace_existing_insurance,
			nicotine_use,
			is_nicotine_user: nicotine_use === "" ? "" : (nicotine_use === 'LessThan12MonthsAgo' ? "true" : "false"),
			weight,
			email,
			plan_id: this.props.plan_id,
			coverage_amount: this.props.coverage_amount,
		}

		try {
			const { push } = this.props.history;
			const search = this.props.location.search;
			const uid = getUid(search);
			if (uid) {
				Object.assign(basicInfo, { 'uid': uid })
			}
			this.props.addDetails(basicInfo, push, search)
			window.formotiv.submit(getForMotivConfig("Affordable coverage. Convenient online application."));
		} catch (e) {
			utils.saveException({ apiName: "camaign(handleButtonClick)", message: e });
			console.log('handleClickError', e);
		}
	}

	handleInput = (e) => {
		let {dob, nicotine_use} = this.state
		let {id, value} = e.target;
		
		e.target.error = id + "Error";

	
		this.setState({
			[e.target.id]: value,
			[e.target.error]: '',
		})
		if (e.target.id === 'coverage_amount') {
			this.props.sendQuoteRequest("CoverageAmount", value, null, true);
		}
		if (e.target.id === 'plan_id') {
			this.props.sendQuoteRequest("PlanId", getPlanId(value), null, true);
		}
		if (e.target.id === 'ftHeight') {
			this.setState({
				height: `${value}.${this.state.inHeight}`
			});
		}
		if (e.target.id === 'inHeight') {
			this.setState({
				height: `${this.state.ftHeight}.${value}`
			});
		}

		if ( e.target.id==='dob' || e.target.id==='nicotine_use'){
			let termData = e.target.id==='dob' ? {dob: value, is_nicotine_user: nicotine_use==='LessThan12MonthsAgo' ? 'true' : 'false', nicotine_use } 
				:
			 {dob, is_nicotine_user:value==='LessThan12MonthsAgo' ? 'true' : 'false', nicotine_use:value }
			if(termData.dob && termData.nicotine_use)
			this.props.getTermLength({dob:termData.dob, is_nicotine_user:termData.is_nicotine_user}, this.props.history.push)
		}
	}

	render() {
		const {
			zipcode,
			ftHeight,
			inHeight,
			isPageLevelError,
			errorTitle,
			errorMessage,
			isZipError,
			email,
			mailError,
		} = this.state

		let { feildError } = this.props

		return (
			<LandingPage loading={this.props.showSpinner} leftText={HELPER.getLeftText()}>
				<div
					className={`content-wrapper ${this.props.showDivLoading ? 'div-loading' : ''}`}
					ref={this.myRef}
				>
					<div className='campaign-content-wrapper normalize-layout-right'>
						{isPageLevelError || isZipError ? <ErrorPopup title={errorTitle} message={errorMessage} /> : null}

						<FormFields
							onInputChange={this.handleInput}
							errors={feildError}
							defaultValues={this.props.quoteResponseLoaded && !feildError ? this.props.quoteData : this.state}
							clientError={
								{
									zipcode: HELPER.zipCodeError(zipcode, isZipError),
									height: HELPER.feetHeightError(ftHeight, inHeight),
									email: HELPER.emailError(email, mailError),
								}
							}
							altFormURI={"Affordable coverage. Convenient online application."}
						/>

					</div>
					<div className={"global-button-margin normalize-layout-right"}>
						<div className={"global-button-wrapper"}>
							<ButtonBlue
								label={"Show my coverage estimate"}
								btnType='pill'
								btnClickHandler={this.handleBtnClick}
								width='365px'
							/>
						</div>
					</div>
				</div>
			</LandingPage>
		);
	}
}

// show spinner, if the breadcrumb is not loaded => initializer action.
// or, if the createQuoteLoading has started.
const showSpinner = (state) => {
	const { landingPage, utilReducer } = state;
	return !utilReducer.breadcrumbsLoaded || landingPage.createQuoteLoading;
}

// if hash is empty, it means it's a new user.
// show div is loading, if the quotedetails api is in progress and
// response is not loaded.
const showDivLoading = (state, props) => {
	let uid = getUid(props.location.search);
	const session_id = getSessionId(props.location.search);
	if (!(uid || session_id)) {
		return false;
	}
	const { landingPage } = state;
	return landingPage.loading && !landingPage.quoteResponseLoaded;
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		addDetails,
		getDetails,
		setBreadCrumbs,
		clearError,
		getTermLength,
		sendQuoteRequest,
		setUid,
		setSessionId,
		isCampaignMounted
	}, dispatch);
}

const mapStateToProps = (state, ownProps) => {
	return {
		showSpinner: showSpinner(state),
		showDivLoading: showDivLoading(state, ownProps),
		quoteData: state.landingPage.quoteResponse,
		quoteResponseLoaded: state.landingPage.quoteResponseLoaded,
		zipError: state.landingPage.zipError,
		zipnum: state.landingPage.zipcode,
		zipErrorInfo: state.landingPage.zipErrorInfo,
		DOBError: state.landingPage.DOBError,
		DOBnum: state.landingPage.DOB,
		DOBErrorInfo: state.landingPage.DOBErrorInfo,
		backendError: state.landingPage.backendError,
		feildError: state.landingPage.feildError,
		plan_id: state.premiumReducer.selectedPlanId,
		coverage_amount: state.premiumReducer.selectedCoverageAmount,
		isError: state.quotePageReducers.error,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);

