import React from "react";
import * as utils from '../../util/index';
import get from "lodash/get";
import { Tooltip } from '../../Components/UIElements/index';
import './quotes.scss'

export default function (props) {
  const { quoteResponse, getRef } = props;
  let estimateTooltip=(
    <div>
      <p>
        This estimated range is meant to give you an idea of what you'll pay for coverage if you're approved.
        You can adjust your coverage amount and term length, which will change your estimate.
        Once you fill out your application, we'll give you an exact rate for your policy.
      </p>
    </div>
  );
  
  if (quoteResponse) {
    const  coverageAmount = utils.formatAmount(get(quoteResponse, "coverage_amount.default_value", 0), 0, get(quoteResponse, "premium.currency_symbol", "$"));
    const  quoteYear = quoteResponse.id ? utils.getDefaultPlanId(quoteResponse.id) : "";
    const  premium_best = utils.formatAmount(get(quoteResponse, "premium.premium_best_case", 0), 2, get(quoteResponse, "premium.currency_symbol", "$"));
    const  premium_worst = utils.formatAmount(get(quoteResponse, "premium.premium_worst_case", 0), 2, get(quoteResponse, "premium.currency_symbol", "$"));
    const  monthly_estimate = utils.formatAmount(get(quoteResponse, "premium.monthly_estimate", 0), 2, get(quoteResponse, "premium.currency_symbol", "$"));
    return (
      <div className="premium-range-wrapper normalize-margin">
        <p className="quotes-header-title">Monthly estimate</p>
        <div className="number-value">
          <p className="set-margin">{premium_best+"*"} </p>
          <div className="border-for-num">
             <p className="quotes-num">{monthly_estimate}</p>
                <div className = "background-color">
                   {coverageAmount} {"|"} {quoteYear}-year term
                </div>
          </div>
          <p ref={ getRef } className="set-margin">{premium_worst+"*"}</p>
        </div>
        
        {/* <div className="estimate-tooltip-text">
        Current AAA members save 10%. If you're a member, your savings are reflected in your estimate.
          <Tooltip value={estimateTooltip} id='estimate-tooltip' />
        </div> */}
      </div>
    )
  }
  return null;
}
