import {sendQuotes} from './quoteActions'
import {addDetails,getDetails, clearError, setUid, setSessionId, isCampaignMounted } from './landingPageActions'
import {setBreadCrumbs} from './utilsActions'
import { getTermLength, sendQuoteRequest } from './premiumActions';

const fakeUpdateStore = () => ({
    type: 'FAKE_UPDATE_STORE'
});

export {
    sendQuotes,
    addDetails,
    getDetails,
    setBreadCrumbs,
    clearError,
    getTermLength,
    setUid,
    setSessionId,
    sendQuoteRequest,
    isCampaignMounted,
    fakeUpdateStore
}
