import React from "react";
import "./Radio.css";
import { ErrorTypes } from "../../../util"

export default class RadioButton extends React.Component {
  state = {
    isError: false,
    response_options: [],
    user_response: {}
  };

  getErrorMessage = errorType => {
    const {
      questionObj: { question_text }
    } = this.props;
    let error_message = "";
    if(errorType === ErrorTypes.required) {
      if(question_text.length < 20)
        error_message = "The " + question_text.toLowerCase() + " field is required.";
      else
        error_message = "This field is required.";
    }
    return error_message
  };

  componentDidMount = () => {
    const {
      handleRadioChange,
      questionObj: {
        question_id,
        question_text,
        validations,
        index,
        response_options,
        response: user_response
      }
    } = this.props;

    const value =
      validations && validations.required ? validations.required.value : false;

    if (user_response)
      this.props.handleRadioChange(question_id, user_response.label, value);

    this.setState({
      handleRadioChange,
      question_id,
      question_text,
      value,
      error_messge:
        validations && validations.required
          ? validations.required.error_messge
          : "",
      index,
      response_options,
      user_response
    });
  };

  handleRadioGroupChange = (question_id, label, value) => event => {
    this.props.handleRadioChange(question_id, label, value);
  };

  render() {
    let {
      question_id,
      question_text,
      response_options,
      value,
      user_response
    } = this.state;
    const { requiredError, nextButtonOnClick } = this.props;
    return (
      <div className={"radio-group-parent-wrapper"}>
        <label htmlFor={question_id}>
          {question_text} {this.props.required}
          {/* {this.props.required && <span style={{ color: "#0b808e" }}>*</span>} */}
        </label>
        <div className={"radio-group-wrapper"}>
          {response_options.map(({ id, label }, index) => (
            <label className="container_r radio-label" key={index}>
              {label}
              <input
                type="radio"
                name={`radio-${question_id}`}
                defaultChecked={user_response && user_response.label === label}
                onChange={this.handleRadioGroupChange(
                  question_id,
                  label,
                  value
                )}
              />
              <span className="checkmark" />
            </label>
          ))}
        </div>
        {requiredError && nextButtonOnClick &&
          requiredError !== ErrorTypes.noError && (
            <p
              style={{
                fontSize: 12,
                color: "red",
                marginTop: 0,
                marginBottom: 0
              }}
            >
              {this.getErrorMessage(requiredError)}
            </p>
          )}
      </div>
    );
  }
}
