const localDb = {
  key: 'questions',

  /**
   * @description creates a local storage db for the given uid.
   * @param {String} uid of current user.
   */
  createLocalDb (uid) {
    const dbKey = `${this.key}-${uid}`;
    const value = {};
    try {
      localStorage.setItem(dbKey, JSON.stringify(value));
    } catch (error) {
      console.log ("local storage db creation failed.");
      console.error (error);
    }
  },

  /**
   * @description creates an entry in local storage for the given question.
   * @param {Object} requestParams to be sent to the api.
   */
  saveQuestionsResponse (requestParams) {
    const {
      page_sequence_number: pageSequenceNumber,
      prev_page_flag: prevPageFlag,
      uid,
      reflexive_question_flag: reflexiveQuestion,
      questions,
    } = requestParams;

    // it is not needed to save request parameters while going back or,
    // when next page flag is not present.
    if (prevPageFlag || reflexiveQuestion) {
      return;
    }
    const dbKey = `${this.key}-?uid=${uid}`;
    try {
      let data = JSON.parse(localStorage.getItem(dbKey));
      data = {
        ...data,
        [pageSequenceNumber]: questions
      }
      localStorage.setItem(dbKey, JSON.stringify(data));
    } catch (error) {
      console.log (`Saving response for page sequence number ${pageSequenceNumber} failed`);
      console.error (error);
    }
  },

  /**
   * @description extracts the originally answered q & a.
   * @param {String} uid of the current user
   * @param {Number} pageSeqNumber current page sequence number
   */
  getQuestionResponse (uid, pageSeqNumber) {
    const dbKey = `${this.key}-${uid}`;
    try {
      const data = JSON.parse(localStorage.getItem(dbKey));
      let questions = data[pageSeqNumber];
      if (questions) {
        // delete requestParams.next_page_flag;
        return {
          questions,
          edit_page_flag: 1,
          page_sequence_number: pageSeqNumber,
          uid: uid.replace("?uid=", ""),
        }
      } else {
        return null;
      }
    } catch (error) {
      console.log ("Error in fetching old q & a");
      console.error (error);
    }
  },

  /**
   * @description deletes the local storage db of the given uid.
   * @param {String} uid of current user.
   */
  destroyLocalDb (uid) {
    const dbKey = `${this.key}-${uid}`;
    try {
      localStorage.removeItem(dbKey);
    } catch (error) {
      console.log ("Local storage db clear failed.");
      console.error (error);
    }
  },  
};

export default localDb;
