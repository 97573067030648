import { QUOTE_API, ERROR, GET_QUOTE_DATA_START, GET_QUOTE_DATA_SUCCESS, UPDATE_PREMIMUM_PAGE_ERROR } from '../types'
import { axiosInstance } from "../../util/axios";
import { redirectPreReflex } from '../../util';
import * as utils from '../../util/index'
import get from "lodash/get";

/**
 * @description updates the options array with the data stored in the
 * state which was returned by quotedetails API.
 * 
 * This function can be removed once the quote/premium API is fixed to
 * give updated data from back-end.
 * 
 * @param {Function} getState retrieves the current state
 * @param {Object} data received from backend
 * @param {Array}
 */

 const updateSelectedOption = (getState, data) => {
	 const currentState = getState();
	 const options = get(data, "response.plans.option", []);
	 const planId = get(currentState, "premiumReducer.selectedPlanId", "");

	 if (planId) {
		 return options.map(option => ({
				 ...option,
				 default: (option.id === planId)
			 })
		 );
	 }
	 return options
 }

export const sendQuotes = (uid, push) => {
	const formData = new FormData()
	return (dispatch, getState) => {
		dispatch({
			type: GET_QUOTE_DATA_START,
		});
		utils.prepareFormData(formData, uid);
		axiosInstance.post('/quote/premium', formData).then(res => {
			utils.handleGtmPrefilledDataBeforeQuestions(res);
			redirectPreReflex(res, push);
			if (res.data.response.plans.option.length === 0) {
				dispatch({
					type: ERROR,
				})
				utils.saveException({ apiName: '/v1/quote/premium', message: 'Received Empty Options Array on Plans data, hence we are not able to render any plan related information to the user' })
			}
			else {
				axiosInstance.post('/quote/validateageonpremium', formData).then(validationRes => {
					dispatch({
						type: UPDATE_PREMIMUM_PAGE_ERROR,
						payload: validationRes
					})
					dispatch({
						type: GET_QUOTE_DATA_SUCCESS,
						payload: {
							quoteApi: updateSelectedOption(getState, res.data),
							tenureApi: get(res, "data.response.coverage_term"),
							coverageAmount: get(res, "data.response.coverage_amount")
						}
					})
				}).catch(err => {
					utils.saveException({ apiName: '/v1/validateageonpremium', message: err })
					push(`/error${window.location.search}`)
					if (err && err.response){
						dispatch({
							type: ERROR,
							payload: err.response.data.errors.userMessage
						})
					}
					console.log(err)
				});
			}
		})
			.catch(err => {
				utils.saveException({ apiName: '/v1/quote/premium', message: err })
				dispatch({
					type: ERROR,
				})
				push(`/error${window.location.search}`)
			})
	};
}