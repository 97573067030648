import React, { Component } from "react";
import { Tooltip } from '../../index'
import "./button.css";
import { getForMotivConfig } from '../../../../util';

export default class SingleSelectionButton extends Component {

    constructor(props) {
        super(props)
        this.state = {
            yesButtonClass: 'button-not-selected',
            noButtonClass: 'button-not-selected',
        }
    }

    handleButtonClick = (elementName, e) => {
        const { onInputChnage, id } = this.props
        let value
        if (e.target.textContent === 'Yes') {
            this.setState({
                yesButtonClass: 'button-selected',
                noButtonClass: 'button-not-selected',
            })
            value = { target: { value: 'Yes', id: id } }
        }
        else {
            this.setState({
                yesButtonClass: 'button-not-selected',
                noButtonClass: 'button-selected',
            })
            value = { target: { value: 'No', id: id } }

        }
        onInputChnage(value)
        window.formotiv.inputActivity(getForMotivConfig(this.props.altFormURI), elementName , "radio", value.target.value);
    }

    onKeyDown = (e) => {
        const { onInputChnage, id } = this.props
        let value
        if (e.keyCode === 13) {
            if (e.target.value === 'yesButton' || e.target.textContent ==='Yes') {
                this.setState({
                    yesButtonClass: 'button-selected',
                    noButtonClass: 'button-not-selected',
                })
                value = { target: { value: 'Yes', id: id } }
            }
            else {
                this.setState({
                    yesButtonClass: 'button-not-selected',
                    noButtonClass: 'button-selected',
                })
                value = { target: { value: 'No', id: id } }
            }
            onInputChnage(value)
        }

    }

    render() {
        const { label, error, isError, value, tooltip, tooltipValue, tooltipDelay, id, tooltipLink, href } = this.props
        return (
            <div className='input-static-container'>
                <div className='label-area'>
                    <div>
                        <p className='label-title'> {label} </p>
                    </div>
                    {tooltip ? <Tooltip value={tooltipValue} link={tooltipLink} delay={tooltipDelay} id={id} href={href} /> : null}
                </div>
                <div className='input-height-div'>
                    <div className="radio-toolbar moz-radio" >
                      
                        <input type="radio" id={id + "_yes"} value="yesButton" name={id+"_radioYes"}
                            className={value === "Yes" ? 'button-option button-selected' : 'button-option ' + this.state.yesButtonClass} 
                            checked={value==="Yes"?true:false}/>

                        <label htmlFor={id + "_yes"} tabIndex='0' onKeyDown={this.onKeyDown} onClick={this.handleButtonClick.bind(this, id+"_radioYes")} >Yes</label>
                    </div>
                    <div className="radio-toolbar"  >
                        <input  type="radio" id={id + "_no"} value="noButton" name={id+"_radioNo"}
                            className={value === "No" ? 'button-option button-selected' : 'button-option ' + this.state.noButtonClass } 
                            checked={value==="No"?true:false}/>

                        <label htmlFor={id + "_no"} tabIndex='0' onKeyDown={this.onKeyDown} onClick={this.handleButtonClick.bind(this, id+"_radioNo")} value="noButton" >No</label>
                    </div>
                </div>
                {isError ? <p className='button-error-message'>{error}</p> : null}
            </div>
        )
    }
}