import React ,{ Component } from "react";
import "./header.css";
import "font-awesome/css/font-awesome.min.css";
import logo from "../../assets/Images/AAA.png";
import BreadCrumbs from '../Breadcrumbs/breadcrumbs'
import { connect } from 'react-redux';
import { isMobile, getPhoneNumber } from "../../util";
class Headers extends Component {
  constructor(props) {
    super(props)
    this.state={
      style1:{ display: 'flex' },
      style2:{ display: 'none' },
      menuDropDown:false,
      icon:'fa fa-bars',
      phoneNumber:{
        ph:'(888) 469-4188',
        tel:'(888)469-4188'
      }
    }
  }
  componentDidUpdate(prevprops){
    if(prevprops.breadcrumbs!==this.props.breadcrumbs){
      let {breadcrumbs}=this.props
      if(breadcrumbs.length){
      this.setState({
        style1:{ display: 'flex' },
        style2:{ display: 'none' }
      })
    }
    else{
      this.setState({
        style2:{ display: 'flex' },
        style1:{ display: 'none' }
      })
    }
    if(isMobile()){
      this.setState({
        style1:{ display: 'none' },
        style2:{ display: 'flex' }
      })
    }
  }
  if(prevprops.status!==this.props.status){
      this.setState({
        phoneNumber: getPhoneNumber(this.props.status)
      })
  }
 
}
onMenuClick=()=>{
  this.setState({
    menuDropDown: !this.state.menuDropDown,
    icon: this.state.menuDropDown ? 'fa fa-bars' : 'close-style',
})
}


  render() {
    let {style1, style2,icon,menuDropDown, phoneNumber:{ph,tel}}=this.state
    return (
      <div className="header-container-root">
        <div className="header-container">
          <div className='header-row-1'>
            <div className="company-logo">
              <a href=' https://www.aaalife.com' target='_blank'>
              <img src={logo} height="50" width="90" alt="AAA" />
              </a>
            </div>
            <div className="button-container display-flex vertical-center" style={style2}>
            {ph &&
              <span className="call-label">
                Have questions? Call us at
                <span className="call-number">
                <a href={`tel:${tel}`} className="call-number">{ph}</a>
          </span>
              </span>
            }
              <div className="hamberger-header" onClick={this.onMenuClick}>
                <i className={`${icon} fa-2x`} aria-hidden="true" />
              </div>
            </div>
          </div>
          <div className='header-row-2'>
            <div className='breadcrumbs logo-breadcrumbs-container'>
              <BreadCrumbs />
            </div>
            {menuDropDown ? (
            <div className='hamberger-dropdown'>  
            <div className='hamberger-menu'>{this.props.breadcrumbs.map((ele,index)=>(
              <span className={`menu-options ${ele.state}-menu`} key={index}>{ele.title}</span>
            ))}
            </div>
            <div className='hamberger-footer'>
            {ph &&
            <span className="footer-label">
                Have questions? Call us at
                <span className="call-number">
                <a href={`tel:${tel}`} className="call-number">{ph}</a>
          </span>
              </span>
            }
            </div>
            </div>
            ) : null}
            <div className="button-container display-none" style={style1}>
            {ph &&
              <span className="call-label">
                Have questions? Call us at
                <span className="call-number">
                <a href={`tel:${tel}`} className="call-number">{ph}</a>
          </span>
              </span>
            }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    breadcrumbs: state.utilReducer.breadcrumbs,
  }
}
export default connect(mapStateToProps, null)(Headers);




