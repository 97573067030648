/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import "./footer.css";
import "font-awesome/css/font-awesome.min.css";
import entrust_logo from "../../assets/Images/entrust_logo.png";
import entrust_mobile from "../../assets/Images/entrust_mobile.png";

export default class Footer extends Component {
  render() {

    return (
      <div className="footer-container-root">
       
        <footer className={"global-footer"}>
        
          <div className={"container"}>
          <div className="footer-text-container">
          <div className="footer-links footer-links-alignment">
                  <a href='https://www.aaalife.com/disclosures' target='_blank'>Disclosures</a> <span className="divider"> |
                   </span> <a href='https://www.aaalife.com/' target='_blank'>AAALife.com</a><span className="divider"> |
                   </span> <a href='https://www.aaalife.com/privacy-policy' target='_blank'>Privacy Policy</a><span className="divider"> |
                   </span> <a href='https://www.aaalife.com/terms-and-conditions'target='_blank'>Terms and Conditions</a> <span className="divider"> |
                   </span> <a  href='https://www.aaalife.com/contact-us' target='_blank'>Contact Us</a>
                  {" "}
                </div>
                </div>
            <div className="content-display">
              <div className="footer-text-container">
                <p className="footer-content">
                  Life insurance underwritten and annuities offered by AAA Life Insurance Company, Livonia, Michigan. AAA Life Insurance Company is licensed in all states except NY. CA Certificate of Authority #07861. Products and their features may not be available in all states.
              </p>
                <p className="footer-content">
                  ®2019 AAA Life Insurance Company, All Rights Reserved.
              </p>
              <p className='footer-content'>25661</p>
              </div>
              <span className="entrustLogo">
                <a href='https://www.entrust.net/customer/profile.cfm?domain=www.aaalife.com' target='_blank'>
                <img src={entrust_logo} height="90" width="90" alt="AAA" />
                </a>
              </span>
             
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
