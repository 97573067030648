import React, { Component } from "react";
import { connect } from 'react-redux';
import "./breadcrumbs.css";

class Breadcrumbs extends Component {

  constructor(props) {
    super(props)
    this.state={
      allComplete: false
    }
  }
  componentDidUpdate(prevprops){
    if(prevprops.breadcrumbs!==this.props.breadcrumbs){
      let { breadcrumbs }=this.props
      let count = breadcrumbs.length;
      const completedCount = breadcrumbs.filter(b => b.state === "completed").length;

      // when all are in completed state and last one is active.
      this.setState({
        allComplete: (count === completedCount + 1) && (breadcrumbs[completedCount].state === "active")
      });
    }
  }
  render() {
    let {breadcrumbs}=this.props
    const len = breadcrumbs.length;
    return (
      <div>
        {
          breadcrumbs.length ? (
            <div>
              <div className='breadcrumbs-header-comp'>
                <div className='breadcrumbs-header'>
                  {
                    breadcrumbs.map((value,index)=>{
                      let klass = index === 0 ? "first" : "";
                      if (index === len - 1) {
                        klass = "last";
                      }
                      return (
                        <div className='breadcrumb-body'>
                          <div
                            key={index}
                            className={`breadcrumbs-title`}
                          >
                           <span className='title-value'> { value.title }</span>
                          </div>
                          <div className={`breadcrumb-border border-${klass} border-${value.state} ${this.state.allComplete ? "all-completed" : ""}`}></div>
                        </div>
                      )
                    })
                  }             
                </div>
              </div>            
            </div>
          ) : null
        }
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    breadcrumbs: state.utilReducer.breadcrumbs,
  }
}
export default connect(mapStateToProps, null)(Breadcrumbs);