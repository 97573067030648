import React, { Component } from "react";
import './dropdown.css';
import Select from 'react-select';
import { Tooltip } from '../../index'
import { isMobile, CustomOption, getForMotivConfig } from "../../../../util";


export default class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: '',
        };
    }

    componentDidMount() {
        if (!isMobile()) {
            this.setState({
                width: this.props.width,
            })
        }
        else {
            this.setState({
                width: '100%'
            })
        }
        this.select.select.inputRef.name = `${this.props.id}_${this.props.inputType}`;
    }

    handleChange = (selectedOption) => {
        var e = { target: { value: selectedOption.value, id: this.props.id } };
        this.props.onInputChnage(e)
        window.formotiv.inputActivity(getForMotivConfig(this.props.altFormURI), `${this.props.id}_${this.props.inputType}`, "dropdown", e.target.value);
    }

    customStyles = {
        control: (base) => ({
            ...base,
            height: 44,
            maxWidth: this.state.width,
            borderRadius: 0,
            borderColor: 'rgba(153, 153, 153, 1) '
        }),

        option: (base) => ({
            ...base,
            height: 44,
            maxWidth: this.state.width
        }),
        menu : (base) => ({
            ...base,
           maxWidth: this.state.width
        })
    }

    render() {

        const {
            label,
            placeholder,
            options,
            error,
            isError,
            tooltip,
            tooltipValue,
            id,
            tooltipDelay,
            value
        }
        = this.props;

        let selected = null;

        if (value) {
            selected = this.props.options.find(ele=>{
                return ele.value===value
            });
        }

        // assing the value of isSearchable passed from parent.
        // default: true
        let { isSearchable } = this.props;
        if (undefined === isSearchable) {
            isSearchable = true;
        }
        return (
            <div>
                <div className='label-area'>

                    <p className='label-title'>
                        {label}
                        {tooltip ? <Tooltip value={tooltipValue} id={id} delay={tooltipDelay} /> : null}
                    </p>
                </div>
                <Select
                    isSearchable={isSearchable}
                    value={selected}
                    onChange={this.handleChange}
                    options={options}
                    placeholder={placeholder}
                    styles={this.customStyles}
                    className={'select'}
                    components={{ Option: CustomOption }}
                    ref={ref => { this.select = ref; }}
                />
                {isError ? <p className='dropdown-error-message'>{error}</p> : null}
            </div>
        )
    }
}