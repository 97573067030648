import React from 'react'
import  {LandingPage}  from "../";
import { scrollUP } from '../../util';
const PaymentMessage=(props)=>{
  scrollUP()
    return(
        <LandingPage leftText={'Payment is being processed'}>
              <div className='review-questions-area normalize-layout-right'>
                <div className='thankyou-message'>
                  <p>We are getting your payment details, your patience in appreciated</p>
                </div>
              </div>
          </LandingPage>
    )
}

export default PaymentMessage