import { axiosInstance } from "../../util/axios";
import * as utils from '../../util/index'
import get from "lodash/get";

import {
	UPDATE_TERM_LENGTH_OPTIONS,
	ERROR,
	SET_SELECTED
} from "../types";

import { sendQuotes } from "./quoteActions";

const getQuoteRequestData = (getState) => {
	const currentState = getState();
	const coverage_amount = get(currentState, "premiumReducer.selectedCoverageAmount", 0);
	const plan_id = get(currentState, "premiumReducer.selectedPlanId", "");
	const uid = get(currentState, "landingPage.uid", "");
	return {
		coverage_amount,
		plan_id,
		uid
	}
}

const setSelected = (key, value) => ({
	type: SET_SELECTED,
	key,
	value
})

const hasSessionId = (getState) => {
	const currentState = getState();
	const session_id = get(currentState, "landingPage.sessionId", "");
	return session_id ? true : false;
}

export const sendQuoteRequest = (key, value, push, skipRequest) => {
	return (dispatch, getState) => {
		dispatch(setSelected(key, value));
		if (!skipRequest) {
			const quoteData = getQuoteRequestData(getState);
			if (quoteData.uid || hasSessionId(getState)) {
				dispatch(sendQuotes(quoteData, push));
			}
		}
	}
}

export const getTermLength = (age) => {
	const formData = new FormData();
	return (dispatch, getState) => {
		utils.prepareFormData(formData, age);
		axiosInstance.post('quote/termLengthDOB', formData)
			.then(res => {
				const termLength = get(res, "data.response.termLength", []);
				const coverageAmount = get(res, "data.response.coverage_amount", null);
				const defaultTermLength = get(res,"data.response.defaultTermLength",null)
				const defaultCoverageAmount = get(res, "data.response.defaultCoverageAmount", null )
				if (termLength && coverageAmount) {
					const currentState = getState();
					const quoteResponseLoaded = get(currentState, "landingPage.quoteResponseLoaded", false);
					dispatch({
						type: UPDATE_TERM_LENGTH_OPTIONS,
						payload: {
							termLength,
							coverageAmount,
							quoteResponseLoaded,
							defaultTermLength,
							defaultCoverageAmount
						}
					});
				} else {
					dispatch({
						type: ERROR,
					});
				}
			})
			.catch(err => {
				dispatch({
					type: ERROR,
				});
				utils.saveException({ apiName: 'v1/quote/termLengthDOB', message: err });
			});
	}
}
