import Axios from "axios";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";
import Store from '../store'

import {
  getQueryParamValue,
  getParams,
  convertQueryStringToObject,
  appendRequiredCookieValue
} from ".";
import moment from "moment";
import momenttz from "moment-timezone";
import originConfig from "./config";

let config=originConfig[process.env.REACT_APP_NODE_ENV||"development"]

function timer () {
  var t = setTimeout(function () {
    console.log("Timeout reached");
    // const formData = new FormData()
    // const uid = getParams('uid')
    // formData.append("uid", uid);
    // formData.append("session_timeout",true)
    // const applicationEndPages = ['/completed','/error','/paymentsuccess','/authcode','/timeout']
    // if(uid  && !applicationEndPages.includes(window.location.pathname))
    //   axiosInstance.post('/questions', formData).then(response=>{
    //         Store.dispatch({
    //           type:"SESSION_TRACK",
    //           payload:response
    //         })
    //   })
  }, 5* 60*1000);
  return t;
}

let timerCount = timer()

export const axiosInstance = Axios.create({
  baseURL:config.API_BASE_URL,
  method: "post",
  _origin: window.location.host
});

axiosInstance.interceptors.request.use(
  (request) => {
    clearTimeout(timerCount)     
    if (request && request.data) {
      const transactionId = getQueryParamValue(window.location.search, "transaction_id");
  
      if (transactionId) {
        request.data.set("transaction_id", transactionId);
      }

      if (window.initialTagManager) {
        request.data.set("tag_manager", JSON.stringify(uniqBy(window.initialTagManager, function (e) {
            return e.page_id;
          }))
        );
      }
      
      // add sessionId if available.
      const sessionId = getParams("session_id");
      if (sessionId) {
        request.data.set("session_id", sessionId);
      }
      // add global parameters to capture user detail
      request.data.set('current_date_time', moment());
      request.data.set('user_time_zone', momenttz.tz.guess());
      request.data.set('browser', navigator.userAgent);
      request.data.set('sourcePage', window.location.pathname);
      request.data.set('sourceURL', window.location.search);
      //uncomment this is session check is not required
      // request.data.append('skip_session_check', true);
      let searchParams =window.location.search.replace('?','');
      const queryObject = convertQueryStringToObject(searchParams)
      Object.keys(queryObject).forEach(key=>{
        if(!request.data.has(key)) {
          request.data.set(key, queryObject[key]);
        }
      });

      // append cookies, if applicable in the form-data
      const [cookieData, visitID] = appendRequiredCookieValue()
      if(visitID) {
        console.log("Appended :::: visit_id value with request ", visitID)
        request.data.set("visit_id", visitID);
      }
		  request.data.set("cookie_data",JSON.stringify(cookieData))
    }
    return request;
  },
  err => Promise.reject(err)
);

axiosInstance.interceptors.response.use(
  (response) => {
     timerCount = timer()
    if (response.data && response.data.response) {
      var tag_manager = get(response, "data.tag_manager.pages", []);
      var tag_manager_fields = response.data.tag_manager_fields;

      if (tag_manager && tag_manager.length > 0) {
        window.initialTagManager = tag_manager;
      } else {
        window.initialTagManager = window.initialTagManager || [];
      }

      window.uniqueTransactionId = response.data.transactionId || (response.data.tag_manager && response.data.tag_manager.transaction_id) || window.uniqueTransactionId;
      if (tag_manager_fields) {
        if (tag_manager_fields.gtmQuestionsList && tag_manager_fields.gtmQuestionsList.length > 0) {
          window.gtmQuestionsList = tag_manager_fields.gtmQuestionsList;
        } else {  
          window.gtmQuestionsList = window.gtmQuestionsList || [];
        }

        if (tag_manager_fields.virtualPageVisitsQuestionsList && tag_manager_fields.virtualPageVisitsQuestionsList.length > 0) {
          window.virtualPageVisitsQuestionsList = tag_manager_fields.virtualPageVisitsQuestionsList;
        } else {  
          window.virtualPageVisitsQuestionsList = window.virtualPageVisitsQuestionsList || [];
        }

        if (tag_manager_fields.virtualPageVisitsQuestionsWithAnsConsidered && tag_manager_fields.virtualPageVisitsQuestionsWithAnsConsidered.length > 0) {
          window.virtualPageVisitsQuestionsWithAnsConsidered = tag_manager_fields.virtualPageVisitsQuestionsWithAnsConsidered;
        } else {  
          window.virtualPageVisitsQuestionsWithAnsConsidered = window.virtualPageVisitsQuestionsWithAnsConsidered || [];
        }
      }
    } else {
      window.initialTagManager = window.initialTagManager || [];
      window.gtmQuestionsList = window.gtmQuestionsList || [];
      window.virtualPageVisitsQuestionsList = window.virtualPageVisitsQuestionsList || [];
      window.virtualPageVisitsQuestionsWithAnsConsidered = window.virtualPageVisitsQuestionsWithAnsConsidered || [];
    }
    return response;
  },
  err => Promise.reject(err)
);

export const axiosGetInstance = Axios.create({
  baseURL:config.API_BASE_URL,
  method: "get",
  headers: {
  organization_id: "6jhdjdh8884gud992008872",
  organization_access_token: "BDBF717DEE8AF923A69E2540A6B0FC9D",
  Authorization:"Basic YWRtaW46NyVkUkdyZVQ=",
  Accept: "application/json",
  }
});
