import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import 'babel-polyfill';
import Main from './main';
import * as serviceWorker from './serviceWorker';
import "react-rangeslider/lib/index.css";
import 'bootstrap/dist/css/bootstrap.css';
import log from './logger';

log();

// form data polyfill to support IE form data methods.
require('formdata-polyfill');

ReactDOM.render(
<BrowserRouter>
<Main />
</BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
