import React from "react";
import get from 'lodash/get';
import "font-awesome/css/font-awesome.min.css";
import { ButtonPrimary } from "..";
import "./ListQuestions.css";
import {
  CreateRequiredSchema,
  LIST_ACTION,
  formatAnswer,
  scrollUP,
  formatMetricData,
  getForMotivConfig
} from "../../../util";

const POSITION = {
  PREFIX: 'prefix',
  POSTFIX: 'postfix'
}

/**
 * This class is to render the UI for the question that are getting
 * the display_type as list,
 * it has the seperate UI and completely unrelated functionality to the
 * other types of questions
 */
export default class ListQuestions extends React.Component {
  
  constructor(props) {
    super(props);
    window.formotiv.init(getForMotivConfig(this.props.formotivURI));
  }

  state = { error: "" };
  componentDidMount = () => {
    scrollUP();
    let { child_questions_completed_flag } = this.props.question;
    this.prepareRequiredSchema();
    if (!child_questions_completed_flag && !this.props.fromListUpdate) {
      this.updateChildStatusFlag();
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.fromListUpdate !== nextProps.fromListUpdate || this.props.listAction !== nextProps.listAction || 
    (nextProps.question.questions !== null && this.props.question.questions.length !== nextProps.question.questions.length)){
      return true;
    }
    else {
      return false;
    }
  }
  prepareRequiredSchema = () => {
    const requiredSchemaTrueGrouped = CreateRequiredSchema(
      this.props.question
    );
    this.props.continuebuttonStatus(requiredSchemaTrueGrouped);
    let request = this.prepareRequest(-1);
    this.props.setListQuestionStatus(true, request);

    //this.props.handleListAction();
  };
  handleAddClickListner = (e, isEmpty) => {
    scrollUP();
    if (e) e.preventDefault();
    let request = this.prepareRequest(-1);
    if(this.props.question.validations.required.value || e){
      this.props.handleListAction(LIST_ACTION.ADD, -1, request, isEmpty)
    }
    window.formotiv.submit(getForMotivConfig(this.props.formotivURI));
  };

  getFormattedResponse = (quesObj, res) => {
    const metricObj = get(quesObj, 'validations.metric', null);
		if (metricObj) return formatMetricData(res.toString(), metricObj);
		return res;
	}

  updateChildStatusFlag = () => {
    let request = this.prepareRequest(this.props.selectedIndex);
    this.props.handleListAction(
      this.props.listAction,
      this.props.selectedIndex,
      request
    );
  };
  handleUpdateClick = async index => {
    scrollUP();
    let request = this.prepareRequest(index);
    //this.setState({ current_selected_index: index });
    this.props.handleListAction(LIST_ACTION.UPDATE, index, request);
  };

  handleDeleteClick = async index => {
    scrollUP();
    let request = this.prepareRequest(index);
    //this.setState({ current_selected_index: index });
    this.props.handleListAction(LIST_ACTION.DELETE, index, request);
  };

  prepareRequest = index => {
    let extraParams = {};
    extraParams.list_completed_flag = false;
    /**
     * When the ADD button click handler is called, the index is set as -1.
     * In that case, check for the response array. The last index of the response
     * array is the one for which the add beneficiary form is displayed.
     * We can use this index to send to back-end as it is required at the back-end.
     */
    if (index === -1) {
      index = this.props.question.response.length - 1
    }
    if (index >= 0) extraParams.current_list_answer_index = index;
    let request = [
      {
        question_id: this.props.question.question_id,
        answer: [],
        extra_params: extraParams
      }
    ];
    return request;
  };
  render() {
    let sections = null;
    let {
      questions,
      question_text,
      question_id,
      question_status,
      question_status_message,
      add_button_text,
      child_questions_completed_flag,
      no_data_message,
      options,
      max_beneficiary_count
    } = this.props.question;
    let mainQuestions = {
      questions: questions
    };
    //
    let { selectedIndex } = this.props;

    let errorText =
      question_status && question_status.toLowerCase() === "invalid" && question_status_message
        ? "Please ensure all beneficiary percentages add up to 100%"
        : "";

    let editText = "";
    let deleteText = "";
    let additionalText = "";
    let solidLineTextEnd = "";
    let addButtonText = add_button_text ? add_button_text : "ADD RECORD";
    let fromUpdate = this.props.fromListUpdate;
    let islistAction =
      fromUpdate &&
      (this.props.listAction === LIST_ACTION.ADD ||
        this.props.listAction === LIST_ACTION.UPDATE ||
        this.props.listAction === LIST_ACTION.DELETE ||
        !child_questions_completed_flag);

    let firstItem = false;
    if (questions && questions.length > 0) {
      mainQuestions.questions =
        selectedIndex !== -1 && fromUpdate && child_questions_completed_flag
          ? questions.filter((item, index) => index === selectedIndex)
          : questions;

      if (mainQuestions.questions.length > 0) {
        if (!Array.isArray(mainQuestions.questions[0])) {
          mainQuestions.questions = [mainQuestions.questions];
        }
        solidLineTextEnd = <div className="solid-line-list" />;
      } else {
        solidLineTextEnd = "";
      }
      sections = mainQuestions.questions.map((question, topIndex) => {
        firstItem = true;
        // eslint-disable-next-line array-callback-return
        return question.map((group, index) => {
          let keyIndex = topIndex * 100 + index;
          let firstPrimaryText = "";
          let solidLineText = "";
          let dottedLineText = "";
          if (firstItem) {
            editText = (
              <div
                className="edit-button"
                onClick={() => this.handleUpdateClick(topIndex)}
              >
                Edit
              </div>
            );
            deleteText = (
              <div
                className="delete-button"
                onClick={() => this.handleDeleteClick(topIndex)}
              >
                Delete
              </div>
            );
            // additionalText = "";
          } else {
            editText = "";
            deleteText = "";
            //additionalText = "";
          }
          firstItem = false;
          if (topIndex === 0 && index === 0) {
            dottedLineText = <div className="dotted-line" />;
            firstPrimaryText = (
              <div className="list_question_group_primary">{question_text}</div>
            );
            solidLineText = <div />;
          } else if (topIndex > 0 && index === 0) {
            firstPrimaryText = <div className="list_question_group_other" />;
            solidLineText = <div className="solid-line-list" />;
            dottedLineText = "";
          } else {
            firstPrimaryText = <div />;
            solidLineText = <div />;
            dottedLineText = "";
          }

          if (!islistAction && group) {
            const editHtml = editText;
            const deleteHtml = deleteText;
            const additionalHtml = additionalText;
            let answerText = formatAnswer(group);

            return (
              <div key={keyIndex}>
                {solidLineText}
                {firstPrimaryText}
                {dottedLineText}
                <div className="display_flex_list">
                  <div className="display_flex_item">
                    {" "}
                    <div className="list_question">{group.question_text}</div>
                    <div className="action-buttons">
                      {editHtml}
                      {deleteHtml}
                    </div>
                  </div>
                  <div className="display_flex_item">
                    <div className="list_answer">{this.getFormattedResponse(group, answerText)}</div>
                    {additionalHtml}
                  </div>
                </div>
              </div>
            );
          } else if (islistAction) {
            //dottedLineText = "";
            if (LIST_ACTION.DELETE !== this.props.listAction) {
              return (
                <React.Fragment>
                  {" "}
                  {solidLineText}
                  {firstPrimaryText}
                  <div>
                    {this.props.questionStructure({ questions: [group] })}
                  </div>
                </React.Fragment>
              );
            }
          }
        });
      });
    } else {
      this.handleAddClickListner(null, true);
    }
    if (!islistAction) {
      const maxBeneficiaryCount = max_beneficiary_count || 5;
      return (
        <div>
          <div className="list_container">
          <div>
            {" "}
            <p
              style={{
                fontSize: 15,
                color: "red"
              }}
            >
              {errorText}
            </p>
          </div>
            {no_data_message ? ( <span>{no_data_message}</span> ) : null}
            {sections}
            {solidLineTextEnd}
          </div>
          <div className="display_flex_button">
            <ButtonPrimary
              className={"addNewButton"}
              btnType={"rounded"}
              label={
                <span className={"add-btn"}>
                  <p className={"add-btn-text"}>{addButtonText} </p>
                </span>
              }
              disabled={questions.length >= maxBeneficiaryCount}
              onClick={e => {
                window.formotiv.inputActivity(getForMotivConfig(this.props.formotivURI), "add_new_beneficiary", "button", "clicked");
                this.handleAddClickListner(e, false)
              }}
            />
          </div>
          {questions.length>=maxBeneficiaryCount ? (
            <div className="error-message-bottom">You cannot add more than {maxBeneficiaryCount} beneficiaries.</div>) : null
          }
          {/* <div>
            {" "}
            <p
              style={{
                fontSize: 12,
                color: "red"
              }}
            >
              {errorText}
            </p>
          </div> */}
        </div>
      );
    } else {
      if (this.props.listAction === LIST_ACTION.DELETE) {
        return (
          <div key={question_id}>
            <p>Are you sure to delete?</p>
          </div>
        );
      } else {
        return <div key={question_id}>{sections}</div>;
      }
    }
  }
}
