/* eslint-disable no-mixed-operators */
import React from 'react';
import StringMask from "string-mask";
import { axiosInstance } from "./axios";
import { GetElementFunc } from "./GetElement";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import queryString from 'query-string';
import isEqual from "lodash/isEqual";
import { components } from "react-select";
import qs from 'qs'
import store from "../store";
import { fakeUpdateStore } from "../redux/actions";

function handleGtmVirtalPageVisitsUtil(questionsWithAnswerObj) {
  const searchParams = new URLSearchParams(window.location.search);
  const transactionId = searchParams.get("transaction_id");
  var questionWithAnswerObj;

  for (let i=0; i < questionsWithAnswerObj.length; i++) {
    questionWithAnswerObj = questionsWithAnswerObj[i];
    let virtualPageVisitsQuestionsList = window.virtualPageVisitsQuestionsList || [];
    for(let j=0; j < virtualPageVisitsQuestionsList.length; j++) {
      if (virtualPageVisitsQuestionsList[j].question_id == questionWithAnswerObj.question_id) {
        let pageTitle = virtualPageVisitsQuestionsList[j].gtm_title;
        let pageId = virtualPageVisitsQuestionsList[j].gtm_id;
        
        if (!isGoogleTagEventFired(pageTitle)) {
          window.initialTagManager.push({
            page_title: pageId,
            page_id: pageId
          });

          window.dataLayer.push({
            'event': 'VirtualPageView',
            'pagePath': pageId,
            'pageTitle' : pageTitle,
             VirtualPageVisitTransactionId: parseInt(transactionId || window.uniqueTransactionId),
          });
          console.log("Sending fake store update after virtual page update. In handleGtmVirtalPageVisitsUtil first block.");
          store.dispatch(fakeUpdateStore());
        }
      }
    }
    let virtualPageVisitsQuestionsWithAnsConsidered = window.virtualPageVisitsQuestionsWithAnsConsidered || [];

    for(let j=0; j < virtualPageVisitsQuestionsWithAnsConsidered.length; j++) {
      if (virtualPageVisitsQuestionsWithAnsConsidered[j].question_id == questionWithAnswerObj.question_id && 
          isEqual(virtualPageVisitsQuestionsWithAnsConsidered[j].answer, questionWithAnswerObj.answer)
        ) {
        let pageTitle = virtualPageVisitsQuestionsWithAnsConsidered[j].gtm_title;
        let pageId = virtualPageVisitsQuestionsList[j].gtm_id;

        window.initialTagManager.push({
          page_title: pageId,
          page_id: pageTitle
        });
        window.dataLayer.push({
          'event': 'VirtualPageView',
          'pagePath': pageId,
          'pageTitle' : pageTitle,
          'VirtualPageVisitTransactionId': parseInt(transactionId || window.uniqueTransactionId),
        });
        console.log("Sending fake store update after virtual page update. In handleGtmVirtalPageVisitsUtil second block.");
        store.dispatch(fakeUpdateStore());
      }
    }
  } 
}

function collectQuestionsList(questionsResponse, questionsArray) {
  let questionsObj = null;
  if (isArray(questionsResponse)) {
    questionsObj = [...questionsResponse]
  } else if (isObject(questionsResponse)) {
    questionsObj = { ...questionsResponse };
  }
  if(questionsObj && questionsObj.questions!==undefined){
    questionsObj = questionsObj.questions;
    if (!isArray(questionsObj)) {
      return;
    }
  }
  questionsObj.forEach((question) => {
    questionsArray.push(question);
    const questions = question.questions;
    if (questions && questions.length) {
      collectQuestionsList(questions, questionsArray)
    }
  });
}

export function handleGtmVirtalPageVisits(questionsResponse) {
  let questionsArray = [];
  if (questionsResponse && questionsResponse.data && 
    questionsResponse.data.response && questionsResponse.data.response &&
    questionsResponse.data.response.data && 
    questionsResponse.data.response.data.questionnarie &&
    questionsResponse.data.response.data.questionnarie.questions
  ) {
    collectQuestionsList(questionsResponse.data.response.data.questionnarie.questions, questionsArray);
  }
  if (isArray(questionsResponse)) {
    questionsArray = questionsResponse;
  }
  handleGtmVirtalPageVisitsUtil(questionsArray);
}

export const formatAmount = (amount, maximumFractionDigits, currency) => {
  if (currency === 'years') {
    return (
      parseFloat(amount).toLocaleString('en-US', {
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: maximumFractionDigits
      }) + ' ' + currency
    )
  }
  else {
    return `${currency}${parseFloat(amount).toLocaleString('en-US', {
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: maximumFractionDigits
      })}`;
    }
};

export const ErrorTypes = {
  modulo: "MODULO_ERROR",
  noError: "NO_ERROR",
  required: "REQUIRED",
  mismatch: "MISMATCH",
  minLength: "MIN_LENGTH",
  minValue: "MIN_VALUE",
  maxValue: "MAX_VALUE",
  allZeros: "ALL_ZEROS"
};

export const formatData = (value, mask) => {
  const formatter = new StringMask(mask);
  return formatter.apply(value);
};

const POSITION = {
  PREFIX: 'prefix',
  POSTFIX: 'postfix'
}

export const formatMetricData = (res, metricObj) => {
  if (metricObj && metricObj.value) {
    if(metricObj.display_position) {
      if(metricObj.display_position === POSITION.POSTFIX) {
        if(/^[a-zA-Z]+$/.test(metricObj.value)) return res + " " + metricObj.value;
        return res + metricObj.value;
      } else if(metricObj.display_position === POSITION.PREFIX) {
        return metricObj.value + res;
      }
      return res + " " + metricObj.value;
    }
  }
  return res;
}

export const getURLSearchParams = (uid, search) => {
  const params = queryString.parse(search);
  if(params) {
    if(uid) {
      return '?' + new URLSearchParams({uid: uid, ...params}).toString()
    } else return '?' + new URLSearchParams({...params}).toString()
  }
  return `?uid=${uid}`;
}

export const prepareFormData = (formData, obj) => {
  if (Object.entries(obj).length > 0) {
    Object.entries(obj).forEach(item => {
      if (item[1]) {
        formData.append(item[0], item[1]);
      }
    });
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getUid = (location) => {
  const values = queryString.parse(location);
  return values.uid;
}

export const getSessionId = (location) => {
  const values = queryString.parse(location);
  return values.session_id;
}

export const getQueryParamValue = (location, queryKey) => {
  const values = queryString.parse(location);
  return values[queryKey];
}

export const scrollUP = (behavior="auto") => {
  if('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: behavior
    });
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 800);
  }
}

export const scrollToRef = (ref) => {
  window.scrollTo(0,ref.current.offsetTop-20)
}

export const getPlanId=(tenure)=>
 `YourLife-${tenure}`

export const getDefaultPlanId=(id)=>
{
 return id.replace('YourLife-','')
}

export const formatDate=(data)=>{
  data=data.replace(/\//gi,'')
return  `${data.slice(0,2)}/${data.slice(2,4)}/${data.slice(4,8)}`
}

export const forceScroll=()=>{
  window.onbeforeunload = function() {
    scrollUP()  
}      
}

export const saveException=(exceptionDetails)=>{
  const error = new Error().error;
  console.error(error);
  const uid = new URLSearchParams(window.location.search).get("uid");
  const input_json = {
    uid: uid,
    exceptionMessage: exceptionDetails.message.toString(),
    exceptionAPI:exceptionDetails.apiName,
    exceptionDatatime: new Date().toUTCString()
  };
  const formData = new FormData();
  formData.append("data", JSON.stringify(input_json));
  // console.log(input_json);
  return axiosInstance.post(`/logerror`, formData)
}

export const getUserAge=(date,today)=>{
  if(date!=null){
    var years = (today.getFullYear() - date.getFullYear());

    if (today.getMonth() < date.getMonth() || 
        today.getMonth() === date.getMonth() && today.getDate() < date.getDate()) {
        years--;
    }
    if(years<-1){
    return -1
    }
    else{
    return years
    }
  }
}

const getRequiredSchema = (questionsResponse, schema) => {
  let questionsObj = null;
  if (isArray(questionsResponse)) {
    questionsObj = [...questionsResponse]
  } else if (isObject(questionsResponse)) {
    questionsObj = { ...questionsResponse };
  }
  if(questionsObj && questionsObj.questions!==undefined){
    questionsObj=questionsObj.questions
  }
  questionsObj.forEach(({ validations, question_id, question_type, questions }) => {
    if (
      question_type !== "group" &&
      validations &&
      validations.required &&
      Boolean(validations.required.value) === true
    ) {
      schema.push(question_id);
    }
    if (questions && questions.length) {
      getRequiredSchema(questions, schema)
    }
  });
}

export const CreateRequiredSchema = questionsResponse => {
  let schema = [];
  getRequiredSchema(questionsResponse, schema);
  return schema;
}

export const LIST_ACTION = {
  ADD: "Add",
  UPDATE: "Update",
  CANCEL: "Cancel",
  NEXT: "next",
  DELETE: "delete"
};
export const MessageType = {
  ERROR: "Error",
  WARN: "Warn",
  INFO: "Info",
  SUCCESS: "Success"
};

export const groupedQuestionType = {
  group: "questions_group",
  list: "list",
  assement_group:'assessment-factor-group',
  assement_extend:'question-group-extended'
};

export const GetElement = (
  questionObj,
  index,
  requiredSchemaTrue,
  handlingFuncs,
  questionsArr,
  errors,
  nextButtonOnClick,
  location,
  showReflexiveLoaderFor,
  disableClass,
  formotivURI
) => {
  /**
   * TODO create an list of question ids which should not be disabled,
   * and compare the value with that list.
   */
  const shouldDisable = !questionObj.allow_form_submit
  let requiredError = errors[questionObj.question_id]
  return <div tabIndex="-1"       
    key={questionObj.question_id}
    style={{outline: "none"}}
    className={`question-element-wrapper ${questionObj.is_hidden ? 'hide-question-element' : ''} ${shouldDisable ? disableClass : ''} `}>
    {
      GetElementFunc(
        questionObj,
        index,
        requiredSchemaTrue,
        handlingFuncs,
        questionsArr,
        requiredError,
        nextButtonOnClick,
        location,
        showReflexiveLoaderFor,
        disableClass,
        formotivURI
      )
    }
  </div>
};

const shouldContainNoError = currentValue =>
  currentValue === ErrorTypes.noError;

const validateQuestionsArr = (requiredElemsArr, response) => {
  /**
   * Remember response is an array
   * we have to find the property should exist as question_id
   */
  if (requiredElemsArr && response) {
    response = response.map(res => res.question_id);
    return requiredElemsArr.every(element => {
      return response.includes(element);
    });
  } else {
    return false;
  }
};

export const ToggleContinueButton = ( 
  requiredSchemaTrue,
  questionsArr,
  errors
) => {
  // console.log(questionsArr, requiredSchemaTrue,errors)
  const containsNoError = Object.entries(errors).every(([key, value]) =>
    shouldContainNoError(value)
  );

  const validatedQuestionsArr = validateQuestionsArr(
    requiredSchemaTrue,
    questionsArr
  );
  return containsNoError && validatedQuestionsArr;
};

export const QUESTION_TYPE = {
  SINGLE_SELECTION: "singleselection",
  MULTI_SELECTION: "multiselection"
};

export const formatAnswer = questionObj => {
  let response = questionObj.response
    ? questionObj.response
    : questionObj.answer;
  let formatted_value = response ? response.toString() : "";
  switch (questionObj.question_type) {
    case QUESTION_TYPE.SINGLE_SELECTION:
      formatted_value =
        response && response.label ? response.label : formatted_value;
      break;
    case QUESTION_TYPE.MULTI_SELECTION:
      formatted_value =
        response && Array.isArray(response)
          ? response.map(item => item.label).join(", ")
          : formatted_value;
      break;
    default:
      break;
  }
  return formatted_value;
};

export const isMobile=()=>{
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
    navigator.userAgent.match(/Opera Mini/i) ||
    navigator.userAgent.match(/IEMobile/i) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) && navigator.userAgent
  )
}

export const parseResponse = (res) => {
  try {
    let response = res;
    if (typeof res === "string") {
      response = JSON.parse(res);
    }
    const data = get(response, "data.response.data", null);
    const questionnaire = get(response, "data.response.data.questionnarie", null);
    const other_params = get(response, "data.response.other_params", null);
    const page_desc = get(response, "data.response.page_desc", null);
    const breadcrumbs = get(response, "data.response.breadcrumbs", null);
    return {
      data,
      questionnaire,
      other_params,
      page_desc,
      breadcrumbs,
    }
  } catch (e) {
    console.error("Error in parsing the response", e);
  }
}

/**
 * @description function compares the current location with the current status of
 * the API response and if needed, routes the application.
 * @param {Object} props to access route props.
 * @param {Object} response return by the API.
 */
export const redirectIfNeeded = (props, response, stateParams = {}) => {
  const { location, history } = props;
  const { search, pathname: currentLocation } = location;
  const { other_params } = parseResponse(response);
  if (currentLocation.toLowerCase().match(other_params.current_status.toLowerCase())) {
    return false;
  }
  history.push(`/${other_params.current_status.toLowerCase()}${search}`, {
    response: JSON.stringify(response),
    ...stateParams,
  });
  return true;
}

/**
 * @description function initiate /question api call
 * with next or previous flag.
 * @param {Object} formParams from the parent component.
 * @param {String} pageSequenceNumber indicates current page in questions flow.
 * @param {String} searchParam current search parameter in URL.
 */
export const callQuestionsAPI = async (formParams, pageSequenceNumber, searchParam) => {
  try {

    let commonGlobalParams = Object.assign({ 'uid': getUid(searchParam) });
    const formData = new FormData();

    commonGlobalParams = {
      ...commonGlobalParams,
      ...formParams
    }

    if (pageSequenceNumber) {
      formData.append("page_sequence_number", pageSequenceNumber);
    }
    prepareFormData(formData, commonGlobalParams);
    return await axiosInstance.post("/questions", formData)
    
  } catch (err) {
    console.error("error, ", err);
  }
}

/**
 * 
 * @param {String} searchParam current URL's search parameter. 
 * @param {String} uriPath api end point
 * @param {String} questionId 
 */
export const axiosCallHelper = async (props, uriPath, questionId, params) => {
  try {
    const completedQuestionsFormData = new FormData();
    //const globalParams = getGlobalParams(props);
    const globalParams = Object.assign({ 'uid': getUid(props.location.search) })
    let formDataForCompeltedQues = {
      ...globalParams,
      ...questionId,
      ...params
    };
    prepareFormData(completedQuestionsFormData, formDataForCompeltedQues);
    return await axiosInstance.post(uriPath, completedQuestionsFormData);
  } catch (error) {
    console.error ("Error", error);
  }
}

/**
 * @description extracts response from location state, and converts to
 * object if the string is stored.
 * If response is not present, makes a /questions api call.
 * @param {Object} props component props to access location properties
 */
export const getResponseFromLocation = async (props) => {
  let response = get(props, "location.state.response", null);
  if (response) {
    response = (typeof response === "string") ? JSON.parse(response) : response;
    return response;
  } else {
     return await axiosCallHelper(props, "/questions");
  }
};

/**
 * @description if redirect url is present, load new page.
 * @param {Object} response from api call.
 */
export const checkLoginRedirection = (response) => {
  const url = get(response, "data.response.loginRedirectUrl", "");
  if (url) {
    window.location.href = url;
  }
}

const isGoogleTagEventFired = function (eventName) {
  // returning false here indicates that we want to maintain duplicate entries.
  // e.g. if user is going back to contact info page from review, we need to add
  // it to tracker even though contact info page was already visited by the user earlier.
  return false;
  // var eventFired = false;
  // window.initialTagManager = window.initialTagManager || [];
  // for(var i=0; i<window.initialTagManager.length; i++) {
  //   if (window.initialTagManager[i].page_id == eventName) {
  //     eventFired = true;
  //     break;
  //   }
  // }
  // return eventFired;
}              

export const fireVirtualPageEventIfNecessary = function(page, uid, transactionId) {
  var pageTitle = page.page_title;
  var pageId = page.page_id;

  if (!isGoogleTagEventFired(pageId)) {
    window.initialTagManager = window.initialTagManager || [];
    window.initialTagManager.push({
      page_id: pageTitle,
      page_title: pageTitle,
    });
    if(window.dataLayer){
      window.dataLayer.push({
        'event': 'VirtualPageView',
        'pagePath': pageTitle,
        'pageTitle' : pageId,
        'VirtualPageVisitTransactionId': parseInt(transactionId || window.uniqueTransactionId),
      });
    }
    console.log("Sending fake store update after virtual page update. In fireVirtualPageEventIfNecessary");
    store.dispatch(fakeUpdateStore());
  }
}

export const handleGtmQuestionsSubmission = function(questionsWithAnswer) {
  
  if (questionsWithAnswer) {
    var questionsWithAnswerObj = JSON.parse(questionsWithAnswer);
    var questionWithAnswerObj;
    let gtmQuestionsList = window.gtmQuestionsList || [];
    
    for(let j=0; j < gtmQuestionsList.length; j++) {
      if (gtmQuestionsList[j].type == "value" && gtmQuestionsList[j].value) {
        window.dataLayer.push({
          [gtmQuestionsList[j].gtm_id]: gtmQuestionsList[j].value
        });
      }
    }

    for (let i=0; i < questionsWithAnswerObj.length; i++) {
      questionWithAnswerObj = questionsWithAnswerObj[i];

      if (questionWithAnswerObj.question_id) {
        let gtmQuestionsList = window.gtmQuestionsList;
        for(let j=0; j < gtmQuestionsList.length; j++) {
          if (gtmQuestionsList[j].type == "value" && gtmQuestionsList[j].value) {
          } else {
            if (gtmQuestionsList[j].question_id == questionWithAnswerObj.question_id) {
              if (gtmQuestionsList[j].type) {
                if (gtmQuestionsList[j].type.toLowerCase() == "boolean") {
                  window.dataLayer.push({
                    [gtmQuestionsList[j].gtm_id]: true
                  });
                } else {
                  window.dataLayer.push({
                    [gtmQuestionsList[j].gtm_id]: questionWithAnswerObj.answer
                  });
                }
              } else {
                window.dataLayer.push({
                  [gtmQuestionsList[j].gtm_id]: questionWithAnswerObj.answer
                });           
              }
            }
          }
        }        
      }
    }
  }
};

export const handleGtmPrefilledDataBeforeQuestions = function(obj) {
  if (obj && obj.data && obj.data.tag_manager_fields && 
    obj.data.tag_manager_fields.gtmQuestionsList
  ) {
    let gtmQuestionsList = obj.data.tag_manager_fields.gtmQuestionsList;
    for(var j=0; j < gtmQuestionsList.length; j++) {
      if (gtmQuestionsList[j].type == "value" && gtmQuestionsList[j].value) {
        window.dataLayer.push({
          [gtmQuestionsList[j].gtm_id]: gtmQuestionsList[j].value
        });
      }      
    }
  }
};



export const getParams = searchString => {
  const url = new URL(window.location.href);
  return url.searchParams.get(searchString);
}

export const converToArrayIfRequired = (obj) => {
  if (isObject(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      acc.push(obj[key]);
      return acc;
    }, [])
  }
  return obj;
}

export const CustomOption = props => {
  const { label } = props;
  const l = label ? label.replace(/ /g, "") : '';
  return <div id={l}>
      <components.Option {...props} />
  </div>;
};

export const convertQueryStringToObject=(query)=>(
   qs.parse(query)
)

export const getRandomValue = () => {
  const crypto = window.crypto || window.msCrypto;
  return crypto.getRandomValues(new Uint32Array(1))[0];
}

/**
 * @description checks for the given value, if it's a string, remove trailing spaces.
 * Updating regular expression for the questions is going to be risky, and we would have
 * to update regex for all the questions.
 * @param {Any} text 
 */
export const trimSpaces = (text) => {
  if (typeof text === "string") {
    return text.trim();
  }
  return text;
}

export const appendRequiredCookieValue=()=>{
  const requiredFilterValue = ['email_source','lead', 'phone_source', 'VISITID_AAA'] 
  const filteredCookieData = document.cookie.split(';').filter(cookie=> 
    {
    const [key, value] = cookie.split('=') 
    if(key === 'VISITID_AAA') console.log("Fetched :::: VISITID_AAA cookie value ", value)
    return requiredFilterValue.indexOf(key.trim())>-1
    })

  let visitID;
  const cookieData = filteredCookieData.map(cookie=>{
    const [key, value] = cookie.split('=')
    if(key.trim()==='lead')
      return {[key.trim()]:'lead=club'}
    else if(key.trim()==='VISITID_AAA') {
      visitID = value
      return false;
    }
    return {[key.trim()]:value}   
  })
  let cookieObject = {}
  cookieData.forEach(cookie=>{
    Object.assign(cookieObject,cookie)
  })  
  return [cookieObject, visitID]
}

export const getLengthFromRegex = (regex)=>{
  const regexArray = regex.split('')
    const lengthArray = regexArray.filter((char, index)=>
    regexArray[index-1] === '{' && regexArray[index+1]==='}'
    )
    return parseInt(lengthArray[0])
}


export const getErrMsg = (question, errorType) => {
  const { validations } = question
  switch(errorType) {
    case ErrorTypes.modulo:
      return (validations && validations.modulo)
      ? validations.modulo.error_message
      : "";
    case ErrorTypes.maxValue:
      return (validations && validations.max_value)
        ? validations.max_value.error_message
        : "";
    case ErrorTypes.minValue:
      return (validations && validations.min_value)
        ? validations.min_value.error_message
        : "";
    case ErrorTypes.required:
      return (validations && validations.required)
        ? validations.required.error_message
        : "";
    case ErrorTypes.mismatch:
      return (validations && validations.format)
        ? validations.format.error_message
        : (validations.pattern ? validations.pattern.error_message : '');
    case ErrorTypes.minLength:
      return (validations && validations.min_length)
      ? validations.min_length.error_message
      : ",";
    case ErrorTypes.allZeros:
      return (validations && validations.required)
      ? validations.required.error_message || "The entered value does not match the expected input format. Please verify."
      : "The entered value does not match the expected input format. Please verify.";
    default:
      return (validations && validations.required 
        && validations.required.value ? 'Please enter a valid input.'
      : '')
  };
}

export const redirectPreReflex = (res, push) => {
  const currentStatus = get(res, 'data.response.other_params.current_status', '');
  if (currentStatus) {
    push(`/${currentStatus}${window.location.search}`, {
      response: JSON.stringify(res),
      skipApiCall: true      // prevents duplicate api call when questions page loads
    });
    return;
  }
};

export const getPhoneNumber = (status)=>{
  const positive_status = [
    'unable_to_finish', 
    'health_screening',
    'health_screening_yes',
    'health_screening_no',
    'health_screening_no_offers',
    'health_screening_abandoned',
    'approved'
  ]
  const negative_status=['declined']
  if(status==='loading'){
    return{
      ph:'',
      tel:''
    }
  }
  if(positive_status.indexOf(status)>-1){
    return {
      ph:'(855) 402-3240',
      tel:'(855)402-3240'
    }
  }else if(negative_status.indexOf(status)>-1){
    return {
      ph:'(855) 402-3291',
      tel:'(855)402-3291'
    }
  }
  else{
    return{
      ph:'(888) 469-4188',
      tel:'(888)469-4188'
    }
  }
}

export const getForMotivConfig = (altURIName, policyNumber) => {
  const forMotivConfig = window.formotiv.FormotivDefaultConfig.Instance("9ff44707-f2cb-457a-9479-e634a7894401");
  forMotivConfig.loglevel = window.formotiv.LogLevel.Debug;
  forMotivConfig.state.ptv1 = window.location.search.split('uid=')[1] ? window.location.search.split('uid=')[1].substring(0, 27) : '';
  forMotivConfig.state.ptv2 = "";
  forMotivConfig.state.ptv3 = "Express Term";
  forMotivConfig.state.ptv4 = policyNumber || "";
  if(altURIName !== undefined && altURIName !== null){
    forMotivConfig.discovery.altURIHandler.altFormURI = altURIName;
  }
  return forMotivConfig
}