import { axiosInstance } from "../../util/axios";
import * as utils from '../../util/index'
import { PAGES_LIST } from '../../util/pages';
import {
	ERROR,
	CLEAR_ERROR,
	GET_DETAILS_START,
	GET_DETAILS_SUCCESS,
	CREATE_QUOTE_START,
	CREATE_QUOTE_SUCCESS,
	SET_UID,
	SET_SESSION_ID,
	IS_CAMPAIGN_MOUNTED
} from '../types'
import { sendQuoteRequest } from ".";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { redirectPreReflex } from '../../util';

export const setUid = (uid) => ({
	type: SET_UID,
	payload: uid
});

export const setSessionId = (session_id) => ({
	type: SET_SESSION_ID,
	payload: session_id
});

const hasSessionId = (getState) => {
	const currentState = getState();
	const session_id = get(currentState, "landingPage.sessionId", "");
	return session_id ? true : false;
}

export const addDetails = (info, push, search) => {
	const formData = new FormData()
	return (dispatch, getState) => {
		dispatch({
			type: CREATE_QUOTE_START
		})
		utils.prepareFormData(formData, info);
		axiosInstance.post('/quote/createquote', formData).then(res => {
			utils.handleGtmPrefilledDataBeforeQuestions(res);
			dispatch({
				type: CREATE_QUOTE_SUCCESS,
				payload: res
			})
			if (search === '' && res.data.response.uid) {
				push(`/?uid=${res.data.response.uid}`)
			}
			// if res.data.errors is undefined or
			// res.data.errors is an empty object, then go to quote page.
			if (!res.data.errors || isEmpty(res.data.errors)) {
				const uid = get(res, "data.response.uid", null);
				if (uid && !hasSessionId(getState)) {
					localStorage.setItem('generatedUid', uid);
					localStorage.setItem(`isCampaignMounted${uid}`,true)
					push({
						pathname: `/quote`,
						search: utils.getURLSearchParams(uid, search)
					})
				} else if(hasSessionId(getState)) {
					push({
						pathname: `/quote`,
						search: utils.getURLSearchParams(null, search)
					})
				} else {
					throw new Error ("UID returned from the server is NULL.");
				}
			}
		})
		.catch((err) => {
			utils.saveException({ apiName: '/v1/quote/createquote', message: err })
			push(`/error${window.location.search}`)
			if (err && err.response)
				dispatch({
					type: ERROR,
					payload: err.response.data.errors.userMessage
				})
				console.log(err)
		});
	};
}

export const getDetails = (info, push) => {
	const formData = new FormData()

	return (dispatch) => {
		dispatch({
			type: GET_DETAILS_START,
		})
		utils.prepareFormData(formData, info);
		axiosInstance.post('/quote/quotedetails', formData).then(res => {
			redirectPreReflex(res, push);
			const planId = get(res, "data.response.plan_id", "");
			const coverageAmount = get(res, "data.response.coverage_amount", "");
			if (planId) {
				dispatch(sendQuoteRequest("PlanId", planId, null, true));
			}
			if (coverageAmount) {
				dispatch(sendQuoteRequest("CoverageAmount", parseInt(coverageAmount), null, true));
			}
			dispatch({
				type: GET_DETAILS_SUCCESS,
				payload: res.data.response,
			})
			utils.handleGtmPrefilledDataBeforeQuestions(res);
		})
			.catch(err => {
				if (err && err.response)
					dispatch({
						type: ERROR,
						payload: err.response.data.errors.userMessage
					})
				push(`/error${window.location.search}`)
				utils.saveException({ apiName: '/v1/quote/quotedetails', message: err })
			})
	};
}

export const clearError = () => ({
	type: CLEAR_ERROR
});

export const isCampaignMounted = (value) => ({
	type: IS_CAMPAIGN_MOUNTED,
	payload: Boolean(value) || false
})
