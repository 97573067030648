import React from "react";
import './errorpopup.css';

function ErrorPopup(props) {
    let {title, message}=props
    return (
        <div className='warning-container'>
            <i className="fa fa-exclamation-triangle fa-3x error-logo" aria-hidden="true" />
            <span className='warning-title'>{title}</span>
            <div className='warning-text'>
            <p >
                {message}
             </p>
             </div>
        </div>

    )
}

export default ErrorPopup;