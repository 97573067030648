import { axiosInstance } from "../../util/axios";
import { groupedQuestionType ,saveException, ErrorTypes} from "../../util";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import intersection from "lodash/intersection";

export const getNewQuestions = async (API, formData, test) => {
  try {
    const questionResponse = await axiosInstance.post(API, formData);
    return JSON.parse(JSON.stringify(questionResponse));
  } catch(err) {
    console.error(err);
    return false;
  }
};

const findTheIndexofID = (ID, answers) =>
  answers.map(e => e.question_id).indexOf(ID);

/**
 * @description scans through the back-end response and
 * store all the questions which needs to to the backed in request parameter.
 * @param {Array} questions received from the back-end
 * @param {Array} list to store all the valid questions
 */
const getAllQuestions = (questions, list) => {
  questions.map(question => {
    if (question.display_type !== "breadcrumb") {
      list.push(question.question_id);
    }
    if (question.questions && question.questions.length) {
      getAllQuestions(question.questions, list);
    }
  })
}

export const insertMissingQuestions = (
  questionsFromApi,
  questionsWithAnswer
) => {
  try {
    if (typeof questionsWithAnswer === "string") {
      questionsWithAnswer = JSON.parse(questionsWithAnswer);
    }
  } catch (e) {
    //console.log(e);
    saveException(e);
    questionsWithAnswer = [];
  }
  
  let allQuestions = [];

  getAllQuestions(questionsFromApi, allQuestions);

  allQuestions.forEach(question => {
    if (!questionsWithAnswer.find(ques => ques.question_id === question)) {
      questionsWithAnswer.push({
        question_id: question,
        answer: "",
      })
    }
  });

  return JSON.stringify(questionsWithAnswer);
};

const isDisplayTypeQuestion = (question) => {
  return question.question_type !== "group";
}

const parseArray = function (list) {
    let parentList = list;
    for (let index = 0; index < parentList.length; index++) {
      let question = parentList[index];
      if (isDisplayTypeQuestion(question) && question.questions && question.questions.length) {
        parentList.splice(index + 1, 0, ...question.questions);
        question.questions.length = 0;
      }
      if (question.questions && question.questions.length) {
          parseArray(question.questions);
      }
    }
};

export const insertReflexiveQuestion = (questionsArray) => {
  questionsArray = [...questionsArray];
  try {
    if (questionsArray && questionsArray.length) {
      parseArray(questionsArray);
    }
    return questionsArray[0];
  } catch (e) {
    return questionsArray[0];
  }
}


const removeQuestions = (question, list) => {
  if (isObject(question) && question.questions && question.questions.length) {
    question.questions.forEach(ques => {
      if (isDisplayTypeQuestion(ques)) {
        list.push(ques.question_id);
      } else {
        removeQuestions(ques, list);
      }
    })
  }
}

/**
 * @description when the reflexive questions load, remove all the
 * questions from the questionsArr which will become obsolete.
 * Which means, those questions which are not appearing after the answer
 * of reflexive question has been changed.
 * @param {Object} questions all questions
 * @param {Array} questionsArr current state of answered questions.
 * @returns {Array}
 */
export const removeObsoleteQuestions = (questions, questionsArr) => {
  let newQuestionsArr = [];
  removeQuestions(questions, newQuestionsArr);
  return questionsArr.filter(question => {
    return (newQuestionsArr.indexOf(question.question_id) > -1)
  });
}


/**
 * @description removes questions from errors which are not present in the
 * questions list anymore.
 * @param {Array} existingErrors 
 * @param {Array} newQuestionsList 
 */
export const removeObsoleteErrors = (existingErrors, newQuestionsList) => {
  if (newQuestionsList.length) {
    let errors = {};
    for (let key in existingErrors) {
      if (newQuestionsList.find(question => question.question_id === key)) {
        errors = {
          ...errors,
          [key]: existingErrors[key]
        }
      }
    }
    return errors;
  }
  return existingErrors;
}

const isAValidQuestion = (allQuestions, questionId) => {
  const questions = [];
  flattenQuestions([allQuestions], questions);
  let matchFound = false;
  for (let index = 0; index < questions.length; index++) {
    if (questions[index].question_id === questionId) {
      matchFound = true;
      break;
    }
  }
  return matchFound;
}

export const createRequiredFieldErrors = (requiredSchema, errors = {}, allQuestions) => {
  requiredSchema.forEach((question_id) => {
    if(!(question_id in errors)) {
      errors = {
        ...errors,
        [question_id]: ErrorTypes.required
      }
    }
    if (question_id in errors && !isAValidQuestion(allQuestions, question_id)) {
      delete errors[question_id];
    }
  })
  return errors;
} 

/**
 * @description during reflexive call, only valid answers should be submitted.
 * This function will remove questions from the questions array for which
 * there is an error present.
 * @param {Array} questionsArr list of questions answered by the user
 * @param {Object} errors identified by the sytem in the answers
 */
export const removeErrorQuestionInReflexiveCall = (questionsArr, errors) => {
  return questionsArr.reduce((acc, question) => {
    // for multiselect, it is necessary to send empty array response
    if (JSON.stringify(question.answer) === "[]") {
      acc.push(question);
      return acc;
    }
    if (!(errors[question.question_id] && errors[question.question_id] !== "NO_ERROR")) {
      acc.push(question);
    }
    return acc;
  }, []);
}


const flattenQuestions = (questions, newList) => {
  if (questions.length) {
      questions.forEach(question => {
          newList.push(question);
          if (question.questions) {
            flattenQuestions(question.questions, newList);
          }
      })
  }
  return;
}

const answerIsAMatch = (allAnswers, currentAnswer) => {
  if (isArray(currentAnswer)) {
    return !intersection(allAnswers, currentAnswer).length;
  }
  return allAnswers.indexOf(currentAnswer) < 0;
}

/**
 * @description function determines if reflexive question API call should be done.
 * @param {Array} questionsArray existing questions and answers
 * @param {Array} childQuestionsOn options for which child questions will be sent
 * @param {String} currentQuestionId
 * @param {String} currentAnswer
 * @return {Boolean}
 */
export const shouldMakeReflexiveCall = (questionsArray, childQuestionsOn, currentQuestionId, currentAnswer) => {
  const hasAnswer = questionsArray.find(question => question.question_id === currentQuestionId);

  // if there are no answer eligible for reflexive calls, return false.
  if (!childQuestionsOn || (isArray(childQuestionsOn) && childQuestionsOn.length === 0)) {
    return false;
  }

  // if there is no answer and the current answer is not part of child_questions_on array,
  // do not make the api call. For every other condition, make the api call.
  if (!hasAnswer && answerIsAMatch(childQuestionsOn, currentAnswer)) {
    return false;
  }

  // if we have an answer and this answer is not in the child_question_on array, AND
  // if the new answer is also not in the child_questions_on array then do not make the api call.
  if (hasAnswer && answerIsAMatch(childQuestionsOn, hasAnswer.answer) && answerIsAMatch(childQuestionsOn, currentAnswer)) {    
    return false;
  }
  return true;
}
