import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
//import ButtonBlue from '../../Components/UIElements/ButtonBlue/button'
import { HELPER } from './helper';
import { DropdownBox, ButtonBlue } from "../../Components/UIElements";
import { getDefaultPlanId, getForMotivConfig, getPlanId } from '../../util';
import { RangeSlider, Tooltip } from '../../Components/UIElements/index'
import { sendQuoteRequest } from '../../redux/actions';

const formatMinAndMax = (data) => {
  let min = parseInt(data.min_year, 10),
      max = parseInt(data.max_year, 10);
  return {
    min, max
  }
}

function PlanSelector(props) {
  const { options, value, page, selectedPlanId } = props;
  const selected = selectedPlanId
    ? getDefaultPlanId(selectedPlanId)
    : value;
  const inputProps = {
    ...props,
    options: HELPER.getOptions(selected, options),
    value: HELPER.getOptions(selected, options).length ? selected : ''
  }

  if (page === "Q") {
    const { tenureApi, sendQuoteRequest } = props;
    const { min, max } = formatMinAndMax(tenureApi);
    const arr= [];
    for(let i=min;i<=max;i+=5){
      if (i === 25) {
        continue;
      }
      arr.push(i)
    }
    return (
      <div className={"quote-content-wrapper"}>
        <div className={"normalize-layout-right"}>
          <div className={"term-content-title-wrapper"}>
            <p className='global-header-title margin-top-19'>Adjust term <Tooltip value={HELPER.getTermTooltip()} id='quote-term-amount' /> </p>
            <div className='quote-feild-description'>                    
            </div>
            <div className='quote-tenure-rangeslider'>
              {/* <RangeSlider
                defaultValue={parseInt(getDefaultPlanId(selectedPlanId))}
                min={parseInt(min)}
                max={parseInt(max)}
                orientation="horizontal"
                formatSymbol={'years'}
                step={parseInt(tenureApi.year_increment)}
                horizontalLabels={HELPER.getLabels(min, max, "years")}
                getSliderValue={(value) => {
                  sendQuoteRequest("PlanId", getPlanId(value));
                }}
                id='tenure'
                skip={[0, 5, 25]}
              /> */}

            {arr.map((value, index) =>{
             return ( 
               <ButtonBlue
                 className={`premium-selector${value === parseInt(selected) ? ' premium-selected' : ''}`}
                 label={value+' yr'}
                 btnType={'rectangle'}
                 name={"premium-selector-"+index}
                 btnClickHandler = {() => { 
                  window.formotiv.inputActivity(getForMotivConfig(props.altFormURI), "premium-selector-"+index, 'radio', value.toString()); 
                  return sendQuoteRequest("PlanId", getPlanId(value))
                 } }
              />
            )
            })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <DropdownBox { ...inputProps } altFormURI={props.altFormURI}/>
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    sendQuoteRequest,
  }, dispatch)
);

const mapStateToProps = (state) => {
  return {
    options: HELPER.getTermLengthOptions(state.premiumReducer.termLengths),
    selectedPlanId: state.premiumReducer.selectedPlanId,
    tenureApi: state.quotePageReducers.tenureApi
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelector);
