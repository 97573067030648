import React from "react";
import "./button.css";
import { scrollUP } from "../../../util";

export default ({ label, btnType, disabled, btnClickHandler, className,width, name }) => {
  switch (btnType) {
    case "pill": {
      return (
        <button className={`btn-footer ${disabled && "disabled"} ${className}`} onClick={btnClickHandler} style={{width:width}} tabIndex={disabled ? "-1" : ""}>{label}</button>
      );
    }

    case "rounded": {
      return (
        <button className={`btn-footer btnRound ${disabled && "disabled"}`} tabIndex={disabled ? "-1" : ""}>
          {label}
        </button>
      );
    }

    case "plain": {
      return (
        <button className={`btnPillPlain ${disabled && "disabled"}`} tabIndex={disabled ? "-1" : ""}>
          {label}
        </button>
      );
    }

    case "link": {
      return (
        <button
          className={`btnPillLink ${className}`}
          tabIndex={disabled ? "-1" : ""}
          onClick={() => {
            scrollUP();
            if (disabled) return;
            return btnClickHandler() || null;
          }}
        >
          {label}
        </button>
      );
    }
    case "rectangle": {
      return (
        <button className={`btn-rectangle ${disabled && "disabled"} ${className}`} name={name} onClick={btnClickHandler} tabIndex={disabled ? "-1" : ""}>
          {label}
        </button>
      );
    }

    default: {
      return (
        <button
          className={`btn-footer ${disabled && "disabled"}`}
          tabIndex={disabled ? "-1" : ""}
          id="submit-button"
          onClick={() => {
            scrollUP();
            if (disabled) return;
            return btnClickHandler() || null;
          }}
        >
          {label}
        </button>
      );
    }
  }
};
