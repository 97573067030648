import React, { useEffect } from 'react'
import { LandingPage } from '..'
import { connect } from 'react-redux';
import { ButtonBlue } from '../../Components/UIElements'
import './error.scss'
import { scrollUP, fireVirtualPageEventIfNecessary, getParams, getUid, getQueryParamValue, getForMotivConfig } from '../../util';
import { bindActionCreators } from "redux";
import { setBreadCrumbs } from "../../redux/actions";
import { PAGES_LIST } from '../../util/pages';

const leftErrorText = (
	<div className="error-left-wrapper">
		<div className="error-title">Oops!</div>
		<div className="error-sorry-message">We're sorry</div>
	</div>
);


const rightErrorText = (
	<React.Fragment>
		<div className="error-message">
			There has been a system error.
		</div>
		<div className="error-message">
			We value your business and want you to get the life insurance you need. Please start the application process again.
		</div>
		<div className="error-message">
			Should you have any questions, please contact AAA Life at <a href="tel:(888)469-4188" class="call-number call-number-right">(888) 469-4188</a>.
		</div>
	</React.Fragment>
)


const Error = (props) => {
	scrollUP();
	useEffect(()=>{
		fireVirtualPageEventIfNecessary(
			PAGES_LIST.SYSTEM_ERROR,
			getUid(props.location.search),
			getQueryParamValue(props.location.search, "transaction_id")
		);
		props.setBreadCrumbs([])
		window.formotiv.init(getForMotivConfig("Error Message"));
		window.formotiv.submitFinal(getForMotivConfig("Error Message"));
	}, []);
	return (
		<div>
			<LandingPage leftText={leftErrorText}>
				<div className='content-wrapper'>
					<div className='campaign-content-wrapper normalize-layout-right'>
						<div className="error-right-wrapper">{ rightErrorText }</div>
					</div>
				</div>
				<div className={"global-button-margin normalize-layout-right review-question-margin"}>
					<div className={"global-button-wrapper"}>
						<ButtonBlue
							label={"Back"}
							btnType='link'
							btnClickHandler={() => {
								props.history.push("/");
							}}
						/>
					</div>
				</div>
			</LandingPage>
		</div>)
}

const mapStateToProps = state => {
	return {
		message: state.utilReducer.errorMessage
	}
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({setBreadCrumbs }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Error)