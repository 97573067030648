import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'

import { DropdownBox } from "../../Components/UIElements";
import { HELPER } from "./helper";
import { RangeSlider, Tooltip } from '../../Components/UIElements/index';
import { sendQuoteRequest } from '../../redux/actions';
import { getForMotivConfig } from '../../util';
import { useEffect } from "react";

function CoverageAmountSelector(props) {
  const { options, selectedCoverageAmount, value, page, isLoading } = props;
  const selected = selectedCoverageAmount || value;

  if (page === "Q") {
    const { coverageAmount, sendQuoteRequest } = props;
    
    if (!coverageAmount)  return null
    
    const { coverage_ranges, currency_symbol } = coverageAmount;
    const { max, min, step_1, step_2, mid } = HELPER.getRangeAttributes(coverage_ranges);
    return (
      <div className={"quote-content-title-wrapper margin-top-19"}>
        <p className='global-header-title'>Adjust coverage <Tooltip value={HELPER.getCoverageTooltip()} id='quote-coverage-amount' /> </p>
        <div className='quote-feild-description'>
        </div>          
        <RangeSlider
          defaultValue={selected}
          min={min}
          max={max}
          orientation="horizontal"
          formatSymbol={currency_symbol}
          mid={mid}
          step={step_1}
          step2={step_2}
          horizontalLabels={HELPER.getLabels(min, max, currency_symbol)}
          getSliderValue={(value) => {
            if (value !== selected) {
              sendQuoteRequest("CoverageAmount", value);
              window.formotiv.inputActivity(getForMotivConfig(props.altFormURI), 'coverageAmount_slider', "input", value.toString());
            }
          }}
          id='amount'
          isLoading={isLoading}
        />
        <div className='global-sub-header-title normalize-margin'>
          <p>
            <a
              target="_blank"
              href="https://www.aaalife.com/campaigns/term-quote/12">
              Looking for more coverage?
            </a>
          </p>
        </div>
      </div>
    )
  }
  const inputProps = {
    ...props,
    options,
    value: selected
  };
  return <DropdownBox {...inputProps} altFormURI={props.altFormURI}/>
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    sendQuoteRequest,
  }, dispatch)
);


const mapStateToProps = (state) => {
  return {
    options: state.premiumReducer.coverageAmount ? HELPER.getCoverageAmountOptions(state.premiumReducer.coverageAmount) : [],
    selectedCoverageAmount: state.premiumReducer.selectedCoverageAmount,
    coverageAmount: state.premiumReducer.coverageAmount,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverageAmountSelector);
