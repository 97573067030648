import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "./datepicker.css";
import * as utils from '../../../../util/index'

export default class Datepicker extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      startDate: '',
      clicked: false,
      error: false,
      isValidLength: false,
      isDateTyped: false
    };
    this.prevValue = "";
  }
  
  componentDidMount() {
    this.setDate(this.props);
  }
  componentWillReceiveProps (nextProps) {
    this.setDate(nextProps);
  }

  setDate = (data) => {
    const { value } = data;
    //avoid invalid dates
    if (value && value.indexOf("NaN")<0) {
      this.setState({
        startDate: moment(value, "YYYY-MM-DD").toDate()
      });
    }
  }
  

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
    const d = moment(date);
    // date is valid
    // and date is less than today.
    // if true, change props, else set date to be empty.
    if (d.isValid() && d.isBefore(moment())) {
      this.props.onInputChnage({target: { value: d.format("YYYY-MM-DD"), id: this.props.id}})
    } else {
      this.setState({
        startDate: ""
      });
    }
  }

  checkValue = (str, max) => {
    let input = str;
    if (input.charAt(0) !== '0' || input == '00') {
      let num = parseInt(input);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      input = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
    };
    return input;
  }

  onChangeRaw = (e) => {
    let input = e.target.value;

    // check if the character deleted is a /, delete one more digit which
    // comes before /.
    if (this.prevValue.replace(input, "") === "/") {
      input = input.substr(0, input.length - 1);
    }

    // split the input value with / as separator
    const values = input.split('/').map(function(v) {
      return v.replace(/\D/g, '')
    });

    // modify date and month values.
    // e.g. if user inputs 4, the date and month should be 04
    if (values[0]) values[0] = this.checkValue(values[0], 12);
    if (values[1]) values[1] = this.checkValue(values[1], 31);

    const output = values.map(function(v, i) {
      return v.length == 2 && i < 2 ? v + '/' : v;
    });
    this.prevValue = output.join('').substr(0, 10);
    e.target.value = this.prevValue;
  }

  resignResponder = () => {
    if(utils.isMobile()) {
      document.activeElement.blur();
    }
  };

  onIconClick=()=>{
    let cal=document.getElementById(this.props.id)
    cal.click();
  }

  /**
   * @description In safari, date formatting throws an error "Invalid Date", if there is
   * any single digit in the date, for example: 7-30-2019 or 11-3-2018 are invalid dates.
   * This function will split the date string and append a zero if the number is less than 10.
   * @param {string} d date value in string format
   * @returns {string} date in string format.
   */
  completeDateFormat(d) {
    let dates = d.split("-");
    return dates.map(Number).map(num => {
      if (num < 10) {
        return `0${num}`
      }
      return num;
    }).join("-");
  }

  render() {
    const { label, id, error, isError, value,maxDate } = this.props
    let dateValue = null;
    if (value) {
      dateValue = moment(moment(this.completeDateFormat(value)).format("MM/DD/YYYY"));
    }

    return (
      <div className="date-picker-container">
        <p className='label-title'> {label}</p>
        <div className='date-picker-logo' >
        <i className="fa fa-calendar calendar-logo" onClick={this.onIconClick} />
        <DatePicker
          className='input-date-area'
          selected={this.state.startDate}
          onChange={(e)=>this.handleChange(e,false)}
          placeholderText='MM/DD/YYYY'
          dropdownMode="select"
          id={id}
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          onFocus={()=>this.resignResponder()}
          maxDate={maxDate}
          onChangeRaw={this.onChangeRaw}
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '5px, 20px' // prevents the datepicker overlap when there are 6 rows in date.
            }
          }}
        />
        </div>
        {isError ? <p className='calendar-error-message'>{error}</p> : (this.state.error ? <p className='calendar-error-message'>{'Invalid date format, please enter "MM/DD/YYYY'}</p> : null)}

      </div>
    );
  }
}
