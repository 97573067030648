import React from "react";
import moment from "moment";
import debounce from "debounce-promise";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import {ErrorTypes, formatData, getErrMsg} from "../../../util";
import './datepicker.css'
import {Tooltip} from "..";
import * as utils from '../../../util/index'

export default class Datepicker extends React.Component {
  state = {
    validation_message: "",
    inputValue: null,
    selectedDateOfDatePicker: null,
    error: "",
    format_value: "YYYY-MM-DD"
  };
  prevValue = "";

  getErrorMessage = errorType => {
    return getErrMsg(this.props.questionObj, errorType);
  };

  getErrorType = (value, { min_value, max_value, pattern, required }) => {
    const momentValue = moment(value, this.state.format_value, true);
    if (value != null && !momentValue.isValid()) {
      return "MISMATCH";
    } else if (momentValue > max_value) {
      return "MAX_VALUE";
    } else if (momentValue < min_value && value != null) {
      return "MIN_VALUE";
    } else if (value === null && required === true) {
      return "REQUIRED";
    } else {
      return "NO_ERROR";
    }
  };

  getErrorTypeDebounce = debounce(this.getErrorType, 500);

  handleInputOnChange = async value => {
    const {
      questionObj: { validations }
    } = this.props;

    // Safe checking
    let min_value =
      validations &&
      validations.min_value &&
      moment(validations.min_value.value, this.state.format_value);

    let max_value =
      validations &&
      validations.max_value &&
      moment(validations.max_value.value, this.state.format_value);

    let required =
      validations && validations.required && validations.required.value;

    let pattern =
      validations &&
      validations.format &&
      new RegExp(
        validations.format.value
          .split("")
          .slice(1, validations.format.value.length - 1)
          .join("")
      );

    const outPut = await this.getErrorTypeDebounce(value, {
      min_value,
      max_value,
      pattern,
      required
    });

    this.setState({
      error: outPut
    });

    const dateValue = moment(value, this.state.format_value, true);
    // Send the data back to parent with error message and question_id
    this.props.handleChange(
      dateValue.isValid() ? dateValue.format("YYYY-MM-DD") : null,
      outPut,
      this.props.questionObj.question_id
    );
  };

  getborderStyle = error => {
    if (!error || error === "NO_ERROR") {
      return null;
    }
    return {
      borderColor: "red"
    };
  };

  onValueChange = val => {
    if (val) {
      const dateValue = moment(val, this.state.format_value, true);
      this.handleInputOnChange(val);
      if (dateValue.isValid()) {
        this.setState({
          inputValue: dateValue.format(this.state.format_value),
          selectedDateOfDatePicker: dateValue.toDate()
        });
      }
    }
  };

  checkValue = (str, max) => {
    let input = str;
    if (input.charAt(0) !== '0' || input == '00') {
      let num = parseInt(input);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      input = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
    };
    return input;
  }

  onValueChangeRaw = event => {
    let val = event.target ? event.target.value : undefined;


    // check if the character deleted is a /, delete one more digit which
    // comes before /.
    if (this.prevValue.replace(val, "") === "/") {
      val = val.substr(0, val.length - 1);
    }

    // split the val value with / as separator
    const values = val.split('/').map(function(v) {
      return v.replace(/\D/g, '')
    });

    // modify date and month values.
    // e.g. if user inputs 4, the date and month should be 04
    if (values[0]) values[0] = this.checkValue(values[0], 12);
    if (values[1]) values[1] = this.checkValue(values[1], 31);

    const output = values.map(function(v, i) {
      return v.length == 2 && i < 2 ? v + '/' : v;
    });
    this.prevValue = output.join('').substr(0, 10);
    event.target.value = this.prevValue;

    if (val && val.length >= 8) {
      const formated_value = this.state.auto_format
        ? formatData(val, this.state.auto_format.value)
        : val;
      const dateValue = moment(formated_value, this.state.format_value, true);
      this.handleInputOnChange(dateValue.toDate());
      if (dateValue.isValid()) {
        this.onValueChange(
          moment(formated_value, this.state.format_value).toDate()
        );
      }
    }

    if (!val || val.length === 0) {
      this.handleInputOnChange(null);
      this.setState({
        inputValue: null,
        selectedDateOfDatePicker: null
      });
    }
  };

  componentDidMount() {
    if (this.props.questionObj) {
      const {
        questionObj: {
          response,
          question_status,
          question_text,
          question_id,
          validations,
          index
        }
      } = this.props;

      if (response && response.length > 0) {
        const responseValue = moment(response, this.state.format_value, true);
        this.props.handleChange(
          responseValue.format("YYYY-MM-DD"),
          ErrorTypes.noError,
          this.props.questionObj.question_id
        );
      } 

      // handling the response
      if (this.props.questionObj && this.props.questionObj.response) {
        let responseFromAPI = moment(this.props.questionObj.response);
        if (responseFromAPI.isValid())
          this.onValueChange(responseFromAPI.toDate());
      }

      const dateValue = moment(response, this.state.format_value);

      this.setState({
        max_value:
          validations && validations.max_value
            ? validations.max_value.value
            : Number.MAX_SAFE_INTEGER,
        min_value:
          validations && validations.min_value
            ? validations.min_value.value
            : 0,
        format_value:
          validations && validations.format
            ? validations.format.value.toUpperCase()
            : "MM/DD/YYYY",
        picker_format_value:
          validations && validations.format && validations.format.value
            ? validations.format.value
            : "MM/dd/yyyy",
        placeholder_text_value:
          validations && validations.placeholder_text
            ? validations.placeholder_text.value
            : "",
        inputValue: response
          ? moment(response, this.state.format_value).format(
              this.state.format_value
            )
          : moment(
              validations && validations.placeholder_text
                ? validations.placeholder_text.value
                : "",
              this.state.format_value
            ).format(this.state.format_value),
        question_id: question_id,
        question_text: question_text,
        index: index,
        auto_format:
          validations && validations.auto_format ? validations.auto_format : "",
        required:
          validations && validations.required && validations.required.value,
        response,
        question_status,
        error:
          question_status && question_status.toLowerCase() === "invalid"
            ? ErrorTypes.required
            : ErrorTypes.noError,
        selectedDateOfDatePicker: dateValue.isValid()
          ? dateValue.toDate()
          : null
      });
    }
  }
  openDatepicker = () => this._calendar.setOpen(true);

  resignResponder = () => {
    if(utils.isMobile()) {
      document.activeElement.blur(); // disable manual input on mobile
    }
  };

  render() {
    const { error, selectedDateOfDatePicker, required } = this.state;
    let {tooltip, is_readonly: disabled}=this.props.questionObj
    let toolTipValue = <div dangerouslySetInnerHTML={{__html:tooltip}}/>
    const { requiredError, nextButtonOnClick } = this.props

    return (
      <div className='date-picker-container'>
        <label htmlFor={this.state.question_id} className="global-label-text">
          {this.state.question_text} {required} {tooltip ? <Tooltip value={toolTipValue} id={this.state.question_id}/> : null} 
        </label>
        <div
          className={`date-picker-custom-wrapper date-picker-logo ${disabled ? 'disable-input' : ''}`}
          style={this.getborderStyle(error)}
        >
          <span onClick={this.openDatepicker}>
            <i className="fa fa-calendar calendar-logo"/>
          </span>
          <DatePicker
            disabled={disabled}
            dateFormat={this.state.picker_format_value}
            onChangeRaw={e => this.onValueChangeRaw(e)}
            onChange={this.onValueChange}
            placeholderText={this.state.placeholder_text_value}
            selected={selectedDateOfDatePicker}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            ref={c => (this._calendar = c)}
            calendarClassName="datepicker-custom"
            maxDate={Date.now()}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "5px, 10px" // to add some space b/w popper and date field
              }
            }}
            onFocus={()=>this.resignResponder()}
          />
        </div>
        {((error &&
          error !== "NO_ERROR") || (requiredError !== ErrorTypes.noError)) && nextButtonOnClick && (
            <p
              style={{
                fontSize: 12,
                color: "red",
                marginTop: 10,
                marginBottom: 15
              }}
            >
              {this.getErrorMessage(requiredError)}
            </p>
          )}
      </div>
    );
  }
}