import React from "react";
import PropTypes from "prop-types";
import { FORM_FIELDS, INPUT_TYPES } from "./constants";
import { InputFeild, DatepickerInput, DropdownBox, SingleSelection } from "../../Components/UIElements";
import PlanSelector from "../Premium/PlanSelector";
import CoverageAmountSelector from "../Premium/CoverageAmountSelector";


const getComponent = (type, props) => {
	switch (type) {
		case INPUT_TYPES.DATEPICKER:
			return <DatepickerInput { ...props } />
		case INPUT_TYPES.DROPDOWN:
			return <DropdownBox { ...props } altFormURI={props.altFormURI} />
		case INPUT_TYPES.SSELECTION:
			return <SingleSelection { ...props } altFormURI={props.altFormURI} />
		case INPUT_TYPES.PLAN_SELECTOR:
			return <PlanSelector {...props} page="C" altFormURI={props.altFormURI} />
		case INPUT_TYPES.COVERAGE_AMOUNT_SELECTOR:
			return <CoverageAmountSelector {...props} page="C" altFormURI={props.altFormURI} />
		case INPUT_TYPES.INPUT:
		default:
			return <InputFeild { ...props } />
	}
}

export default function FormFields ({
	onInputChange,
	errors,
	defaultValues,
	clientError,
	altFormURI
}) {
	return (
		<React.Fragment>
			{
				FORM_FIELDS.map(field => {
					const inputProps = {
						...field,
						onInputChnage: onInputChange,
						error: (errors[field.id] || field.defaultErrorMessage),
						value: defaultValues[field.id] || field.defaultValue,
						isError: (clientError[field.id] || errors[field.id]),
						ftHeight: defaultValues.ftHeight,
						inHeight: defaultValues.inHeight,
						altFormURI
					}
					return (
						<div className='campaign-feilds' key={field.id}>
							{ getComponent(field.inputType, inputProps) }
						</div>
					)
				})
			}
		</React.Fragment>
	)  
}

FormFields.propType = {
	onInputChange: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	defaultValues: PropTypes.object.isRequired,
	clientError: PropTypes.object.isRequired,
}
