import get from 'lodash/get';
import { formatData, formatAmount, formatMetricData } from '../../../util';

const CURRENCY_EXCEPTION_QUESTIONS = [
  'AnnualIncome'
];

const NUMBER_FIELD_QUESTIONS = [
  'PrimaryPhone',
  'SocialSecurityNumber',
  'BeneficiaryShare',
  'ZipCode',
  'AAAMembershipNumber'
];

/**
 * @description checks for the currency question.
 * @param {String} questionId 
 */
const isCurrencyQuestion = (questionId) => {
  return CURRENCY_EXCEPTION_QUESTIONS.filter(question => questionId.match(question)).length;
}

export const isNumberFieldQuestion = (questionId) => {
  return NUMBER_FIELD_QUESTIONS.filter(question => questionId.match(question)).length;
}

/**
 * @description coverts user input to corresponding formatted value
 * @param {Object} questionObj
 * @param {String} value current value
 * @param {String} currencySymbol .
 * @param {Boolean} hasMask if masked, return value
 * @param {Boolean} hasBlur if field is blured
 */
export const formattedData = (questionObj, value, currencySymbol, hasMask, hasBlur=false) => {
  const validations = get(questionObj, "validations", null);
  const questionId = get(questionObj, "question_id", null);
  const isEditable = get(questionObj, "is_editable", null);
  const type = get(questionObj, 'question_type');

  const autoFormatValue = get(validations, "auto_format.value", null);
  const metricObj = get(validations, "metric", null);

  if (hasMask) {
    return value;
  }

  if (type === 'number' && value) {
    value = value.toString().replace(/,/g, '');
    value = parseFloat(value).toString();
  }

  if (metricObj) {
    if(isEditable) return value;
    return formatMetricData(value.toString(), metricObj);
  }

  if (!autoFormatValue) {
    return value;
  }

  if (isCurrencyQuestion(questionId)) {
    if (!value) return 0;
    const amount = formatAmount(value.toString().replace(/,/g, ''), null, (currencySymbol || ''));
    if(!currencySymbol && isNaN(parseFloat(amount))) return 0;
    return amount;
  } else {
    if(hasBlur) {
      return formatData(value.replace(/[^0-9]/g, ''), autoFormatValue)
    }
    return value;
  }
}

/**
 * @description for currency question, removes the , and converts to number to test
 * the min and max values.
 * @param {String} questionId
 * @param {String} value current value, to be compared
 */
export const getActualValue = (questionId, value) => {
  if (isCurrencyQuestion(questionId) || isNumberFieldQuestion(questionId)) {
    if(value) {
      let val = value.replace(/[^0-9]/g, '');
      if(isNaN(val)) return 0;
      return val;
    }
  }
  return value
}

/**
 * @description Contains mapping for question_id with the max_length allowed for them
 */
const getMaxLength = {
  'FirstName': 20,
  'MiddleName': 1,
  'LastName': 29,
  'Street': 30,
  'StreetLine2': 30,
  'City': 20,
  'Email': 128,
  'HealthProviderName': 45,
  'CityElement': 20,
  'Percentage': 3,
  'BeneficiaryShare': 3,
  'ZipCode': 5,
  'AAAMembershipNumber': 16,
  'SocialSecurityNumber': 9,
  'PrimaryPhone': 10
}

/**
 * @description Checks if question_id has maxLength restrictions
 * @param questionId question_id
 */
export const hasMaxLength = questionId => {
  const beneficiaryLastNameRegex = /(Primary|Secondary)Beneficiary[\d]{1,2}.LastName/;
  const secondaryAddresseeLastName = /SecondaryAddressee.LastName/;
  if (!questionId) return false;
  for (const id in getMaxLength) {
    if(questionId.includes(id)) {
      if(beneficiaryLastNameRegex.test(questionId) || secondaryAddresseeLastName.test(questionId)) return 20;
      return getMaxLength[id];
    }
  }
}

/**
 * Calculate mod10 check digit
 * @param {Number|String} numbers
 */
export const mod10CheckDigit = numbers => {
  if (typeof numbers !== 'string') {
    throw new Error('Invalid number provided')
  }
  if (numbers.length < 16 || (numbers.replace(/0/g, '') === "")) {
    return false;
  }
  const reverseNumber = numbers.split('').reverse();
  const checkDigit = Number.parseInt(reverseNumber[0]);
  let doubledSum = 0;
  for (let i = 1; i < reverseNumber.length; i++) {
    let double = Number.parseInt(reverseNumber[i]);
    if (i % 2 !== 0) {
      double = double * 2;
      double = double > 9 ? double - 9 : double;
    }
    doubledSum += double;
    console.log(i, double, reverseNumber[i]);
    console.log('sum', doubledSum, checkDigit);
  }

  const modulo =  (doubledSum * 9) % 10;
  return modulo === checkDigit;
}
